import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { ADMIN_ENDPOINTS } from 'utils/api';

const FETCHING = 'ADMIN/LABORATORIES/FETCHING';
const FETCHED_ALL = 'ADMIN/LABORATORIES/FETCHED_ALL';
const CREATE = 'ADMIN/LABORATORIES/CREATE';
const UPDATE = 'ADMIN/LABORATORIES/UPDATE';
const UPDATE_ANALYSIS = 'ADMIN/LABORATORIES/UPDATE_ANALYSIS';

const initialState = {
  fetching: false,
  fetched: false,
  data: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCHED_ALL:
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: action.payload
      };
    case FETCHING:
      return {
        ...state,
        fetching: true
      };
    case CREATE:
    case UPDATE:
    case UPDATE_ANALYSIS:
    default:
      return state;
  }
};

export const fetchAll = (showLoader = true) => (dispatch) => {
  if (showLoader) dispatch({ type: FETCHING });

  return axios.get(`${ADMIN_ENDPOINTS.LABORATORIES}`)
    .then((res) => dispatch({ type: FETCHED_ALL, payload: res.data }));
};

export const createLaboratory = (newLab) => (dispatch) =>
  axios.post(`${ADMIN_ENDPOINTS.LABORATORIES}`, newLab)
    .then(() => {
      dispatch({ type: CREATE });
      dispatch(fetchAll(false));
    });

export const updateLaboratory = (labId, updatedLab) => (dispatch) =>
  axios.put(`${ADMIN_ENDPOINTS.LABORATORIES}/${labId}`, updatedLab)
    .then(() => {
      dispatch({ type: UPDATE });
      dispatch(fetchAll(false));
    });

export const updateAnalysis = (labId) => (dispatch) =>
  axios.put(`${ADMIN_ENDPOINTS.LABORATORIES}/${labId}/update-analysis`)
    .then(() => {
      dispatch({ type: UPDATE_ANALYSIS });
      dispatch(fetchAll(false));
    });

export const useLaboratoryState = () => ({
  fetching: useSelector(({ admin: { laboratories: { fetching } } }) => fetching),
  fetched: useSelector(({ admin: { laboratories: { fetched } } }) => fetched),
  laboratories: useSelector(({ admin: { laboratories: { data } } }) => data),
});

export const useLaboratoryDispatch = () => {
  const dispatch = useDispatch();
  return {
    fetchAll: () => dispatch(fetchAll()),
    createLaboratory: (newLab) => dispatch(createLaboratory(newLab)),
    updateLaboratory: (labId, updatedLab) => dispatch(updateLaboratory(labId, updatedLab)),
    updateAnalysis: (labId) => dispatch(updateAnalysis(labId))
  };
};
