import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  IconButton,
  LinearProgress, makeStyles, Tab, Tabs, Tooltip, Typography
} from '@material-ui/core';
import { PersonAdd as PersonAddIcon } from '@material-ui/icons';

import { useAdminDispatch, useAdminState } from 'modules/admin/admins';
import { useAccountDispatch } from 'modules/account';

import Statements from './show/Statements';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2)
  },
  title: {
    marginBottom: 20,
    marginLeft: 10
  }
}));

const Show = () => {
  const { role, id, view } = useParams();
  const classes = useStyles();

  const { fetchSingle, clearCurrent } = useAdminDispatch();
  const { fetching, current } = useAdminState();
  const { createLabportalenAccount } = useAccountDispatch();

  useEffect(() => {
    fetchSingle(parseInt(id, 10));
    return () => clearCurrent();
  }, []);

  return (
   <div className="elevated-container">
    {fetching
      ? <LinearProgress />
      : Object.entries(current).length === 0
        ? <React.Fragment>Hittade ingen admin med detta id.</React.Fragment>
        :
        <React.Fragment>
          <Typography variant="h2" className={classes.title}>
            Adminprofil för {current.name} ({current.personalNumber})
          </Typography>
          {current.labPortalenUserGuid === null &&
            <Tooltip title="Användaren har inget Labportalen konto, klicka här för att lägga till">
              <IconButton onClick={() => createLabportalenAccount(current.applicationUserId)}>
                <PersonAddIcon/>
              </IconButton>
            </Tooltip>
          }
          <Tabs
            variant="scrollable"
            value={view}
            indicatorColor="primary"
            textColor="primary"
            aria-label="disabled tabs example"
          >
            <Tab label="Utlåtanden" value="statements" component={Link} to={`/${role}/admins/${id}/statements`} />
          </Tabs>
          <div className={classes.root}>
            {{
              statements: <Statements statements={current.healthStatements} role={role}/>,
            }[view]}
          </div>
        </React.Fragment>
    }
   </div>
  );
};

export default Show;
