import React, { useEffect, useState } from 'react';
import {
  LinearProgress, Button, Typography, Grid, makeStyles
} from '@material-ui/core';

import { useParams } from 'react-router-dom';
import { useSurveyDispatch, useSurveyState } from 'modules/patient/surveys';
import { useAccountState } from 'modules/account';

import HealthSurveyAnswers from 'components/HealthSurveyAnswers';
import HealthSurveyForm from 'components/HealthSurveyForm';
import { surveys, getLatestVersionId } from 'utils/surveys';

const useStyles = makeStyles(() => ({
  button: {
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 10
  }
}));

const Show = () => {
  const classes = useStyles();
  const { user: { gender } } = useAccountState();

  // Gets the latest survey
  const versionId = getLatestVersionId();

  const [answers, setAnswers] = useState([]);
  const [extraData, setExtraData] = useState({});
  const [touched, setTouched] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id, role } = useParams();
  const { fetchSingle, clearCurrent, update } = useSurveyDispatch();
  const { current, fetching } = useSurveyState();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!answers.every((answer) => answer.answer.length > 0 && answer.answer[0] !== '')) {
      setLoading(false);
      return;
    }
    await update(current.id, versionId, {
      survey: answers,
      extra: extraData,
    });
    setLoading(false);
  };

  useEffect(() => {
    fetchSingle(parseInt(id, 10));
    return () => clearCurrent();
  }, []);

  return (fetching ?
    <LinearProgress/>
    :
    <React.Fragment>
      {Object.keys(current).length > 0 && Object.keys(current.answers).length === 0 ?
        <form onSubmit={handleSubmit}>
          {versionId ?
            <HealthSurveyForm
              role={role}
              surveyVersionId={versionId}
              gender={gender}
              updateAnswers={setAnswers}
              updateExtraData={setExtraData}
            />
            :
            'Kunde inte generera enkäten. Kontakta oss via meddelande om problemet består.'
          }
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography color="error">
                {touched && (answers.length === 0 || !answers.every((answer) => answer.answer.length > 0 && answer.answer[0] !== ''))
                  ? 'Vänligen besvara samtliga frågor.'
                  : ''
                }
              </Typography>
            </Grid>
            <Grid item>
              <Button
                disabled={loading}
                className={classes.button}
                variant="contained"
                color="primary"
                type="submit"
                onClick={() => setTouched(true)}
              >
                Skicka svar
              </Button>
            </Grid>
          </Grid>
        </form>
        : Object.keys(current).length > 0 && Object.keys(current.answers).length > 0 &&
        <HealthSurveyAnswers dbSurvey={current} gender={gender} role={role} />
      }
    </React.Fragment>
  );
};

export default Show;
