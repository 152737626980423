import React, { useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import {
  LinearProgress, makeStyles, Tab, Tabs
} from '@material-ui/core';

import { useCaseDispatch, useCaseState } from 'modules/patient/cases';

import Attachments from 'components/Attachments';
import TestResults from './show/TestResults';
import Statements from './show/Statements';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 0),
  },
}));

const Show = () => {
  const { id, view } = useParams();
  const history = useHistory();
  const classes = useStyles();

  const { fetching, current } = useCaseState();
  const { fetchSingle, clearCurrent, createAttachment } = useCaseDispatch();

  useEffect(() => {
    if (!view) {
      history.push(`/patient/cases/${id}/tests`);
    }
    fetchSingle(parseInt(id, 10));
    return () => clearCurrent();
  }, []);

  return (
    <div className="elevated-container">
    {fetching
      ? <LinearProgress />
      : Object.entries(current).length === 0 ?
        <React.Fragment>Hittade inget ärende.</React.Fragment>
        :
        <React.Fragment>
          <Tabs
            variant="scrollable"
            value={view}
            indicatorColor="primary"
            textColor="primary"
            aria-label="disabled tabs example"
          >
            <Tab label="Provresultat" value="tests" component={Link} to={`/patient/cases/${id}/tests`} />
            <Tab label="Utlåtanden" value="statements" component={Link} to={`/patient/cases/${id}/statements`} />
            <Tab label="Bilagor" value="attachments" component={Link} to={`/patient/cases/${id}/attachments`} />
          </Tabs>
          <div className={classes.root}>
            {{
              tests: <TestResults testResults={current.testResults}/>,
              statements:
                <Statements
                  doctorStatements={current.doctorStatements}
                  healthStatements={current.healthStatements}
                />,
              attachments:
                <Attachments
                  attachments={current.patientAttachments}
                  create={createAttachment}
                  modelId={id}
                />
            }[view]}
          </div>
        </React.Fragment>
    }
    </div>
  );
};

export default Show;
