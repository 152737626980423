import React, { useState, useRef } from 'react';
import { Button, Typography, makeStyles } from '@material-ui/core';


const useStyles = makeStyles(() => ({
  input: {
    display: 'none'
  },
  clearButton: {
    marginLeft: 10
  }
}));

/**
 * @param initialFile
 * @param accept: Array with values of "image", "pdf", "csv",
 * @returns {{clearFile: *, file: *, setFile: *, renderFileUploader: *}}
 */

export default (accept = [], initialFile = null) => {
  const fileInput = useRef(null);
  const classes = useStyles();
  const [file, setFile] = useState(initialFile);
  const [fileUrl, setFileUrl] = useState(null);
  const [error, setError] = useState('');

  const fileTypeVerified = (fileType) => {
    if (fileType.includes('image/') && accept.includes('image')) return true;
    if (fileType.includes('application/pdf') && accept.includes('pdf')) return true;
    // if (fileType.includes('csv') && accept.includes('csv')) return true;
    return false;
  };

  const saveFile = (files) => {
    if (files.length > 0) {
      if (fileTypeVerified(files[0].type)) {
        setFile(files[0]);
        setFileUrl(URL.createObjectURL(files[0]));
        setError('');
      } else {
        setError('Filtypen är inte godkänd.');
      }
    }
  };

  const clearFile = () => {
    setFile(null);
    setFileUrl(null);
    setError('');
    fileInput.current.value = '';
  };

  const acceptTypes = () =>
    accept.map((a) => {
      switch (a) {
        case 'image':
          return 'image/*';
        case 'pdf':
          return 'application/pdf';
        case 'csv':
          return '.csv';
        default:
          return null;
      }
    }).filter((a) => a !== null).join(',');

  const renderFileUploader = () => (
    <React.Fragment>
      {error !== '' && <Typography color="error" variant="body1">{error}</Typography>}
      <input
        accept={acceptTypes()}
        className={classes.input}
        id="upload-file"
        type="file"
        onChange={(e) => saveFile(e.target.files)}
        ref={fileInput}
      />
      <label htmlFor="upload-file">
        <Button variant="contained" color="primary" component="span">
          {file === null ? 'Lägg till fil' : 'Ändra fil'}
        </Button>
      </label>
      {file !== null &&
        <Button
          className={classes.clearButton}
          variant="outlined"
          onClick={() => clearFile()}
        >
          Ta bort fil
        </Button>
      }
      {fileUrl !== null &&
        <React.Fragment>
          Förhandsvisning:
          <object data={fileUrl} width="100%" style={{ height: '50vh' }}/>
        </React.Fragment>
      }
    </React.Fragment>
  );

  return {
    file, error, clearFile, setFile, renderFileUploader
  };
};
