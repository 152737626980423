import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  Grid,
  makeStyles,
  TextField,
  FormControl, Select, MenuItem, Checkbox, ListItemText
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { surveys } from 'utils/surveys';

const useStyles = makeStyles(() => ({
  item: {
    marginBottom: 20,
  },
  input: {
    height: 40
  },
  textField: {
    '& > div:first-child': {
      height: 40
    }
  },
  select: {
    padding: '10px 20px',
  },
  button: {
    float: 'right',
    marginLeft: 10
  },
  questionInputWrapper: {
    margin: '10px 0'
  },
  questionInput: {
    '& > div': {
      padding: '10px 10px 10px 20px'
    }
  },
}));

const HealthSurveyForm = ({
  gender, surveyVersionId, updateAnswers, updateExtraData
}) => {
  const classes = useStyles();

  // Gets the latest survey
  const survey = surveys.find((s) => s.version === surveyVersionId);

  const numberOfQuestions = () => {
    let questions = 0;
    for (let s = 0; s < survey.sections.length; s++) {
      for (let q = 0; q < survey.sections[s].questions.length; q++) {
        if (survey.sections[s].questions[q].gender && gender !== 'Unknown') {
          questions += survey.sections[s].questions[q].gender === gender ? 1 : 0;
        } else {
          questions++;
        }
      }
    }
    return questions;
  };
  const [answers, setAnswers] = useState(new Array(numberOfQuestions()).fill().map(() => ({
    answer: [''], input: '', section: null, question: null
  })));
  const [extraData, setExtraData] = useState({
    comment: ''
  });
  const addAnswer = (question, sIndex, qIndex, answer, multiple = false) => {
    const newAnswers = [...answers];
    newAnswers[question].question = qIndex;
    newAnswers[question].section = sIndex;
    if (multiple) {
      if (answers[question].answer[0] === '') {
        newAnswers[question].answer = [answer[1]];
      } else {
        newAnswers[question].answer = answer;
      }
    } else {
      newAnswers[question].answer = [answer];
    }
    setAnswers(newAnswers);
    updateAnswers(answers);
  };

  const handleUpdateExtraData = (key, value) => {
    if (key in extraData) {
      const newExtraData = { ...extraData };
      newExtraData[key] = value;
      setExtraData(newExtraData);
      updateExtraData(extraData);
    }
  };

  const addAnswerInput = (question, input) => {
    answers[question].input = input;
    setAnswers(answers);
    updateAnswers(answers);
  };

  let a = 0;
  return (
      <React.Fragment>
        {survey.sections.map((section, sIndex) => (
          <ExpansionPanel key={section.name} defaultExpanded>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography variant="subtitle1">{section.name}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container>
                {section.questions.map((question, qIndex) => {
                  if (question.gender && gender !== 'Unknown') {
                    if (question.gender !== gender) return null;
                  }
                  a++;
                  const b = a - 1;
                  return (
                    <Grid item xs={12} className={classes.item} key={section.name + qIndex}>
                      {question.multiple ?
                        <React.Fragment>
                          <FormControl variant="outlined" fullWidth>
                            {question.name}
                            <Select
                              multiple
                              required
                              className={classes.input}
                              classes={{
                                select: classes.select
                              }}
                              value={answers[b].answer}
                              renderValue={(answers) => {
                                if (answers[0] === '') return 'Välj svar';
                                return answers.map((answer) => question.choices[answer].name).join(', ');
                              }}
                              onChange={(e) => addAnswer(b, sIndex, qIndex, e.target.value, true)}
                              inputProps={{
                                name: `question-${section.name}-${qIndex}-name`,
                                id: `question-${section.name}-${qIndex}`,
                              }}
                            >
                              {question.choices.map((choice, cIndex) =>
                                <MenuItem key={`choice-${section.name}-${qIndex}-${cIndex}`} value={cIndex}>
                                  <Checkbox checked={answers[b].answer.indexOf(cIndex) > -1} color="primary"/>
                                  <ListItemText primary={choice.name} />
                                </MenuItem>)
                              }
                            </Select>
                          </FormControl>
                          {answers[b].answer[0] !== '' && question.input !== '' &&
                          <div className={classes.questionInputWrapper}>
                            {question.input}
                            <TextField
                              fullWidth
                              required={question.inputRequired}
                              multiline={true}
                              rows={4}
                              className={classes.questionInput}
                              type="text"
                              variant="outlined"
                              value={answers[b].text}
                              onChange={(e) => addAnswerInput(b, e.target.value)}
                            />
                          </div>
                          }
                        </React.Fragment>
                        : question.type === 'input' ?
                          <div>
                            {question.name}
                            <TextField
                              fullWidth
                              required
                              className={classes.textField}
                              inputProps={{
                                min: 1,
                                step: 0.01
                              }}
                              type={question.inputType}
                              variant="outlined"
                              value={answers[b].answer[0]}
                              onChange={(e) => addAnswer(b, sIndex, qIndex, e.target.value)}
                            />
                          </div>
                          :
                        <React.Fragment>
                          <FormControl variant="outlined" fullWidth>
                            {question.name}
                            <Select
                              native
                              required
                              className={classes.input}
                              classes={{
                                select: classes.select
                              }}
                              value={answers[b].answer[0]}
                              onChange={(e) => addAnswer(b, sIndex, qIndex, e.target.value)}
                              inputProps={{
                                name: `question-${section.name}-${qIndex}-name`,
                                id: `question-${section.name}-${qIndex}`,
                              }}
                            >
                              {answers[b].answer[0] === '' && <option value={''} disabled>Väj ett svar</option>}
                              {question.choices.map((choice, cIndex) =>
                                <option key={`choice-${section.name}-${qIndex}-${cIndex}`} value={cIndex}>{choice.name}</option>)
                              }
                              }
                            </Select>
                          </FormControl>
                          {answers[b].answer[0] !== '' && (question.choices[answers[b].answer].input !== '' || question.input !== '') &&
                          <div className={classes.questionInputWrapper}>
                            {question.choices[answers[b].answer].input !== ''
                              ? question.choices[answers[b].answer].input
                              : question.input !== '' && question.input
                            }
                            <TextField
                              fullWidth
                              required={question.choices[answers[b].answer].inputRequired}
                              multiline={true}
                              rows={4}
                              className={classes.questionInput}
                              type="text"
                              variant="outlined"
                              value={answers[b].text}
                              onChange={(e) => addAnswerInput(b, e.target.value)}
                            />
                          </div>
                          }
                        </React.Fragment>
                      }
                    </Grid>);
                })}
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>))}
        <ExpansionPanel defaultExpanded>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography variant="subtitle1">Övrigt</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails style={{ flexDirection: 'column' }}>
            <div>Övriga synpunkter</div>
            <TextField
              fullWidth
              multiline={true}
              rows={4}
              className={classes.questionInput}
              type="text"
              variant="outlined"
              value={extraData.comment}
              onChange={(e) => handleUpdateExtraData('comment', e.target.value)}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </React.Fragment>
  );
};

HealthSurveyForm.propTypes = {
  gender: PropTypes.string.isRequired,
  surveyVersionId: PropTypes.number.isRequired,
  updateAnswers: PropTypes.func.isRequired,
  updateExtraData: PropTypes.func.isRequired,
};

export default HealthSurveyForm;
