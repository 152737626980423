import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import {
  LinearProgress, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Box
} from '@material-ui/core';

import {
  ArrowForward as ArrowForwardIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@material-ui/icons';

import { useDoctorDispatch, useDoctorState } from 'modules/admin/doctors';

import ButtonBar from 'components/ButtonBar';
import useToggle from 'hooks/useToggle';
import CreateDoctorForm from './index/CreateDoctorForm';

const Index = () => {
  const [open, toggleOpen] = useToggle(false);

  const { fetchAll } = useDoctorDispatch();
  const { fetching, fetched, doctors } = useDoctorState();

  useEffect(() => {
    if (!fetched) fetchAll();
  }, []);

  const buttons = [{
    name: 'Lägg till läkare',
    icon: 'add',
    onClickEvent: 'func',
    onClick: toggleOpen
  }, {
    icon: 'refresh',
    onClickEvent: 'func',
    onClick: fetchAll
  }];

  return (
    <div className="elevated-container">
    {fetching
      ? <LinearProgress />
      :
      <React.Fragment>
        <Box ml="10px">
          <ButtonBar buttons={buttons} />
          <CreateDoctorForm
            toggleOpen={toggleOpen}
            open={open}
          />
        </Box>
        {doctors.length === 0
          ? <React.Fragment>Hittade inga läkare.</React.Fragment>
          :
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Namn</TableCell>
                <TableCell>Typ</TableCell>
                <TableCell/>
              </TableRow>
            </TableHead>
            <TableBody>
              { doctors.map((doctor) => (
                <TableRow key={doctor.id}>
                  <TableCell component="th" scope="row">
                    {doctor.name}
                  </TableCell>
                  <TableCell>{doctor.type === 'Doctor' ? 'Läkare' : doctor.type === 'Therapist' ? 'Terapeut' : ''}</TableCell>
                  <TableCell>
                    <Tooltip title="Öppna läkarprofil">
                      <IconButton
                        component={Link}
                        to={`/admin/doctors/${doctor.id}/statements`}
                      >
                        <ArrowForwardIcon color="primary"/>
                      </IconButton>
                    </Tooltip>
                    {/*<Tooltip title="Redigera läkare (TBI)">
                      <IconButton>
                        <EditIcon color="primary"/>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Ta bort läkare (TBI)">
                      <IconButton>
                        <DeleteIcon color="error"/>
                      </IconButton>
                    </Tooltip>*/}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        }
      </React.Fragment>
      }
    </div>
  );
};

export default Index;
