import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PeopleIcon from '@material-ui/icons/People';
import NotificationIcon from '@material-ui/icons/Notifications';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Badge from '@material-ui/core/Badge';

import { useAccountState } from 'modules/account';
import { useNotificationState } from 'modules/notifications';

const DoctorMenu = ({ toggle, isMobile, pathname }) => {
  const { user: { unreadNotifications: userUnreadNotifications } } = useAccountState();
  const { unreadNotifications } = useNotificationState();

  return (
    <React.Fragment>
      <Divider/>
      <List>
        <ListItem
          button component={Link}
          to="/doctor/patients"
          selected={pathname.includes('/doctor/patients')}
          onClick={() => isMobile && toggle()}
        >
          <ListItemIcon><PeopleIcon/></ListItemIcon>
          <ListItemText primary="Klienter"/>
        </ListItem>
        <ListItem
          button component={Link}
          to="/doctor/notifications"
          selected={pathname.includes('/doctor/notifications')}
          onClick={() => isMobile && toggle()}
        >
          <ListItemIcon>
            <Badge badgeContent={unreadNotifications !== null ? unreadNotifications : userUnreadNotifications} color="error">
              <NotificationIcon />
            </Badge>
          </ListItemIcon>
          <ListItemText primary="Notifikationer"/>
        </ListItem>
      </List>
    </React.Fragment>
  );
};

DoctorMenu.propTypes = {
  toggle: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  pathname: PropTypes.string.isRequired,
};

export default DoctorMenu;
