import MuiTableBody from './MuiTableBody';
import MuiTableHead from './MuiTableHead';
import MuiTab from './MuiTab';
import MuiTabs from './MuiTabs';
import MUIRichTextEditor from './MUIRichTextEditor';

export default {
  MuiTableBody,
  MuiTableHead,
  MuiTab,
  MuiTabs,
  MUIRichTextEditor
};
