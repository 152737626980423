import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory, Redirect } from 'react-router-dom';
import { Formik } from 'formik';
import {
  Button, Container, Grid, TextField, Typography, LinearProgress, FormControl, Select,
} from '@material-ui/core';

import { useAccountState, useAccountDispatch } from 'modules/account';
import { DevLoginSchema } from 'utils/validation';
import { getReturnUrl } from 'utils/helpers';
import { COMMON_ENDPOINTS } from '../utils/api';

const DevLogin = () => {
  const [isDevEnv, setIsDevEnv] = useState(null);
  const [selectedRole, setSelectedRole] = useState('-p');
  const history = useHistory();
  const { authenticated } = useAccountState();
  const { devLogin } = useAccountDispatch();

  useEffect(() => {
    axios.get(`${COMMON_ENDPOINTS.ACCOUNT}/environment`).then((res) => {
      if (!res.data) return history.push('/');
      return setIsDevEnv(true);
    });
  }, []);

  return (authenticated ?
    <Redirect to="/"/>
    : isDevEnv === null ?
      <LinearProgress/>
      :
      <Formik
        initialValues={{
          personalNumber: '',
          password: ''
        }}
        validationSchema={DevLoginSchema}
        onSubmit={(values) => {
          devLogin(`${values.personalNumber}${selectedRole}`, values.password).then(() => history.push(getReturnUrl()));
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit
        }) => (
          <form onSubmit={handleSubmit} className="elevated-container">
            <Container maxWidth="sm">
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h2" align="center">
                    Logga in
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl variant="outlined" fullWidth>
                    Välj roll
                    <Select
                      native
                      value={selectedRole}
                      onChange={(e) => setSelectedRole(e.target.value)}
                      inputProps={{
                        name: 'role',
                        id: 'role-select',
                      }}
                    >
                      <option value="-p">Patient</option>
                      <option value="-d">Läkare</option>
                      <option value="-a">Admin</option>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    name="personalNumber"
                    type="text"
                    label="Personnummer (ÅÅÅÅMMDDXXXX)"
                    fullWidth
                    helperText={touched.personalNumber && errors.personalNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.personalNumber}
                    error={touched.personalNumber && errors.personalNumber}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    name="password"
                    type="password"
                    label="Lösenord"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    error={touched.password && errors.password}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="outlined"
                    fullWidth
                  >
                    Logga in
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" align="center">
                    Utvecklarlogin nedan
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    onClick={() => devLogin('111111111111-a', 'Password123!').then(() => history.push(getReturnUrl()))}
                    variant="outlined"
                    fullWidth
                  >
                    Admin
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    onClick={() => devLogin('222222222222-d', 'Password123!').then(() => history.push(getReturnUrl()))}
                    variant="outlined"
                    fullWidth
                  >
                    Doctor
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    onClick={() => devLogin('333333333333-p', 'Password123!').then(() => history.push(getReturnUrl()))}
                    variant="outlined"
                    fullWidth
                  >
                    Patient
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </form>
        )}
      />
  );
};

export default DevLogin;
