import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import {
  makeStyles, Grid, Typography, TextField, Button
} from '@material-ui/core';

import { useCaseDispatch as useCaseDispatchAdmin } from 'modules/admin/cases';
import { useCaseDispatch as useCaseDispatchDoctor } from 'modules/doctor/cases';
import { usePatientDispatch as usePatientDispatchAdmin } from 'modules/admin/patients';
import { usePatientDispatch as usePatientDispatchDoctor } from 'modules/doctor/patients';
import { StatementCreationSchema } from 'utils/validation';
import { useAccountState } from '../modules/account';

import { DoctorType } from '../utils/enums';

const useStyles = makeStyles((theme) => ({
  section: {
    marginBottom: 70,
  },
  item: {
    padding: theme.spacing(4, 2),
    borderBottom: '1px solid black'
  },
  header: {
    fontWeight: 'bold',
    marginBottom: 10,
  },
  content: {
    whiteSpace: 'pre-line',
    wordWrap: 'break-word'
  },
  addNew: {
    marginTop: 30,
    '& > .header': {
      fontSize: 20
    },
    '& > div': {
      marginBottom: 10
    },
    '& .bottom': {
      display: 'flex',
      justifyContent: 'space-between'
    }
  }
}));

const Statements = ({
  healthStatements, doctorStatements, role, id, page,
}) => {
  const classes = useStyles();

  let useDispatch;
  if (page === 'patient') {
    useDispatch = role === 'admin' ? usePatientDispatchAdmin : usePatientDispatchDoctor;
  } else if (page === 'case') {
    useDispatch = role === 'admin' ? useCaseDispatchAdmin : useCaseDispatchDoctor;
  }
  const { createStatement } = useDispatch();
  const { user } = useAccountState();

  console.log(user, DoctorType);

  const StatementForm = () =>
    <Formik
      initialValues={{ content: '' }}
      validationSchema={StatementCreationSchema}
      onSubmit={(values, action) => {
        createStatement(id, values).then(() => action.resetForm()).catch(() => action.setSubmitting(false));
      }}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
      }) => (
        <form onSubmit={handleSubmit} className={classes.addNew}>
          <div className="header">Lägg till ett nytt utlåtande</div>
          <TextField
            variant="outlined"
            fullWidth
            multiline={true}
            rows={8}
            name="content"
            label="Meddelande"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.content}
            error={touched.content && errors.content}
          />
          <div className="bottom">
            <Button
              variant="outlined"
              type="submit"
              disabled={isSubmitting}
            >
              Lägg till
            </Button>
          </div>
        </form>
      )}
    />;

  return (
    <React.Fragment>
      <div className={classes.section}>
        {healthStatements.length > 0
          ? <Typography variant="h2" className={classes.header}>Hälsoutlåtanden</Typography>
          : <Typography variant="h5" className={classes.header}>Hittade inga hälsoutlåtanden</Typography>
        }
        { healthStatements.map((statement) =>
          <Grid key={`health-statement-${statement.id}`} className={classes.item} container justify="space-between" alignItems="center">
            <Grid item xs={10}>
              <div className={classes.header}>
                { statement.admin.name }
                { page !== 'case' && statement.case !== null &&
                <React.Fragment> - <Link to={`/${role}/cases/${statement.case.id}/tests`}>Tillhör följande ärende</Link></React.Fragment>
                }
              </div>
              <div className={classes.content}>{statement.content}</div>
            </Grid>
            <Grid item>
              {statement.createdAt.split('T')[0]}
            </Grid>
          </Grid>)
        }
        {role === 'admin' && StatementForm()}
      </div>
      <div className={classes.section}>
        {doctorStatements.filter((statement) => statement.doctor.type === Object.keys(DoctorType)[DoctorType.Therapist]).length > 0
          ? <Typography variant="h2" className={classes.header}>Läkarutlåtanden</Typography>
          : <Typography variant="h5" className={classes.header}>Hittade inga läkarutlåtanden</Typography>
        }
        { doctorStatements.filter((statement) => statement.doctor.type === Object.keys(DoctorType)[DoctorType.Doctor]).map((statement) =>
          <Grid key={`doctor-statement-${statement.id}`} className={classes.item} container justify="space-between" alignItems="center">
            <Grid item xs={10}>
              <div className={classes.header}>
                {statement.doctor.name}
                {page !== 'case' && statement.case !== null &&
                  <React.Fragment> - <Link to={`/${role}/cases/${statement.case.id}/tests`}>Tillhör följande ärende</Link></React.Fragment>
                }
              </div>
              <div className={classes.content}>{statement.content}</div>
            </Grid>
            <Grid item>
              {statement.createdAt.split('T')[0]}
            </Grid>
          </Grid>)
        }
        {role === 'doctor' && user.doctorType === Object.keys(DoctorType)[DoctorType.Doctor] && StatementForm()}
      </div>
      <div className={classes.section}>
        {doctorStatements.filter((statement) => statement.doctor.type === Object.keys(DoctorType)[DoctorType.Therapist]).length > 0
          ? <Typography variant="h2" className={classes.header}>Terapeututlåtanden</Typography>
          : <Typography variant="h5" className={classes.header}>Hittade inga terapeututlåtanden</Typography>
        }
        {doctorStatements.filter((statement) => statement.doctor.type === Object.keys(DoctorType)[DoctorType.Therapist]).map((statement) =>
          <Grid key={`doctor-statement-${statement.id}`} className={classes.item} container justify="space-between" alignItems="center">
            <Grid item xs={10}>
              <div className={classes.header}>
                {statement.doctor.name}
                {page !== 'case' && statement.case !== null &&
                <React.Fragment> - <Link to={`/${role}/cases/${statement.case.id}/tests`}>Tillhör följande ärende</Link></React.Fragment>
                }
              </div>
              <div className={classes.content}>{statement.content}</div>
            </Grid>
            <Grid item>
              {statement.createdAt.split('T')[0]}
            </Grid>
          </Grid>)
        }
        {role === 'doctor' && user.doctorType === Object.keys(DoctorType)[DoctorType.Therapist] && StatementForm()}
      </div>
    </React.Fragment>
  );
};

Statements.propTypes = {
  healthStatements: PropTypes.array,
  doctorStatements: PropTypes.array,
  role: PropTypes.oneOf(['admin', 'doctor']).isRequired,
  page: PropTypes.oneOf(['patient', 'case']).isRequired,
  id: PropTypes.number.isRequired,
};

export default Statements;
