import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { TextField, makeStyles } from '@material-ui/core';

import FormDialog from 'components/FormDialog';

import { useCaseDispatch } from 'modules/admin/cases';

import { ReferralsAddedToCaseSchema } from 'utils/validation';

const useStyles = makeStyles(() => ({
  form: {
    '& > div': {
      marginBottom: 10
    }
  },
}));

const UploadReferralForm = ({
  toggleOpen, open, caseId
}) => {
  const classes = useStyles();
  const { addReferrals } = useCaseDispatch();

  return (
    <Formik
      initialValues={{
        referralIds: ''
      }}
      validationSchema={ReferralsAddedToCaseSchema}
      onSubmit={(values, actions) => {
        addReferrals(caseId, values.referralIds.replace(/ /g, '').split(','))
          .then(() => {
            actions.resetForm();
            toggleOpen();
          })
          .catch(() => actions.setSubmitting(false));
      }}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        resetForm
      }) => (
        <FormDialog
          title="Ladda upp nya remiss id"
          description="Fyll i remiss id (rid) från labportalen. För att ladda upp fler på en gång dela upp med ett ',' mellan varje id. Denna funktion används endast om ni skapat manuella remisser i labportalen som vi inväntar svar på."
          open={open}
          toggle={() => {
            toggleOpen();
            resetForm();
          }}
          onSubmitDisabled={isSubmitting}
          onSubmit={handleSubmit}
        >
          <form onSubmit={handleSubmit} className={classes.form}>
            <TextField
              variant="outlined"
              fullWidth
              required
              name="referralIds"
              label="Remiss id (rid)"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.referralIds}
              error={touched.referralIds && errors.referralIds}
            />
          </form>
        </FormDialog>
      )}
    />
  );
};

UploadReferralForm.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  caseId: PropTypes.number.isRequired,
};

export default UploadReferralForm;
