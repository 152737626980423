import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { PATIENT_ENDPOINTS } from 'utils/api';

const FETCHING = 'PATIENT/CASES/FETCHING';
const FETCHED_ALL = 'PATIENT/CASES/FETCHED_ALL';
const FETCHED_SINGLE = 'PATIENT/CASES/FETCHED_SINGLE';
const CREATE_ATTACHMENT = 'PATIENT/CASES/CREATE_ATTACHMENT';
const CLEAR_CURRENT = 'PATIENT/CASES/CLEAR_CURRENT';

const initialState = {
  fetching: false,
  data: [],
  current: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCHED_ALL:
      return {
        ...state,
        fetching: false,
        data: action.payload
      };
    case FETCHED_SINGLE:
      return {
        ...state,
        fetching: false,
        current: action.payload
      };
    case FETCHING:
      return {
        ...state,
        fetching: true,
      };
    case CLEAR_CURRENT:
      return {
        ...state,
        current: {}
      };
    case CREATE_ATTACHMENT:
    default:
      return state;
  }
};

export const fetchAll = (showLoader = true) => (dispatch) => {
  if (showLoader) dispatch({ type: FETCHING });

  return axios.get(`${PATIENT_ENDPOINTS.CASES}`)
    .then((res) => dispatch({ type: FETCHED_ALL, payload: res.data }));
};

export const fetchSingle = (id, showLoader = true) => (dispatch) => {
  if (showLoader) dispatch({ type: FETCHING });

  return axios.get(`${PATIENT_ENDPOINTS.CASES}/${id}`)
    .then((res) => dispatch({ type: FETCHED_SINGLE, payload: res.data }));
};

export const createAttachment = (id, newAttachment) => (dispatch) =>
  axios.post(`${PATIENT_ENDPOINTS.CASES}/${id}/attachment`, newAttachment,{
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
    .then(() => {
      dispatch({ type: CREATE_ATTACHMENT });
      dispatch(fetchSingle(id, false));
    });

export const clearCurrent = () => (dispatch) => dispatch({ type: CLEAR_CURRENT });

export const useCaseState = () => ({
  fetching: useSelector(({ patient: { cases: { fetching } } }) => fetching),
  cases: useSelector(({ patient: { cases: { data } } }) => data),
  current: useSelector(({ patient: { cases: { current } } }) => current),
});

export const useCaseDispatch = () => {
  const dispatch = useDispatch();
  return {
    fetchSingle: (id) => dispatch(fetchSingle(id)),
    fetchAll: () => dispatch(fetchAll()),
    createAttachment: (id, newAttachment) => dispatch(createAttachment(id, newAttachment)),
    clearCurrent: () => dispatch(clearCurrent()),
  };
};
