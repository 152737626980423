import React, { useEffect } from 'react';
import {
  Avatar, Button, Grid, makeStyles, TextField, LinearProgress
} from '@material-ui/core';
import { Formik } from 'formik';

import { MessageCreationSchema } from 'utils/validation';
import { useMessageDispatch, useMessageState } from 'modules/patient/messages';

const useStyles = makeStyles((theme) => ({
  message: {
    padding: theme.spacing(3, 0),
    width: '100%',
  },
  contentWrapper: {
    borderBottom: '1px solid #C0C0C0',
    paddingBottom: 20,
  },
  messageHeader: {
    fontSize: 20,
    marginBottom: 10,
    fontWeight: '#C0C0C0'
  },
  date: {
    color: 'grey'
  },
  content: {
    whiteSpace: 'pre-line',
    wordWrap: 'break-word'
  },
  addNew: {
    marginTop: 30,
    padding: 10,
    '& > .header': {
      fontSize: 20
    },
    '& > div': {
      marginBottom: 10
    },
    '& .bottom': {
      display: 'flex',
      justifyContent: 'space-between'
    }
  }
}));

const Messages = () => {
  const classes = useStyles();
  const { fetchAll, createMessage } = useMessageDispatch();
  const { fetching, messages } = useMessageState();

  useEffect(() => {
    fetchAll();
  }, []);

  const MessageForm = () =>
    <Formik
      initialValues={{ content: '' }}
      validationSchema={MessageCreationSchema}
      onSubmit={(values, action) => {
        createMessage(values).then(() => action.resetForm()).catch(() => action.setSubmitting(false));
      }}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
      }) => (
        <form onSubmit={handleSubmit} className={classes.addNew}>
          <div className="header">Lägg till ett nytt meddelande</div>
          <TextField
            variant="outlined"
            fullWidth
            multiline={true}
            rows={8}
            name="content"
            label="Meddelande"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.content}
            error={touched.content && errors.content}
          />
          <div className="bottom">
            <Button
              variant="outlined"
              type="submit"
              disabled={isSubmitting}
            >
              Lägg till
            </Button>
          </div>
        </form>
      )}
    />;

  return (
    <div className="elevated-container">
      {fetching ?
        <LinearProgress />
        : messages.length === 0 ?
          'Hittade inga meddelanden'
          : messages.map((message) =>
          <Grid container key={message.id} justify="space-around" alignItems="center" className={classes.message} spacing={1}>
            <Grid item>
              <Avatar>{message.creatorName.charAt(0)}</Avatar>
            </Grid>
            <Grid item xs={10}>
              <Grid container justify="space-between" className={classes.contentWrapper}>
                <Grid item xs={12}>
                  <div className={classes.messageHeader}>
                    {message.creatorName}
                  </div>
                  <div className={classes.content}>{message.content}</div>
                </Grid>
                <Grid item className={classes.date}>
                  {message.createdAt.split('T')[0]}
                </Grid>
              </Grid>
            </Grid>
          </Grid>)
      }
      {MessageForm()}
    </div>
  );
};

export default Messages;
