import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  FormControl, InputLabel, Select, TextField, MenuItem, Typography, makeStyles, LinearProgress, Grid,
} from '@material-ui/core';

import { ReferralCreationSchema } from 'utils/validation';
import { useLaboratoryDispatch, useLaboratoryState } from 'modules/admin/laboratories';
import { useReferralDispatch } from 'modules/admin/referrals';
import MultiSelect from 'components/MultiSelect';
import FormDialog from 'components/FormDialog';

const useStyles = makeStyles(() => ({
  form: {
    '& > div': {
      marginBottom: 10
    },
    '& > button': {
      textAlign: 'right'
    }
  },
}));

const CreateHealthPackage = ({
  open, toggleOpen, edit, editReferral, clearEditReferral
}) => {
  const classes = useStyles();
  const [analysisList, setAnalysisList] = useState([]);
  const [differingAnalysisList, setDifferingAnalysisList] = useState([]);
  const { fetching, laboratories } = useLaboratoryState();
  const { fetchAll } = useLaboratoryDispatch();
  const { createReferral, updateReferral } = useReferralDispatch();

  useEffect(() => {
    fetchAll();
  }, []);

  useEffect(() => {
    if (laboratories.length > 0 && edit) {
      if (editReferral.analysisList !== null && editReferral.analysisList.length > 0) {
        const editAnalysisList = editReferral.analysisList.map((analysis) => ({
          label: analysis.name,
          value: analysis.code
        }));
        setAnalysisList(editAnalysisList);
      } else if (editReferral.needsVerification && editReferral.oldAnalysisList !== null && editReferral.oldAnalysisList.length > 0) {
        const editAnalysisList = [];
        const differingAnalysisList = [];
        editReferral.oldAnalysisList.forEach((oldAnalysis) => {
          if (!editReferral.laboratory.analysis.Analysislist.find((a) => a.AnalysisCode === oldAnalysis.code)) {
            differingAnalysisList.push(oldAnalysis);
          } else {
            editAnalysisList.push({
              label: oldAnalysis.name,
              value: oldAnalysis.code
            });
          }
        });
        setDifferingAnalysisList(differingAnalysisList);
        setAnalysisList(editAnalysisList);
      }
    }
  }, [laboratories, editReferral, edit]);

  return (
    <Formik
      key={edit ? `referral-modal-${editReferral.id}` : 'referral-modal'}
      initialValues={{
        name: edit ? editReferral.name : '',
        laboratoryId: edit ? editReferral.laboratoryId : '',
      }}
      validationSchema={ReferralCreationSchema}
      onSubmit={(values, actions) => {
        if (edit) {
          const updatedReferral = {
            ...values,
            analysisList:
              analysisList.map((analysis) => ({ code: analysis.value, name: analysis.label })),
          };
          updateReferral(editReferral.id, updatedReferral).then(() => {
            toggleOpen();
            setAnalysisList([]);
            clearEditReferral();
          }).catch(() => actions.setSubmitting(false));
        } else {
          const newReferral = {
            ...values,
            analysisList:
              analysisList.map((analysis) => ({ code: analysis.value, name: analysis.label })),
          };
          createReferral(newReferral).then(() => {
            toggleOpen();
            actions.resetForm();
          }).catch(() => actions.setSubmitting(false));
        }
      }}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        resetForm,
      }) => (
        <FormDialog
          title={edit
            ? 'Uppdatera remiss'
            : 'Lägg till ett remiss'
          }
          description={edit
            ? 'Ändra informationen nedan för att uppdatera remissen.'
            : 'Fyll i informationen nedan för att skapa en ny remiss.'
          }
          open={open}
          toggle={() => {
            resetForm();
            clearEditReferral();
            setAnalysisList([]);
            toggleOpen();
          }}
          onSubmitDisabled={isSubmitting}
          onSubmit={handleSubmit}
        >
          <form onSubmit={handleSubmit} className={classes.form} autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  required
                  name="name"
                  label="Remissens namn"
                  type="text"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  error={touched.name && errors.name}
                />
              </Grid>
              <Grid item xs={12}>
                {fetching ?
                  <LinearProgress/>
                  : laboratories.length === 0 ?
                    'Hittade inga laboratorier.'
                    :
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="referral-laboratory-label">Laboratorie</InputLabel>
                      <Select
                        id="referral-laboratory-id"
                        value={values.laboratoryId}
                        onChange={(e) => {
                          handleChange(e, 'laboratoryId');
                          setAnalysisList([]);
                        }}
                        labelWidth={61}
                        inputProps={{
                          name: 'laboratoryId',
                          id: 'laboratoryId-select',
                        }}
                      >
                        {laboratories.map((lab) =>
                          <MenuItem
                            key={lab.id}
                            disabled={lab.analysis.Analysislist === null}
                            value={lab.id}
                          >
                            {lab.analysis.Analysislist === null ? `${lab.name} (inga analyser)` : lab.name}
                          </MenuItem>)
                        }
                      </Select>
                    </FormControl>
                }
              </Grid>
              {values.laboratoryId !== '' &&
                <Grid item xs={12}>
                  <MultiSelect
                    selectId="referral-select"
                    onChange={(newValues) => setAnalysisList(newValues)}
                    suggestions={laboratories.find((t) => t.id === values.laboratoryId).analysis.Analysislist.map((a) => ({ value: a.AnalysisCode, label: a.AnalysisName }))}
                    values={analysisList}
                  />
                </Grid>
              }
              {edit && editReferral.needsVerification &&
                <Grid item xs={12}>
                  Laboratoriet som denna remissen tillhör har uppdaterat sin analyslista därför behöver remissen uppdateras för att nya remisser ska kunna skickas.
                  Koderna som inte har ändrats har automatiskt fyllts i ovan och nedan visas koderna som har ändrats.
                  {differingAnalysisList.length > 0 &&
                  <React.Fragment>
                    <div>
                      <br/>
                     <b>Namn</b> - <b>Kod</b>
                    </div>
                    {differingAnalysisList.map((analysis) => (
                      <Typography color="error" key={analysis.code}>{analysis.name} - {analysis.code}</Typography>
                    ))}
                  </React.Fragment>
                  }
                </Grid>
              }
            </Grid>
          </form>
        </FormDialog>
      )}
    />
  );
};

CreateHealthPackage.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  edit: PropTypes.bool,
  editReferral: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  clearEditReferral: PropTypes.func,
};

export default CreateHealthPackage;
