import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const FormDialog = ({
  title, description, open, toggle, onSubmit, onSubmitDisabled, children
}) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={toggle}
      PaperProps={{
        component: 'form',
        onSubmit: onSubmit || (() => {}),
        className: classes.paper
      }}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle disableTypography className={classes.header}>
        <Typography variant="h6">{title}</Typography>
        <IconButton aria-label="close" onClick={toggle} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          {description}
        </DialogContentText>
        { children }
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={onSubmitDisabled}
          type="submit"
        >
          Spara
        </Button>
      </DialogActions>
    </Dialog>
  );
};

FormDialog.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onSubmit: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  onSubmitDisabled: PropTypes.bool,
};

FormDialog.defaultProps = {
  onSubmitDisabled: false,
  onSubmit: false,
};

export default FormDialog;
