import green from '@material-ui/core/colors/green';

export default {
  primary: {
    main: '#161e56',
  },
  secondary: {
    main: '#f6e569',
  },
  action: {
    disabledBackground: 'none'
  },
  success: {
    main: green[700],
    contrastText: '#fff'
  }
};
