import React from 'react';
import { Redirect } from 'react-router-dom';
import { Formik } from 'formik';
import axios from 'axios';
import {
  Button, Container, Grid, TextField, Typography
} from '@material-ui/core';

import { useAccountState } from 'modules/account';
import { LoginSchema } from 'utils/validation';
import { getReturnUrl } from 'utils/helpers';
import { COMMON_ENDPOINTS } from '../utils/api';

const DoctorLogin = () => {
  const { authenticated } = useAccountState();
  return (authenticated ?
    <Redirect to="/doctor/patients"/>
    :
    <Formik
      initialValues={{
        personalNumber: '',
      }}
      validationSchema={LoginSchema}
      onSubmit={(values) => {
        axios.post(`${COMMON_ENDPOINTS.BANKID}/login/${values.personalNumber}-d?return=${getReturnUrl()}`).then((res) => {
          window.location.replace(res.data.redirectUrl);
        });
      }}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit
      }) => (
        <form onSubmit={handleSubmit} className="elevated-container">
          <Container maxWidth="sm">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h2" align="center">
                  Logga in som läkare
                </Typography>
              </Grid>
              {window.location.href.includes('error') &&
              <Grid item xs={12}>
                <Typography variant="body1" align="center" color="error">
                  Något gick fel, verifiera att uppgifterna är korrekta och försök igen.
                </Typography>
              </Grid>
              }
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  name="personalNumber"
                  type="text"
                  label="Personnummer (ÅÅÅÅMMDDXXXX)"
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.personalNumber && errors.personalNumber}
                  value={values.personalNumber}
                  error={touched.personalNumber && errors.personalNumber}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="outlined"
                  fullWidth
                >
                  Logga in
                </Button>
              </Grid>
            </Grid>
          </Container>
        </form>
      )}
    />
  );
};

export default DoctorLogin;
