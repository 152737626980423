export default {
  root: {
    '& tr:nth-child(odd)': {
      backgroundColor: '#F0F0F0'
    },
    '& td': {
      borderBottom: '1px solid #DFE3E8',
    },
    '& > a:nth-child(odd)': {
      backgroundColor: '#F0F0F0'
    },
    '& > a': {
      borderBottom: '1px solid #DFE3E8',
    },
  }
};
