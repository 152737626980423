import React from 'react';
import { Route } from 'react-router-dom';

import { useAccountState } from 'modules/account';

const AuthRoute = ({ component: Component, ...rest }) => {
  const { authenticated } = useAccountState();
  return (authenticated !== null &&
    <Route
      {...rest}
      render={(props) => authenticated && <Component {...props}/>}
    />
  );
};

export default AuthRoute;
