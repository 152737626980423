export default {
  version: 2,
  sections: [
    {
      name: 'Basfrågor',
      color: '#e6194B',
      questions: [
        {
          name: 'Vad är din vikt i kilogram?',
          type: 'input',
          inputType: 'number',
          accessor: 'length',
        },
        {
          name: 'Vad är din längd i centimeter?',
          type: 'input',
          inputType: 'number',
          accessor: 'length',
        },
        {
          name: 'Är du gravid eller ammar?',
          multiple: false,
          input: '',
          inputRequired: false,
          scaleGroup: 'Gravid / ammar',
          gender: 'Female',
          choices: [{
            name: 'Nej',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: ''
          }, {
            name: 'Vet ej',
            input: '',
            inputRequired: false,
            scoring: 1,
            response: 'Vi rekommenderar att du kollar upp om du är gravid så du har vetskapen och slipper eventuell oro. Om du avser att bli gravid så rekommenderar vi att du kollar upp dina värden av näring och dess inbördes balans. Kolla upp tungmetaller och framför allt kolla upp de mediciner som du ev. tar. Öka ditt mineralintag, sänk Omega 6, öka din syresättning genom djupandning, avslappning.'
          }, {
            name: 'Ja',
            input: '',
            inputRequired: false,
            scoring: 2,
            response: 'Grattis till ditt kommande barn. Nu när vi vet detta kan vi ta med detta i vår beräkning vid framtida test analyser" För att ge både mamma och barn viktiga näringsmässiga förutsättningar och förhindra missbildning och andra psykiska störningar; Undvik att laga mat i aluminiumkastruller eller folie, för att förhindra Autism ofta förekommande vid för hög halt aluminium. Asperger, ADHD, dyslexi motsv. har ofta brist på Omega3, Vitamin-D och -C samt B-komplex. Kolla även järnvärdena och magnesium och zink.'
          }],
        }, {
          name: 'Vet du när du hade din senaste menstruationsdag?',
          multiple: false,
          input: '',
          inputRequired: false,
          scaleGroup: 'Menstruation',
          gender: 'Female',
          choices: [{
            name: 'Vet ej',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: ''
          }, {
            name: 'Ja',
            input: 'Ange den första dagen på din menstruationsperiod.',
            inputRequired: true,
            scoring: 1,
            response: ''
          }],
        },
        {
          name: 'Hur är din huvudsakliga kost?',
          multiple: false,
          input: '',
          inputRequired: false,
          scaleGroup: 'Kosthållning',
          choices: [{
            name: 'Vegan',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Som vegan har du större möjligheter till en ren och frisk kropp. Men kom ihåg att om du nyligen blivit vegan, behöver din kropp en viss tid för att ställa om sig innan den kan ta upp all den näring som din kropp har vant sig att få genom andra källor" Att omvandla omega 3 från ALA från t.ex. linfrön till EPA och DHA tar tid för kroppen att förstå. Samma sak om du tagit regelbundet C-vitamin som kosttillskott under längre tid, då kommer det ta tid för kroppen att förstå att den måste ta upp vitamin-C från frukt och rotfrukter. '
          }, {
            name: 'Vegetarian',
            input: '',
            inputRequired: false,
            scoring: 1,
            response: 'Som vegetarian så bör du få i dig de mesta du behöver. Dock kom ihåg att många vegetarianer äter ofta alldeles för ensidig kost då nästan alla färdiga matprodukter på marknaden är gjorda av soja. All mat är nyttig i rätt mängd, men all mat är giftig i fel mängd. Så var Observant på att du äter tillräckligt varierat och försök äta regnbågens alla färger varje dag samt de 4 smakerna, Salt, surt, sött och beskt. OBS: överdriv inte det söta. Försök även ta minst 60% lokalproducerat och är den mat som skördas efter respektive årstid. Då kommer du få en optimal hälsa.'
          }, {
            name: 'Allätare',
            input: '',
            inputRequired: false,
            scoring: 2,
            response: 'Vi människor har under årtusenden utvecklats att bli de mest uthållig "djuret" av alla däggdjur. Vi är även det mest anpassbara "Djuret" av alla. Detta gör att vi kan leva som rena köttätare likt Inuiter (Eskimåer) gjorde förr, vi kan även leva helt vegetariskt som majoriteten av befolkningen i Indien gör. Eller vi kan välja att vara s.k. allätare. Oavsett vilket val vi gör så måste det finnas en balans för att vi skall må bra. Att äta mycket kött innebär att din kropp utvecklas snabbt, men den åldras även snabbt. Inuiterna hade en genomsnittlig livslängd av 43 år innan de började importera frukt och grönt från resten av världen, men det var könsmogna vid 12 års ålder redan för att hinna fortplanta sig. \n' +
              'Om du äter mycket grönsaker och frukt, får du en långsammare utveckling men lever desto längre. Det som är viktigt att förstå är att var och en måste sätta upp sina mål varför äter jag som jag gör? - Vill jag leva länge? Vill jag bli elitidrottare? Vill jag öka min mentala kapacitet? Vill jag leva frisk och vitalt aktiv livet ut? Sedan vi svenskar gick med i EU, har vi ökat vår konsumtion av kött med 8% varje år och andelen sjuka i cancer, hjärt- & kärlsjukdomar, diabetes m.fl. har ökat i samma takt. \n' +
              'Så vårt råd till dig är att tänka på följande: Ät minst 60% lokalproducerad mat. (Naturen är alltid i balans, så för att vi människor och djur som lever i en viss klimatzon skall klara det klimat vi lever i, så har naturen sett till att ge oss allt vi behöver. Våra svenska bär har mellan dubbelt till 10 gånger högre näring än samma bär som växer söderut. Detta för att vi skall klara den långa mörka vintern.) Följ den mat som skördas för den årstid som gäller. (då är den som mest nyttig) Ät minst 0,5 dl av regnbågens alla färger inkl., svart och vitt varje dag (ger dig alla nödvändiga vitaminer och antioxidanter). Äta de fyra smakerna Sött, Salt, Surt och Beskt varje dag. (ger dig alla mineraler samt balansera pH-värdet) Så kommer din hälsa att hålla sig betydligt bättre än genomsnittet" Allmänt bör de flesta tänka på Syra-basbalans, minska sockerintag, minska protein, öka grönsaker och frukt i vissa fall. Rådgör med oss på Life-Check för kostkonsultation.'
          }, {
            name: 'Går på diet',
            input: 'Vilken diet?',
            inputRequired: false,
            scoring: 3,
            response: 'Alla dieter som skiljer sig från vår vanliga kosthållning kommer att kortsiktigt ge dig en förbättring, då din kropp alltid vill ha variation. Men alla dieter kommer även bli som din tidigare kosthållning, d.v.s. den hindrar en variation som din kropp behöver. Vi lever i en värld där vi reser till fjärran länder, vi lever i olika klimatzoner med olika mycket sol, värme och kyla etc. allt detta påverkar vårt behov av näring och fetter. Så att köra samma kosthållning över allt året om, fungerar inte om vi önskar en optimal hälsa och välmående. Att köra en diet som t.ex. detox (avgiftning) så måste du vara medveten om att först se till att alla dina utrensningsorgan som lever, njurar, lymfsystem mm, fungerar för att din kropp skall kunna frigör inlagrade gifter och att kroppen kan föra ut dessa, i annat fall så kan det bli allvarliga bieffekter. Kör du en alltför sträng LCHF diet där du helt utesluter kolhydrater finns det belägg för att många människor har drabbats av depression efter 3–6 månader på en sådan diet. Så var vaksam på den diet som du väljer och vilka risker du tar. Vi avråder inte från att köra en diet, men vi vill uppmärksamma dig på att den skall vara i balans med din kropps behov. Att banta bort övervikt kan leda till att hjärnan får för lite energi och du får negativa bieffekter som du inte önskar. Vill du veta mer så rådfråga oss på Life-Check.'
          }],
        },
        {
          name: 'Hur ser du på din kropp? Normalvikt, underviktig eller överviktig eller muskulös/atletisk???',
          multiple: false,
          input: '',
          inputRequired: false,
          scaleGroup: 'Din kropp',
          choices: [{
            name: 'Muskulös',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Att vara muskulös, d.v.s. vara vältränad är utmärkt, men om du tränar på elitnivå skall du vara medveten om att din kropps normala matintag inte räcker till. Då måste du söka hjälp av en kostrådgivare för att veta hur du skall äta för att inte bränna muskler och tappa vikt.'
          }, {
            name: 'Normal',
            input: '',
            inputRequired: false,
            scoring: 1,
            response: 'Att vara normal viktig låter bra. Men vad är din önskan att vara? Muskulös, snabb, uthållig, vig och flexibel? vad du än önskar så kan vi på Life-Check hjälpa dig att nå dina mål. Låt oss bli din personliga kroppsmentor. '
          }, {
            name: 'Överviktig',
            input: '',
            inputRequired: false,
            scoring: 2,
            response: 'Psykoterapi /NLP vid ohälsosam självuppfattning. Annars öka/minska vikt genom kost och rörelse. Fysiska behandling med frekvenser rekommenderas vid fetma. Om du har svårt att minska i vikt, så kan det även beror på att du har ett psykologisk undermedvetet skydd, att det finns en osäkerhet där du känner dig sårbar och behöver ett skydd. I detta fall ökad fettinlagring'
          }, {
            name: 'Underviktig',
            input: '',
            inputRequired: false,
            scoring: 3,
            response: 'Acceptans av den egna kroppen är en av förutsättningarna för välmående. I båda fallen av över- eller undervikt finns även en underliggande psykologisk faktor. Därav att det finns belägg att psykologisk NLP i de flesta fall är den bästa lösningen.'
          }],
        },
      ]
    },
    {
      name: 'Kost',
      color: '#f58231',
      questions: [
        {
          name: 'Jag äter restaurangmat och eller hämt-mat/färdiglagad mat.',
          multiple: false,
          input: '',
          inputRequired: false,
          scaleGroup: 'Industri tillagad mat',
          choices: [{
            name: 'Dagligen',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende.'
          }, {
            name: 'Ibland',
            input: '',
            inputRequired: false,
            scoring: 1,
            response: 'Vi rekomenderar utbildning och kostomläggning.',
          }, {
            name: 'Sällan',
            input: '',
            inputRequired: false,
            scoring: 2,
            response: 'Vi kan förebygga ohälsa och påverka vår livslängd och livskvalité till stor del genom vår kost och livsstil. Regelbundet intag av restaurang lagad mat kan innebära större mängder av omega 6, härdade fetter (transfetter) samt nitrit.',
          }],
        },
        {
          name: 'Jag dricker minst 1,5L vatten om dagen',
          multiple: false,
          input: '',
          inputRequired: false,
          scaleGroup: 'Vatten',
          choices: [{
            name: 'Dagligen',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende.'
          }, {
            name: 'Ibland',
            input: '',
            inputRequired: false,
            scoring: 1,
            response: 'Vi rekommenderar utbildning och kostomläggning.',
          }, {
            name: 'Sällan',
            input: '',
            inputRequired: false,
            scoring: 2,
            response: 'Vatten är bland de viktigaste näringsämnen för kroppen då vi består till stor del (ca 70%) av vatten. Det är också grundläggande för de flesta kroppsfunktioner, som t.ex. producera energi, skapa minnen i cellerna samt transportera ut näring och syre samt göra sig av med slaggprodukter mycket annat. Är det något vi skall prioritera mer än allt annat i livet så är rent vatten det bästa vi kan tillföra vår kropp.',
          }],
        },
        {
          name: 'Jag äter minimum halva tallriken med grönsaker och/eller rotfrukter (färska, frysta eller tillagade)',
          multiple: false,
          input: '',
          inputRequired: false,
          scaleGroup: 'Grönsaker',
          choices: [{
            name: 'Minst 50%',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende.'
          }, {
            name: 'Mindre än 50%',
            input: '',
            inputRequired: false,
            scoring: 1,
            response: 'Öka mänden grönsaker, följ årstiderna till minst 60%, handla närproducerat och helst ekologiskt. Vill du veta med kolla in vår blogg på www.life-check.se.',
          }, {
            name: 'Nästan aldrig',
            input: '',
            inputRequired: false,
            scoring: 2,
            response: 'Råa grönsaker ger viktiga enzymer. Grönsaker i allmänhet ger kroppen viktiga byggstenar som kolhydrater, fett, protein, vitaminer, mineraler och antioxidanter. Dessa hjälper kroppen att reglera pH-värdet som i sin tur hjälper kroppen att ta upp näring effektivare.',
          }],
        },
        {
          name: 'Jag äter något eller några av följande: gluten (vete råg korn), mjölkprodukter, tillsatt socker.',
          multiple: true,
          input: 'Hur ofta/mycket av vad??',
          inputRequired: false,
          scaleGroup: 'Gluten, Mjölkprodukter, Socker',
          choices: [{
            name: 'Inget av dessa.',
            input: '',
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende.'
          }, {
            name: 'Mjölkprodukter',
            input: '',
            scoring: 1,
            response: 'Rent generellt kan mjölkprodukter fungera som föda i mindre mängder om de kommer från en bra naturlig ekologsk källa från gräsbetande kor. Men tyvärr så är hanteringen av den industriella mjölkframställningen skadlig för det som naturligt bör finnas i en balanserad hälsosam mjölkprodukt. Vi rekomenderar därför att byta ut komjölksprodukter mot växtbaserad mjölk, eller get/fårmjölk. Även havremjölk eller mandelmjölk, kokosmjölk motsv. fungerar bra.',
          }, {
            name: 'Gluten',
            input: '',
            scoring: 2,
            response: 'Generellt bör alla människor minska intaget av Vete, som är förädlat 50 000 ggr sedan 1943, vilket gör att vetet av idag är extremt obalanserat som föda. Minska på vete, använd hellre ursprungssorter som Dinkel, Kamut, Emmer och Enkorn motsv. samt välj alltid äkta Surdegsbröd om bröd är ett måste. Eller sluta helt med vete". Referens till studie som visar att majoriteten av människor påverkas/får genomsläpplig tarm av gluten, samt att det motverkar upptag av vitaminer och mineraler.',
          }, {
            name: 'Socker',
            input: '',
            scoring: 3,
            response: 'Undvik drycker med socker och sötningsmedel helt, välj hellre natrulig äkta honung eller äkta Lönnsirap motsv. i te och kaffe eller uteslut helt socker. Överkonsumtion av socker kan leda till livsstilssjukomar som diabetes2. Ökat sockerbrär och därmed övervikt och fetma etc.',
          }],
        },
        {
          name: 'Jag stressar ofta i mig mina måltider? D.v.s. jag avslutar mina måltider snabbt. Eller äter du långsamt och sitter du kvar en stund efter maten?',
          multiple: false,
          input: '',
          inputRequired: false,
          scaleGroup: 'Stressar i mig mat',
          choices: [{
            name: 'Sällan',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende.'
          }, {
            name: 'Ibland',
            input: '',
            inputRequired: false,
            scoring: 1,
            response: 'Sitt ned och ät i lugn och ro. Djupandas gärna några minuter innan du börjar måltiden. Tugga maten noggrant och undvik dryck till maten. Drick före eller efter maten. Ta det lugnt en stund efter maten. Gör hellre rörelseaktivitet innan maten och vänta 30-60 min efter maten.',
          }, {
            name: 'Ofta',
            input: '',
            inputRequired: false,
            scoring: 2,
            response: 'När vi stressar prioriterar vår kropp att förbereda sig för kamp/flykt och prioriterar ned matsmältning. Det gör att tex magsäcken inte kan producera tillräckligt med saltsyra för att bryta ned maten. Stress gör också att blodtillförsel prioriteras till muskler istället för matsmältningsorganen. Kroppen kan då inte ta hand om maten och både matsmältning och näringsupptag försämras.',
          }],
        },
      ],
    },
    {
      name: 'Sömn',
      color: '#3cb44b',
      questions: [
        {
          name: 'Jag sover ca 7-8 timmar per natt',
          multiple: false,
          input: '',
          inputRequired: false,
          scaleGroup: 'Effektiv sovtid',
          choices: [{
            name: 'Ofta',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende'
          }, {
            name: 'Ibland',
            input: '',
            inputRequired: false,
            scoring: 1,
            response: 'Sov ej mer än 8 timmar. Helst endast 7 timmar om du inte har ett fysiskt ansträngande arbete. (Om du har en sjukdom i kroppen, rekomenderar vi att sova så mycket som kroppen behöver).',
          }, {
            name: 'Sällan',
            input: '',
            inputRequired: false,
            scoring: 2,
            response: 'Att sova mer än 8 timmar per natt kan det vara en indikation på att kroppen är i obalans.',
          }]
        },
        {
          name: 'Jag lägger mig i regel ca kl 22',
          multiple: false,
          input: 'Om inte 22.00 så när lägger du dig?',
          inputRequired: false,
          scaleGroup: 'Sovtider på dygnet',
          choices: [{
            name: 'Ofta',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende'
          }, {
            name: 'Ibland',
            input: '',
            inputRequired: false,
            scoring: 1,
            response: 'Regelbundna sovtider mår kroppe bäst av. att sova mellan 22.00 fram till 15.00 är den mest verksamma tiden på dygnet för kroppen att nå maximal vila. Stäng av TV, dataskärm, och använd helst blått ljus 1-2 timmar innan sänggående. Djupandas och meditera/slappna av timmen innan sänggående. Lägg dig helst så att du somnar vid 22.',
          }, {
            name: 'Sällan',
            input: '',
            inputRequired: false,
            scoring: 2,
            response: 'Att sova reglebundet och tillräckligt mycket ger ökat produktion av Melatonin, kroppens starkaste antioxidant. Detta antioxidant bidrar till bättre hälsa, stärker immunförsvaret och du lever längre. Att sova mellan 22-03 ger bästa nattvilan. Cirkadiska rytmen. Läs gärna mer om detta i vår blogg om Nattarbete.',
          }]
        },
        {
          name: 'Jag känner mig utvilad när jag vaknar',
          multiple: false,
          input: '',
          inputRequired: false,
          scaleGroup: 'Utvilad på morgonen',
          choices: [{
            name: 'Ofta',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende'
          }, {
            name: 'Ibland',
            input: '',
            inputRequired: false,
            scoring: 1,
            response: 'Är du fortfarande trött på morgoen kan det vara näringsbrist, framför allt magnesium, vitamin-D och K2, samt att du läcker energi pga inflammationer i kroppen som dränerar energinivåerna. Det kan ävenbero på att du har svårt att syresätta kroppen p.g.a. snarkningar.',
          }, {
            name: 'Sällan',
            input: '',
            inputRequired: false,
            scoring: 2,
            response: 'Att kunna känna sig utvilad efter nattsömn är mycket viktigt och bra sov vanor ger kroppen möjlighet att producera melatonin. Kroppen tål dessutom högre belastning och stress mycket bättre.',
          }]
        },
        {
          name: 'Jag sover vanligtvis hela natten utan avbrott',
          multiple: false,
          input: '',
          inputRequired: false,
          scaleGroup: 'Sova utan avbrott',
          choices: [{
            name: 'Ofta',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende'
          }, {
            name: 'Ibland',
            input: 'Vad tror du är orsaken till att du inte kan sova utan avbrott?',
            inputRequired: true,
            scoring: 1,
            response: 'Om du vaknar ofta under natten. Kör 4 regeln: 1) Inte äta innan sänggående. Minst 2 timmar emellan. 2) Inte dricka grönt te eller kaffe under kvällen, ibland inte ens under eftermiddagen. 3) inte sova på dagen för sent. Barn som sover på eftermiddagen påverkar endast nattsömnen om de vilar efter 16.00. 4) Inte stirra på en lysande skärm, TV, dator eller mobil innan sänggående. Stäng av alla modem och elektriska sovklockor. Dessa strömmar frekvenser som påvekar din kropp negativt och stör sömnen för vissa. Även småbarnsföräldrar tvingas vakna flera gånger under natten, vilket påverkar vårt välbefinnande. Är ni 2 så dela upp nätterna om detta är möjligt. ',
          }, {
            name: 'Sällan',
            input: 'Vad tror du är orsaken till att du inte kan sova utan avbrott?',
            inputRequired: true,
            scoring: 2,
            response: 'Att kunna känna sig utvilad efter nattsömn är mycket viktigt och bra sov vanor ger kroppen möjlighet att producera melatonin. Kroppen tål då dessutom högre belastning och stress myckey bättre.',
          }]
        },
        {
          name: 'Jag somnar vanligtvis inom 10 min när jag lägger mig',
          multiple: false,
          input: '',
          inputRequired: false,
          scaleGroup: 'Förmåga att somna',
          choices: [{
            name: 'Ofta',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende'
          }, {
            name: 'Ibland',
            input: '',
            inputRequired: false,
            scoring: 1,
            response: 'Om det tar lång tid att somna kan det bero på att kroppen är stressad, att dygnsrytmen har rubbats eller att melatoninproduktionen på annat sätt är störd. OM du har för vana att använda dator, mobil innan sänggående, se till att mobilen/datorn är inkopplad med blåljusfilter, (night vision). I annat fall kommer din kropp inte koppla på din melatoninproduktion som är din kropps absolut starkaste antioxidant, vilket reparerar din kropp när du sover.',
          }, {
            name: 'Sällan',
            input: '',
            inputRequired: false,
            scoring: 2,
            response: 'Du somnar lättare och skönare om du lägger dig på regelbundna tider, undviker starkt ljussken från datorer, mobiler och andra lysande föremål. En 15 minuters meditation med lugnade djupa andningsövningar i 5 minuter kommer att göra underverk. Även en stilla meditativ musik, lugnar ner din kropp så att då kan somna snabbare. Undvik att äta sent och drick varken kaffe eller grönt te, då dessa piggar upp dig. Samma sak med energidrycker och läsk.',
          }]
        },
      ]
    },
    {
      name: 'Träning',
      color: '#42d4f4',
      questions: [
        {
          name: 'Jag tränar/rör på mig minst 30 min, 3 ggr i veckan',
          multiple: false,
          input: '',
          inputRequired: false,
          scaleGroup: 'Tränar 3 ggr/vecka',
          choices: [{
            name: 'Ofta',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende'
          }, {
            name: 'Ibland',
            input: '',
            inputRequired: false,
            scoring: 1,
            response: 'Börja med att prioritera en stund några ggr i veckan då du tex tar en promenad eller utövar annan rörelse/motion som du uppskattar. Öka successivt till minst 30 min, 3 ggr i veckan.',
          }, {
            name: 'Sällan',
            input: '',
            inputRequired: false,
            scoring: 2,
            response: 'Rörelse är ett måste för en sund kropp, själ och hjärna samt att fördröja åldrandet. Syreupptagningsförmågan, leder, matsmältning och tankar blir bättre.',
          }]
        },
        {
          name: 'Jag får energi av att träna',
          multiple: false,
          input: '',
          inputRequired: false,
          scaleGroup: 'Energi av träning',
          choices: [{
            name: 'Ofta',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende'
          }, {
            name: 'Ibland',
            input: '',
            inputRequired: false,
            scoring: 1,
            response: 'Fundera på om det är rätt träningsform för dig. Prova annan typ av rörelse eller motion eller minska på ansträngningsgraden. Saknar du energi efter att ha tränat hårt även efter ett par timmars återhämtning, finns det risk för inflammation i kroppen',
          }, {
            name: 'Sällan',
            input: '',
            inputRequired: false,
            scoring: 2,
            response: 'Att ständigt vara i rörelse är en del av livet. Rörelse skapar energi och ger vitalitet i kroppen som både förbättrar hjärnans funktion och förlänger ditt liv.',
          }]
        },
        {
          name: 'Jag känner mig återhämtad efter ett intensivare träningspass efter 2 dagar (där du når 70% av din maxpuls)',
          multiple: false,
          input: '',
          inputRequired: false,
          scaleGroup: 'Återhämtning av träning',
          choices: [{
            name: 'Ofta',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende'
          }, {
            name: 'Ibland',
            input: '',
            inputRequired: false,
            scoring: 1,
            response: 'Bristande kondition måste motverkas med mer kondition i vardagen. Gå i trappan tag ej hiss. Gå en promenad utomhus varje dag i ur och skur minst 20 minuter.',
          }, {
            name: 'Sällan',
            input: '',
            inputRequired: false,
            scoring: 2,
            response: 'Att kunna återhämta sig snabbt indikerar på en vitalitet i kroppen som både förbättrar hjärnans funktion och förlänger ditt liv.',
          }]
        },
        {
          name: 'Jag känner mig mjuk och rörlig i kroppen',
          multiple: false,
          input: '',
          inputRequired: false,
          scaleGroup: 'Rörlighet i kroppen',
          choices: [{
            name: 'Ofta',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende'
          }, {
            name: 'Ibland',
            input: '',
            inputRequired: false,
            scoring: 1,
            response: 'Träna plank-tänj 10 minuter om dagen. Samt kroppsklappning. Yoga eller stretching.',
          }, {
            name: 'Sällan',
            input: '',
            inputRequired: false,
            scoring: 2,
            response: 'Genom dagligen genomför dessa övningar kan du undvika över 70 olika sjukdomar.',
          }]
        },
        {
          name: 'Jag får önskade resultat av min träning',
          multiple: false,
          input: 'Beskriv vilket resultat önskar du?',
          inputRequired: false,
          scaleGroup: 'Önskat resultat av träning',
          choices: [{
            name: 'Ofta',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende'
          }, {
            name: 'Ibland',
            input: '',
            inputRequired: false,
            scoring: 1,
            response: 'Öka självbilden du duger som du är, kostomläggning och andningsteknik. Se över träningsrutiner, kanske har man nått en platå och behöver öka intensitet eller ansträngning för att nå mer resultat. Blir man för trött efter träning och har svårt med återhämtning kan man istället behöva minska intensitet och ansträngning och ev. byta aktivitet.',
          }, {
            name: 'Sällan',
            input: '',
            inputRequired: false,
            scoring: 2,
            response: 'Uteblivet resultat av träning påverkar livskvalité och kan leda till att man tappar intresse och energi.',
          }]
        },
      ]
    },
    {
      name: 'Jobb/ekonomi',
      color: '#000075',
      questions: [
        {
          name: 'Jag känner mig tillfreds med min ekonomiska situation',
          multiple: false,
          input: '',
          inputRequired: false,
          scaleGroup: 'Bra ekonomi',
          choices: [{
            name: 'Ofta',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende'
          }, {
            name: 'Ibland',
            input: '',
            inputRequired: false,
            scoring: 1,
            response: 'Sök ekonomirådgivning/planeringshjälp',
          }, {
            name: 'Sällan',
            input: '',
            inputRequired: false,
            scoring: 2,
            response: 'Otillfredsställande ekonomi kan bidra till stress och försämrad livskvalité',
          }]
        },
        {
          name: 'Jag har människor att rådfråga kring ekonomiska frågor',
          multiple: false,
          input: '',
          inputRequired: false,
          scaleGroup: 'Rådgivning ekonomi',
          choices: [{
            name: 'Ofta',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende'
          }, {
            name: 'Ibland',
            input: '',
            inputRequired: false,
            scoring: 1,
            response: 'Skapa självtillit och byt arbete om situationen inte förbättras efter försök att kommunicera med arbetskamrater och chef.',
          }, {
            name: 'Sällan',
            input: '',
            inputRequired: false,
            scoring: 2,
            response: 'Vi spenderar många timmar på jobbet och det är viktigt inte bara för vår ekonomi men för vår hälsa och välbefinnande att känna att våra behov möts på arbetet.',
          }]
        },
        {
          name: 'Jag vet hur jag kan göra för att påverka min ekonomi',
          multiple: false,
          input: '',
          inputRequired: false,
          scaleGroup: 'Kan påverka min ekonomi',
          choices: [{
            name: 'Ofta',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende'
          }, {
            name: 'Ibland',
            input: '',
            inputRequired: false,
            scoring: 1,
            response: 'Begär nya arbetsuppgifter på jobbet eller byt arbete',
          }, {
            name: 'Sällan',
            input: '',
            inputRequired: false,
            scoring: 2,
            response: 'Att utvecklas i jobbet är en viktig del av tillfredställelse med arbete och därmed också allmänt välbefinnande. Känner man att man sitter fast i samma arbetsuppgifter och rutiner kan det leda till stress, en känsla av att sitta fast och inte komma vidare.',
          }]
        },
        {
          name: 'Jag utvecklas av mina arbetsuppgifter',
          multiple: false,
          input: '',
          inputRequired: false,
          scaleGroup: 'Utveckas i mitt arbete',
          choices: [{
            name: 'Ofta',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende'
          }, {
            name: 'Ibland',
            input: '',
            inputRequired: false,
            scoring: 1,
            response: 'Skapa självtillit och byt arbete',
          }, {
            name: 'Sällan',
            input: '',
            inputRequired: false,
            scoring: 2,
            response: 'Att känna sig uppskattad är en viktig faktor för att känna sig tillfreds och lycklig på jobbet. Vi vill känna att vi gör ett bra jobb och att vi får bekräftelse på det.',
          }]
        },
        {
          name: 'Jag har energi över efter en arbetsdag',
          multiple: false,
          input: '',
          inputRequired: false,
          scaleGroup: 'Energi efter arbetsdag',
          choices: [{
            name: 'Ofta',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende'
          }, {
            name: 'Ibland',
            input: '',
            inputRequired: false,
            scoring: 1,
            response: 'Vilken arbetsbelastning orkar du med. Se över om du har för mycket arbetsuppgifter och för mycket ansvar. Minimera stress. Öka träning på gym motsv. Med vikter och kondition',
          }, {
            name: 'Sällan',
            input: '',
            inputRequired: false,
            scoring: 2,
            response: 'Om man inte har energi över efter arbetsdagen får man svårt att orka med resten av livet, livskvalitén blir lidande. Det kan innebära att du arbetar mer än du egentligen har energi till och på sikt öka risken för utmattning. Det kan finnas en underliggande problematik med stress som lett till inflammation och obalanser i kroppen som behöver tas om hand. Har man "bara" dålig kondition behöver man prioritera att öka den för att därmed också öka sin livskvalité, att orka göra mer än bara jobba',
          }]
        }
      ]
    },
    {
      name: 'Familj/vänner',
      color: '#469990',
      questions: [
        {
          name: 'Jag känner mig sedd och bekräftad av min familj (t.ex partner, barn, syskon och föräldrar)',
          multiple: false,
          input: 'Beskriv av vilka du blir bekräftde av samt vilka du inte känner bekräftelse ifrån?',
          inputRequired: false,
          scaleGroup: 'Bekräftad av andra',
          choices: [{
            name: 'Ofta',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende'
          }, {
            name: 'Ibland',
            input: '',
            inputRequired: false,
            scoring: 1,
            response: 'Psykoterapi för att öka självbilden och våga be om det som du önskar på riktigt',
          }, {
            name: 'Sällan',
            input: '',
            inputRequired: false,
            scoring: 2,
            response: 'Ett av våra mest grundläggande behov är att känna att vår omgivning ser oss och bekräftar oss. Det ökar vår självkänsla och bidrar till välbefinnande och livskvalité',
          }]
        },
        {
          name: 'Jag trivs med min partner relation/relationsstatus/singel.',
          multiple: false,
          input: '',
          inputRequired: false,
          scaleGroup: 'Bra relation',
          choices: [{
            name: 'Ofta',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende'
          }, {
            name: 'Ibland',
            input: '',
            inputRequired: false,
            scoring: 1,
            response: 'Psykoterapi för att öka självbilden och våga be om det som du önskar på riktigt',
          }, {
            name: 'Sällan',
            input: '',
            inputRequired: false,
            scoring: 2,
            response: 'Ett otillfredsställande förhållande med partner kan leda till stress och nedstämdhet och sänker livskvalité och livsenergi.',
          }]
        },
        {
          name: 'Jag har nära vänner.',
          multiple: false,
          input: '',
          inputRequired: false,
          scaleGroup: 'Energi av vänner',
          choices: [{
            name: 'Ofta',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende'
          }, {
            name: 'Ibland',
            input: '',
            inputRequired: false,
            scoring: 1,
            response: 'Psykoterapi för att öka självbilden och våga stå upp för vad du tycker, egenvärde',
          }, {
            name: 'Sällan',
            input: '',
            inputRequired: false,
            scoring: 2,
            response: 'Vi är alla energivarelser och påverkas i olika grad av andras energier. Umgås man med andra människor som påverkar den egna energin negativt kan det leda till stress och nedstämdhet som sänker din livskvalité',
          }]
        },
        {
          name: 'Jag får energi av mina vänner',
          multiple: false,
          input: '',
          inputRequired: false,
          scaleGroup: 'Kommunikation med omgivning',
          choices: [{
            name: 'Ofta',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende'
          }, {
            name: 'Ibland',
            input: '',
            inputRequired: false,
            scoring: 1,
            response: 'Psykoterapi för att öka självbilden och våga stå upp för vad du tycker, egenvärde',
          }, {
            name: 'Sällan',
            input: '',
            inputRequired: false,
            scoring: 2,
            response: 'Dålig kommunikation med omgivningen kan leda till stress, frustration och nedstämdhet. Ett av våra grundläggande behov är att känna gemenskap, ge och få utbyte med andra människor.',
          }]
        },
        {
          name: 'Jag upplever att jag har en givande kommunikation med min omgivning',
          multiple: false,
          input: '',
          inputRequired: false,
          scaleGroup: 'Feedback helt OK',
          choices: [{
            name: 'Ofta',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende'
          }, {
            name: 'Ibland',
            input: '',
            inputRequired: false,
            scoring: 1,
            response: 'Psykoterapi för att öka självbilden och våga stå upp för vad du tycker, egenvärde',
          }, {
            name: 'Sällan',
            input: '',
            inputRequired: false,
            scoring: 2,
            response: 'En viktig del av personlig utveckling är att kunna ge och ta emot feedback. Vi får genom det en ökad förståelse för hur vi uppfattas av andra och hjälper andra på samma sätt. Det ger en känsla av att kontroll över sitt liv och möjlighet att öka sin livskvalité',
          }]
        },
        {
          name: 'Jag känner mig tillfreds att ge och ta emot feedback',
          multiple: false,
          input: 'Kommentera vilket: Bra på att ge men inte ta emot? Eller bra på att ta men inte ge? Eller bra på både och?',
          inputRequired: true,
          scaleGroup: '',
          choices: [{
            name: 'Ofta',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende'
          }, {
            name: 'Ibland',
            input: '',
            inputRequired: false,
            scoring: 1,
            response: '',
          }, {
            name: 'Sällan',
            input: '',
            inputRequired: false,
            scoring: 2,
            response: '',
          }]
        }
      ]
    },
    {
      name: 'Fysisk tillfredsställelse',
      color: '#808000',
      questions: [
        {
          name: 'Jag får enligt MIG den tillfredställande mängd av beröring i form av kramar och närhet.',
          multiple: false,
          input: '',
          inputRequired: false,
          scaleGroup: 'Jag får beröm',
          choices: [{
            name: 'Ofta',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende'
          }, {
            name: 'Ibland',
            input: '',
            inputRequired: false,
            scoring: 1,
            response: 'Är du bra på att ta emot beröm? Träna på detta. Ger du andra bekräftelse? Att börja ge utan krav på motprestation.',
          }, {
            name: 'Sällan',
            input: '',
            inputRequired: false,
            scoring: 2,
            response: 'Ett av våra mest grundläggande behov är att känna att vår omgivning ser oss och bekräftar oss. Det ökar vår självkänsla och bidrar till välbefinnande och livskvalité',
          }]
        },
        {
          name: 'Jag känner mig nöjd med mitt sexliv',
          multiple: false,
          input: '',
          inputRequired: false,
          scaleGroup: 'Nöjd med sexlivet',
          choices: [{
            name: 'Ofta',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende'
          }, {
            name: 'Ibland',
            input: '',
            inputRequired: false,
            scoring: 1,
            response: 'Kost och träning eller fysisk funktionsnedsättning, psykoterapi, partnerterapi.',
          }, {
            name: 'Sällan',
            input: '',
            inputRequired: false,
            scoring: 2,
            response: 'Sex är ett av våra mest grundläggande behov, enligt Maslows behovstrappa. Vid sex ökar nivåerna av dopamin och oxytocin i våra kroppar som ger oss känsla av njutning, tillfredställese och livsenergi. Har vi ett otillfredställande sexliv är det därmed viktigt att',
          }]
        },
        {
          name: 'Jag känner mig snygg naken',
          multiple: false,
          input: '',
          inputRequired: false,
          scaleGroup: 'Snygg naken',
          choices: [{
            name: 'Ofta',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende'
          }, {
            name: 'Ibland',
            input: '',
            inputRequired: false,
            scoring: 1,
            response: 'Psykoterapi för att öka självbilden, självacceptans. Kostomläggning och träning vid behov.',
          }, {
            name: 'Sällan',
            input: '',
            inputRequired: false,
            scoring: 2,
            response: 'Det är viktigt för välbefinnandet att känna sig bekväm i sin kropp, att vara tacksam och uppskatta den.',
          }]
        },
        {
          name: 'Jag vågar uttrycka mina fysiska behov',
          multiple: false,
          input: '',
          inputRequired: false,
          scaleGroup: 'Vågar uttrycka min behov',
          choices: [{
            name: 'Ofta',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende'
          }, {
            name: 'Ibland',
            input: '',
            inputRequired: false,
            scoring: 1,
            response: 'Psykoterapi för att öka självbilden och våga stå upp för vad du behöver.',
          }, {
            name: 'Sällan',
            input: '',
            inputRequired: false,
            scoring: 2,
            response: 'Det är viktigt för våra grundläggande behov att vi kan uttrycka våra fysiska behov. Tex beröring som närhet, kramar och sex ökar lyckohormonet oxytocin och dopamin som ger välbefinnande och livsenergi.',
          }]
        },
      ]
    },
    {
      name: 'Egentid/återhämtning',
      color: '#ffe119',
      questions: [
        {
          name: 'Jag bokar in tillfällen / aktiviteter som förstärker min livslust och fyller på med energi (massage, resor, skogsutflykt, konsert)',
          multiple: false,
          input: '',
          inputRequired: false,
          scaleGroup: 'Jag gör saker jag gillar',
          choices: [{
            name: 'Ofta',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende'
          }, {
            name: 'Ibland',
            input: '',
            inputRequired: false,
            scoring: 1,
            response: 'Prioritera och sätt undan tid varje vecka för att göra sådant som ger dig energi och glädje.',
          }, {
            name: 'Sällan',
            input: '',
            inputRequired: false,
            scoring: 2,
            response: 'För att vara hållbar i längden och kunna finnas där för våra nära och kära är det viktigt att vi tar hand om oss själva och fyller på vår energi med aktiviteter som ger oss glädje och livslust.',
          }]
        },
        {
          name: 'Jag upplever tacksamhet dagligen för det jag har i livet.',
          multiple: false,
          input: '',
          inputRequired: false,
          scaleGroup: 'Jag är tacksam',
          choices: [{
            name: 'Ofta',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende'
          }, {
            name: 'Ibland',
            input: '',
            inputRequired: false,
            scoring: 1,
            response: 'Tacksamhetsövningar - fundera en stund varje kväll och varje morgon på vad du är tacksam för. Skriv gärna ned och sätt upp där du kan påminnas om vad du är tacksam för.',
          }, {
            name: 'Sällan',
            input: '',
            inputRequired: false,
            scoring: 2,
            response: 'När vi känner tacksamhet ökar vår glädje, helt enkelt för att vi inser att vi har mer än vi tror, när vi flyttar fokus från vad vi inte har, till vad vi faktiskt har.',
          }]
        },
        {
          name: 'Jag utövar aktiv vila såsom meditation, mindfullness, skogspromenad dagligen',
          multiple: false,
          input: '',
          inputRequired: false,
          scaleGroup: 'Jag utövar aktiv vila',
          choices: [{
            name: 'Ofta',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende'
          }, {
            name: 'Ibland',
            input: '',
            inputRequired: false,
            scoring: 1,
            response: 'Prioritera och sätt undan tid varje dag för aktivitet som ger dig återhämtning, såsom skogspromenad, mindfullness eller meditation.',
          }, {
            name: 'Sällan',
            input: '',
            inputRequired: false,
            scoring: 2,
            response: 'Studier visar att aktiviteter som meditation har en regenererande effekt på hjärnan.',
          }]
        },
        {
          name: 'Jag vet vilken typ av återhämtning jag behöver för att skapa balans i mitt liv.',
          multiple: false,
          input: '',
          inputRequired: false,
          scaleGroup: 'Jag kan återhämta mig',
          choices: [{
            name: 'Ofta',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende'
          }, {
            name: 'Ibland',
            input: '',
            inputRequired: false,
            scoring: 1,
            response: 'Fundera på och prova på olika aktiviteter för att hitta det som är rätt för dig. Det kan vara aktiviteter som ger aktiv vila eller intensivare aktiviteter såsom att utöva olika sporter, beroende på dina behov.',
          }, {
            name: 'Sällan',
            input: '',
            inputRequired: false,
            scoring: 2,
            response: 'För att vara hållbar i längden och kunna finnas där för våra nära och kära är det viktigt att vi tar hand om oss själva och fyller på vår energi med aktiviteter som ger oss återhämtning, glädje och livlust.',
          }]
        },
        {
          name: 'Jag djupandas medvetet dagligen med magen.',
          multiple: false,
          input: '',
          inputRequired: false,
          scaleGroup: 'Jag kan djupandas dagligen',
          choices: [{
            name: 'Ofta',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende'
          }, {
            name: 'Ibland',
            input: '',
            inputRequired: false,
            scoring: 1,
            response: 'Djupandningsövningar morgon och kväll',
          }, {
            name: 'Sällan',
            input: '',
            inputRequired: false,
            scoring: 2,
            response: 'Det är vanligt att vi andas för ytligt, då får inte kroppen tillräckligt med syresättning och det påverkar bla kroppens Ph-balans och kroppens förmåga att få ut koldioxid ur blodet.',
          }]
        },
      ]
    },
    {
      name: 'Personlig utveckling/mental hälsa',
      color: '#4363d8',
      questions: [
        {
          name: 'Jag känner mig stressad eller orolig dagligen',
          multiple: false,
          input: '',
          inputRequired: false,
          scaleGroup: 'Stressad & orolig daglien',
          choices: [{
            name: 'Sällan',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende'
          }, {
            name: 'Ibland',
            input: '',
            inputRequired: false,
            scoring: 1,
            response: 'Psykoterapi för att öka självbilden, självacceptans och hjälp att prioritera och eliminera orsakerna till stress, oro, ångest. Se över näringsintag och öka intag av kost och/eller tillskott som magnesium, zink, D-vitamin, K2.',
          }, {
            name: 'Ofta',
            input: '',
            inputRequired: false,
            scoring: 2,
            response: 'När vi är stressade går kroppen in i kamp/flykt läge, då prioriteras tex matsmältning ned och vi får problem med matsmältning och näringsupptag. Samtidigt går det åt mer näring när vi stressar. Det är viktigt att minimera stress för att vara hållbar och vital i längden.',
          }]
        },
        {
          name: 'Jag litar vanligtvis på min magkänsla',
          multiple: false,
          input: '',
          inputRequired: false,
          scaleGroup: 'Litar på min magkänsla',
          choices: [{
            name: 'Ofta',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende'
          }, {
            name: 'Ibland',
            input: '',
            inputRequired: false,
            scoring: 1,
            response: 'Vi vill gärna tro att det är i hjärnan all vår kunskap sitter, men att lita på sin magkänsla kan hjälpa dig i situationer där din vishet inte räcker till.',
          }, {
            name: 'Sällan',
            input: '',
            inputRequired: false,
            scoring: 2,
            response: 'Vår magkänsla/intuition är ett fantastiskt verktyg vi har som hjälper guida oss rätt i alla situationer. Den är som en inbyggd radar med avancerad teknik och programmering.',
          }]
        },
        {
          name: 'Jag sätter personliga mål och strävar dagligen att nå dessa, (arbete, familj, personlig utveckling)',
          multiple: false,
          input: '',
          inputRequired: false,
          scaleGroup: 'Personliga mål som jag når',
          choices: [{
            name: 'Ofta',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende'
          }, {
            name: 'Ibland',
            input: '',
            inputRequired: false,
            scoring: 1,
            response: 'Börja med att fundera över upp till 3 mål som du vill göra om till nya vanor. Vad skulle du vilja göra annorlunda och vad kommer det att ge dig? Varför är denna handling viktig för dig? Hur ska du förbereda sig för att uppnå detta mål? Hur ofta? Hur effektivt? När ska du göra detta, under vilken period? Vem kommer du att behöva stöd av? Utvärdera sen din egen utveckling och fira dina framgångar. Gör detta upprepade gånger så blir det till en vana.',
          }, {
            name: 'Sällan',
            input: '',
            inputRequired: false,
            scoring: 2,
            response: 'Att sätta mål i livet är som att veta vart man vill gå i livet. Vi mår alla bra att veta varför vi går upp på morgonen, så det inte enbart beror på att vi inte dog. Att sätta mål ger oss en trygghet att veta vad jag vill, och det gör våra dagliga beslut enklare då besluten styr mot våra mål.',
          }]
        },
        {
          name: 'Jag fokuserar oftast på; Nutid, Framtid eller Dåtid?',
          multiple: false,
          input: '',
          inputRequired: false,
          scaleGroup: 'Fokuserar på då, nu eller framtid',
          choices: [{
            name: 'Nutid',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende'
          }, {
            name: 'Framtid',
            input: '',
            inputRequired: false,
            scoring: 1,
            response: 'Prova på tex mindfulness och meditation, tacksamhetsövningar.',
          }, {
            name: 'Dåtid',
            input: '',
            inputRequired: false,
            scoring: 2,
            response: 'Dåtid är förfluten tid som vi inte kan påverka, framtid är ännu inte skapad och vi bör därför inte uppehålla oss där mer än att drömma och fantisera. För det är enbart i nuet som vi kan påverka vår framtid. Det är i nuet som du gör dina val i livet, som leder till det liv du vill skapa.',
          }]
        },
        {
          name: 'Jag har negativa tankar',
          multiple: false,
          input: '',
          inputRequired: false,
          scaleGroup: 'Negativa tankar',
          choices: [{
            name: 'Sällan',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende'
          }, {
            name: 'Ibland',
            input: '',
            inputRequired: false,
            scoring: 1,
            response: 'En effektiv övning är att vid meditation, se dig själv i rummet/platsen du befinner dig, sen vidare till att se dig själv i huset eller platsen några meter ovanifrån, sen vidare till att se dig själv i staden, i landet, på kontinenten, från rymden. Ungefär som att zooma in och ut i Google maps i satellit mod. Att resa på egen hand är ett annat sätt att få perspektiv.',
          }, {
            name: 'Ofta',
            input: '',
            inputRequired: false,
            scoring: 2,
            response: 'Förmågan att ibland lyfta blicken och se sig själv ur ett globalt perspektiv, kan vara bra för att sina egna småsaker inte skall bli till dramatiska händelser. Allt i livet är relativt och vi behöver alla lyfta blicken för att se vårt liv i relation till allt annat som omger oss i världen. Detta ökar din medvetenhet, ger dig nytt perspektiv i livet och även möjlighet att se nya lösningar där du själv har fastnat.',
          }]
        },
      ]
    },
    {
      name: 'Obalanser',
      color: '#911eb4',
      questions: [
        {
          name: 'Jag blir dagligen sugen på specifik mat och dryck, t.ex: Kaffe, Choklad, Oliver etc.',
          multiple: false,
          input: 'OM du blir sugen, komplettera med vad du blir sugen på?',
          inputRequired: false,
          scaleGroup: 'Dagligt matbegär',
          choices: [{
            name: 'Sällan',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende'
          }, {
            name: 'Ibland',
            input: '',
            inputRequired: false,
            scoring: 1,
            response: 'Balansera din tarmflora genom att undvika socker och ersätta med nyttigare alternativ. Gör detta under minst en månads tid för att vänja dig av från beroendet. Suget kan också indikera näringsbrister, tex brist på krom vid sötsug, brist på magnesium vid sug efter choklad, brist på natrium vid sug efter chips.',
          }, {
            name: 'Ofta',
            input: '',
            inputRequired: false,
            scoring: 2,
            response: 'Ca 90 % av människans serotonin finns i mag-tarmkanalen där det reglerar tarmrörelser. Resten används i huvudsak som mättnadssignal i hjärnan och som tillväxtsignal i kroppen. Via Vagusnerven som går från tarm till hjärna, styr mikroberna i din tarmflora din hjärna att känna av hunger, törst men även cravings, sk. begär. Äter vi t.ex. mycket socker kommer mikrober i tarmen som gillar socker att öka mest och dominerar i sin tur styr din hjärnas sockerbegär. Det är viktigt att lyssna på kroppen, då den försöker tala om för oss vad som är i obalans på insidan. Kroppen försöker förmedla genom att göra dig sugen på viss mat och dryck vad den behöver för att nå balans under förutsättning att du inte lider av speciella ensidiga begär som socker, alkohol eller andra droger.',
          }]
        },
        {
          name: 'Jag dricker minst två koppar kaffe per dag alt. energidrycker, coca cola',
          multiple: false,
          input: '',
          inputRequired: false,
          scaleGroup: 'Koffeindrycker per dag',
          choices: [{
            name: 'Nej',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende'
          }, {
            name: 'Ja',
            input: 'Om du dricker varje dag, komplettera med hur många gång & mängd?',
            inputRequired: false,
            scoring: 1,
            response: 'Dra ned på kaffeintaget och försök att inte dricka kaffe varje dag. Njut desto mer de dagar du verkligen dricker kaffe. För att maximera näringsupptaget, undvik kaffe 1-2 timmar innan/efter intag av mat och tillskott.  All mat och dryck är nyttig i rätt mängd, men all mat och dryck kan även bli giftig i fel mängd. Att äta eller dricka samma sort föda, dryck varje dag, ger på sikt en obalans oavsett om det är kaffe eller apelsinjuice. Att variera ger även större smakupplevelse och njutning när man väl kan ta del av det man älskar. Det finns studier som visar att man får sämre näringsupptag när man dricker kaffe.',
          }]
        },
        {
          name: 'Jag dricker alkohol varje vecka: Om Ja! Följdfråga; Hur ofta och hur mycket.',
          multiple: false,
          input: '',
          inputRequired: false,
          scaleGroup: 'Alkoholintag',
          choices: [{
            name: 'Nej',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende'
          }, {
            name: 'Ja',
            input: 'Om du dricker varje vecka, komplettera med hur många gång & mängd?',
            inputRequired: false,
            scoring: 1,
            response: 'Få hjälp med beroende genom tex psykoterapi. Mindfullness kan vara en väg att gå, träning en annan. Att börja dansa och träffa människor en tredje eller någon annan hobby som får dig att fokusera på det som gör dig glad. Små mängder alkohol kan vara bra för kroppen, medan stora mängder kan förstöra en människas liv. Ett max två glas vin om dagen kan för många vara stärkande, medan allt över denna mängd oftast ger mer skada än nytta. Känner jag behov att dricka mer bör jag finna något som kan ersätta detta beteende.',
          }]
        },
        {
          name: 'Jag röker och eller snusar dagligen',
          multiple: false,
          input: '',
          inputRequired: false,
          scaleGroup: 'Röker / Snusar',
          choices: [{
            name: 'Nej',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende'
          }, {
            name: 'Ja',
            input: 'Om du röker/snusar varje dag, komplettera med hur många gång & mängd?',
            inputRequired: false,
            scoring: 1,
            response: 'Ta reda på ditt varför, som första steg till medveten förändring. Ditt varför är vad du vill uppnå och meningen med ditt liv. Du kan också få hjälp att bli kvitt beroende med hjälp av tex hypnosterapi, frekvensterapi eller psykoterapi. Lite elakt skulle man kunna säg att cigaretter är den vuxnes Napp. D.v.s. ett behov att sysselsätta sig när man är stressad, upprörd eller känner sig ensam eller illa till mods. D.v.s. det skapar en tillfällig trygghet i vardagen. Men om du vill värna om din hälsa bör du finna andra saker som ger dig samma trygghet. Men all förändring kräver mod, och mod får vi när vi har en inre övertygelse om varför?',
          }]
        },
        {
          name: 'Jag har amalgam i munnen',
          multiple: false,
          input: '',
          inputRequired: false,
          scaleGroup: 'Amalgam i tänderna',
          choices: [{
            name: 'Nej',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende'
          }, {
            name: 'Ja',
            input: 'Hur många tänder som är fyllda med amalgam?',
            inputRequired: true,
            scoring: 1,
            response: 'Tillhör du generationen som fick tandlagningar gjorda av Amalgan, försök att byta ut dessa mot porslin genom en sanering av dina gamla tandfyllningar. Det bör göras med försiktighet av en tandläkare med erfarenhet och rätt utrustning och som är väl insatt i riskerna. Amalgamet i tänderna innehåller tungmetaller bl.a. kvicksilver som fäller ut när vi äter framför allt sura eller syrlig mat och frukt. Dessa tungmetaller lagras ofta i kroppen och blir alltmer belastande med åren som går och i kontakt med fria radikaler bl.a. gör att vi åldras fortare och får sämre näringsupptag o.s.v.',
          }]
        },
        {
          name: 'Jag upplever att jag har svårt att minnas?',
          multiple: false,
          input: '',
          inputRequired: false,
          scaleGroup: 'Minnessvårighet',
          choices: [{
            name: 'Sällan',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende'
          }, {
            name: 'Ibland',
            input: '',
            inputRequired: false,
            scoring: 1,
            response: 'Minnesförlust som är kopplad till näringsbrist åtgärdar du genom att tillsätta kost eller tillskott med Omega 3, A-, D-, E, K-vitamin, B-complex inkl B12, magnesium, zink och vatten. Om minnesproblemen inte har med näringsbrister att göra, försök tänka på vad du har upplevt i livet och vad som kan vara din psykiska orsak. Även uttorking leder till minnesförlust. Så se till att dricka ordentligt, ca 1,5 liter vatten /dag.',
          }, {
            name: 'Ofta',
            input: '',
            inputRequired: false,
            scoring: 2,
            response: 'Minnesförlust (amnesi) är ovanlig glömska. Orsaken avgör om minnesförlust tänds långsamt eller plötsligt, och om det är tillfälligt eller permanent. Minnesförlust kan ha många olika orsaker; vanligaste är alkohol/droger, mediciner, B12 brist, infektioner, mineralbrist, uttorkning samt depression, och trauman som under ett långt liv av förnekelse framkallar minnesförlust p.g.a. att det du bär på, stressar kroppen som inte längre orkar med utan helt enkelt stänger av minnet. Vanlig orsak för demenssjukdomar som Alzheimers.',
          }]
        },
        {
          name: 'Jag har oregelbunden menstruation',
          multiple: false,
          input: 'Om du har mens, kommentar när hade du menstration senast? Eller hur ofta?',
          inputRequired: false,
          scaleGroup: 'Oregelbunden mens',
          choices: [{
            name: 'Sällan',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende'
          }, {
            name: 'Ibland',
            input: '',
            inputRequired: false,
            scoring: 1,
            response: 'Vid enstaka menstrations obalanser kan orsakenoftast vara stress, undervikt eller att du bantat för fort eller överdriven träning. Det endokrina systemet håller din kropp i balans, reglerar organfunktion och säkerställer god tillväxt och utveckling. Hormonstörande ämnen kan ändra, blockera, härma eller ändra våra naturliga hormoner. Effekten av att utsättas för hormonstörande ämnen kan visa sig senare i livet och kommer inte nödvändigtvis på en gång. Symptom som kan uppstå speciellt hos kvinnor som blivit utsatt för mycket hormonstörande ämnen kan vara; att menstruationen blir oregelbunden och brösten och körtlarna sväller upp. Det endokrina systemet är extra känsligt och vi behöver skydda systemet från gifter. ',
          }, {
            name: 'Ofta',
            input: '',
            inputRequired: false,
            scoring: 2,
            response: 'Även om det är omöjligt att helt undvika hormonstörande ämnen så finns det sätt att begränsa exponeringen för det: Välj ekologiska livsmedel. Skaffa en Vattenrenare som tar bort kemikalier och metaller (jag kan rekommendera http://www.purplesolution.e.... Undvik plast som innehåller BPA, konserver och konservmat. Ta bort alla matlådor gjorda av plast och drick inte vatten ur plastflaska. Värm inte upp plast i mikron och drick aldrig vatten ur en plastflaska som legat kvar i bilen och blivit uppvärmd i solen. Diska inte plastburkar i diskmaskinen. Tvätta händerna efter hantering av kassakvitton – många kvitton innehåller BPA och tungmetaller (bly). Som tas upp genom huden när vi tar i kvittona. Undvik ftalater - Dessa kemikalier finns i plaster och är vanligt förekommande i livsmedelsförpackningar och leksaker. De används i ”dofter”, som återfinns i allt från diskmedel och schampo till doftljus och luft fräschare/doftspridare. Använd naturlig hudvård, som du helst skall kunna äta. Tandläkaren har flera alternativ till plastlagningar och fråga om din tandläkare använder sig av ett material som heter Doxa, som är ett mycket bättre alternativ. Tänk på rotfyllningar, där förekommer starka kemiska medel. Dessutom kommer det samla sig bakterier som kan läcka ut i ditt system. Ät regnbågens samtliga färger så ofta du kan. (mycket grönsaker och bär). Det innehåller massor av antioxidanter som skyddar våra celler. NIEHS och National Toxikologi Program har tagit fram studier där det visar sig att ”vissa hormonliknande kemikalier kan påverka kroppsfunktioner redan i mycket låga doser”.',
          }]
        }
      ]
    },
    {
      name: 'Medicinsk status',
      color: '#bfef45',
      questions: [
        {
          name: 'Jag känner regelbundet smärta, obehag i kroppen.',
          multiple: false,
          input: 'Om du känner smärta/obehag, komplettera Var & Vad du tror är orsaken?',
          inputRequired: false,
          scaleGroup: 'Regelbunden smärta',
          choices: [{
            name: 'Sällan',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende'
          }, {
            name: 'Ibland',
            input: '',
            inputRequired: false,
            scoring: 1,
            response: 'Känner vi till orsaken och vi har en läkning som pågår då kan vi under tiden även ta något som bedövar den värsta smärtan. Emedan vi är oklara om orsak och läkprocessen, då bör vi undvika smärtlindrande och antiinflammatoriska läkemedel som i värtsa fall kan förvärra skadan, och istället fokusera på dess grundorsak. OBS: Symtomens placering är inte alltid det samma som grundproblemets orsak. T.ex. ryggvärk i nedre svanken, beror ofta på dålig matsmältning som får tarmen att svälla upp och trycka på nerverna längs ryggraden. Smärtan kan vara orsakad av näringsbrister, se till att du får i dig tillräckligt med Omega 3, Magnesium, D-vitamin, K-vitamin, B-complex inkl B12, Zink, Selen, C-vitamin via kost eller tillskott.',
          }, {
            name: 'Ofta',
            input: '',
            inputRequired: false,
            scoring: 2,
            response: 'Smärta i kroppen oavsett var de uppstår och oavsett vad du tror är orsaken, är kroppens sätt att signalera till dig att något är fel. Ofta reagerar kroppen med en inflammation för att lösa detta problem. Vår kropp har en otrolig förmåga att reparera sig själv i nästan alla lägen, om vi enbart stöder kroppens egna funktioner.',
          }]
        },
        {
          name: 'Jag har vaccinerat mig under de senaste tio åren',
          multiple: false,
          input: '',
          inputRequired: false,
          scaleGroup: '',
          choices: [{
            name: 'Nej',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende'
          }, {
            name: 'Ja',
            input: 'Vilka vacciner har du tagit och ungefär när?',
            inputRequired: false,
            scoring: 1,
            response: 'Gör medvetna val gällande vacciner. Om du väljer att ta vacciner, gör så minst 1 vecka innan du reser utomlands eller utsätter kroppen för andra patogener som då kan överblasta kroppens immunförsvar, med risk för kraftfulla biverkningar som följd. Vacciner innehåller både den sjukdom som de är tänkt att skydda emot, samt ett antal andra ämnen som bl.a. konserveringsmedel etc. Alla dessa ämnen utgör en belastning på kroppens immunförsvar för att kroppen skall kunna skapa antikroppar mot dessa patogener (virus, bakterier, svamp, parasiter) som vaccinet avser. Beroende på vilket konserveringsmedel eller andra tillsatser som vaccinet innehåller kan dessa skapa olika typer av reaktioner och biverkningar i sig. Men det som framför allt är viktigt att känna till är att vaccinets verksamma beståndsdelar ger ökad belastning på kroppens immunförsvar 3:e samt 7:e dagen efter vaccinering. Detta innbär att dag 3 och 7 efter vaccinering är kritiska för om kroppen skall orka med denna ökade belastningen eller drabbas av biverkningar eller till och med utveckla sjukdomen som vaccinet avser att skydda emot, vilket händer hos vissa känsliga grupper.',
          }]
        },
        {
          name: 'Jag äter medicin och eller kosstillskott ()',
          multiple: false,
          input: '',
          inputRequired: false,
          scaleGroup: 'Mediciner / kosttillskott',
          choices: [{
            name: 'Nej',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende'
          }, {
            name: 'Ja',
            input: 'Kompletter med vilka märken och vilken dosering. Skriv de mediciner och tillskott som du tar',
            inputRequired: true,
            scoring: 1,
            response: 'Låt mat var din medicin, och medicin vara din mat. Dvs försök att i första hand att ge kroppen det den behöver genom din kost. Tillskott kan vara nödvändiga under en period och i vissa fall nödvändiga även ätas regelbundet när jorden som grödorna växer i är näringsfattig eller att du t.ex. genomgått en Bypass operation. De flesta mediciner och tillskott har sitt ursprung från naturens egna kemiska ämnen såsom växter, örter och rötter. När vi äter det naturen ger oss i sin naturliga form får vi i oss alla synergier, som behövs för att samverka i våra kroppar och ge oss det vi behöver för kroppens olika processer och behov för att nå balans. Detta fungerar mest optimalt när vi äter lokala vilda eller ekologiskt odlade råvaror. ',
          }]
        },
        {
          name: 'Jag har ärftliga sjukdomar i släkten (Cancer, Diabetes, Hjärt- & kärl, Demens, Parkinson)',
          multiple: false,
          input: '',
          inputRequired: false,
          scaleGroup: 'Ärftliga sjukdomar',
          choices: [{
            name: 'Nej',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende'
          }, {
            name: 'Ja',
            input: 'Komplettera med vilka du känner till?',
            inputRequired: true,
            scoring: 1,
            response: 'Förebygg och lindra, där det är möjligt, genetiska sjukdomar genom att äta en kost och hålla en livsstil som stödjer kroppen att nå balans. Studier visar att vi kan påverka våra geners uttryck till 80% genom den kost och livsstil vi väljer.',
          }]
        },
        {
          name: 'Detta stämmer in på min hy: normal, torr, fet, acne, eksem/psoariasis',
          multiple: true,
          input: '',
          inputRequired: false,
          scaleGroup: 'Min huds utseende',
          choices: [{
            name: 'Normal',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende'
          }, {
            name: 'Fet',
            input: '',
            inputRequired: false,
            scoring: 1,
            response: 'Ersätt gluten, komjölkprodukter och socker med hälsosamma alternativ, såsom bröd och pasta gjort på naturligt glutenfria mjölsorter, växtbaserad mjölk såsom mandel-, havre- , Dinkelmjölk, och mycket grönsaker i alla dess färger. Tillsätt nyttiga fetter såsom kokosfett, avokado, olivolja, sesamolja. Stress kan också vara en bidragande orsak och är viktigt att minimera.',
          }, {
            name: 'Torr',
            input: '',
            inputRequired: false,
            scoring: 2,
            response: 'Huden är vårt största utsöndringsorgan och visar oss att vi har obalanser på insidan genom tex torr hy, fet hy, acne, eksem, psoriasis m.fl. Ger vi kroppen de näringsämnen den behöver har den bättre förutsättning att nå balans både på insidan och utsidan.',
          }, {
            name: 'Acne',
            input: '',
            inputRequired: false,
            scoring: 3,
            response: 'Acne - lever, slagg, hormoner, stress, toxiner. Acne kan uppstå p.g.a. olika saker: Oftast nedsatt matsmältning med Galla problematik. Det kan även vara inflammation magen/tarm. Utsöndring av gifter via huden kan ge upphov till inflammationer. t.ex. eksem, acne, rosacea genom att huden belastas alltför mycket. Leverproblem eller näringsbrister. Lösningen kan vara: Tillsätt B5, Zink, Vitamin-C, Omega 3, Gurkmeja och Ingefära. Grunda med rätt näring beroende på grundproblem. Undvik inflammationsbildande kost. Acne tar tid så ha tålamod. Läs mer om inflammationsbildande kost se www.life-check.se/blogg',
          }, {
            name: 'Eksem',
            input: '',
            inputRequired: false,
            scoring: 4,
            response: 'Eksem, bla. brist på fettsyror samt obalans i tarm, även levertecken då den visar på obalanser genom huden. Oftast nedsatt matsmältning med Galla problematik. Det kan även vara inflammation magen/tarm. Utsöndring av gifter via huden kan ge upphov till inflammationer. t.ex. eksem, acne, rosacea genom att huden belastas alltför mycket. Leverproblem eller näringsbrister. Tillsätt B5, Zink, Vitamin-C, Omega 3, Gurkmeja och Ingefära. Grunda med rätt näring beroende på grundproblem. Undvik inflammationsbildande kost.',
          }]
        },
        {
          name: 'Detta stämmer in på min mage: Utan besvär. Jag känner mig gasig i mage. Jag blir uppblåst diekt efter att ha ätit, uppblåst efter någon timme. Jag är ofta hård i magen, Jag får ofta diarré.',
          multiple: true,
          input: '',
          inputRequired: false,
          scaleGroup: 'Dålig mage',
          choices: [{
            name: 'Optimalt - Utan besvär',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende'
          }, {
            name: 'Jag känner mig gasig i magen',
            input: '',
            inputRequired: false,
            scoring: 1,
            response: 'Ersätt gluten, komjölkprodukter och socker med hälsosamma alternativ, såsom bröd och pasta gjort på naturligt glutenfria mjölsorter, växtbaserad mjölk såsom mandel-, havre- eller cashewmjölk, och mycket grönsaker i alla dess färger. Ät mycket råa grönsaker för att få i dig viktiga enzymer kroppen behöver för att bryta ned maten. Enzymtillskott kan tillsättas vid behov för att få snabb effekt. Tillsätt nyttiga fetter såsom kokosfett, avokado, olivolja, sesamolja. Stress kan också vara en bidragande orsak och är viktigt att minimera.  OM du tycker at det känns för tungt att sluta med allt gluten, kan du prova att byta ut allt brd du äter mot äkta surdegsjästa bröd. De den långa fermenteringstiden (jästiden) på ett äkta surdegsbröd har jäst minst 12 timmar upp emot48 timmar. Gör att det mesta av glutinet har brytits ner. Väljer du dessutom surdeg som bakats på enbart Dinkelvete, korn, råg eller havre, så kommer du garanterat må betydligt bättre. Vanligt vete bör du undvika så långt det är möjligt. Även pasta på dinkel och pizzabröd på dinkel är att föredra. Vill du veta mer om detta kolla in på vår bloggsite och läs om surdegsbröd och modernt vete som har förädlats 50 000 gång sedan 1954. www.evergreenhealth.se',
          }, {
            name: 'Jag blir uppblåst i magen efter en måltid',
            input: '',
            inputRequired: false,
            scoring: 2,
            response: 'Kroppen visar tydligt att den har svårt att smälta maten. Det leder inte bara till symtomen beskrivna utan även till att kroppen får svårt att ta upp näringen och därmed inte förutsättningar att fungera optimalt.',
          }, {
            name: 'Jag blir uppblåst i magen efter en timme',
            input: '',
            inputRequired: false,
            scoring: 3,
            response: 'Hård mage eller förstoppning kan ha många orsaker, men oftast beror det på att du inte går på toaletten tillräckligt ofta, eller att du dricker för lite vätska. OBS: Kaffe, Cola och alkohol torkar ut kroppen och ger inte den vätskemängd som kroppen behöver. Även stillasittande arbete och fritid gör att tarmen inte masseras av dina kroppsrörelser, vilket ökar risken för förstoppning. Lösning är regelbunden rörelse, (promenader, gå i trappor istället för att ta hissen etc) Samt dricka minst 2 liter vatten per dag samt gå på toaletten så fort du behöver, samt sitt kvar en tid så din kropp hinner reagera och få ut din avföring. ',
          }, {
            name: 'Jag blir oftast hård i magen',
            input: '',
            inputRequired: false,
            scoring: 4,
            response: 'Som regel är akut diarré ett symtom på en gastrointestinal infektion, d v s att man fått i sig, bakterier, virus eller parasiter som hämmar tarmens förmåga att ta upp vätska. Därmed blir vätskan kvar i tarmen och löser upp avföringen – man har fått diarré. Dessa bakterier, virus eller parasiter kan vi råka ut för överallt. Men risken är extra stor när vi är utomlands och möter ”främmande” bakteriemiljöer i både dricksvatten och mat. Andra orsaker till diarré kan vara för hög konsumtion av alkohol eller kaffe, födoämnesallergier, stress och ångest eller som en bieffekt av medicinering. Har du ofta diarré tyder på att din tarmflora är i obalans eller saknar förmåga att ta hand om de födoämnen som du äter. Antingen det beror på intolerans mot t.ex. gluten, laktos eller andra livsmedelsprodukter. Rådgör gärna med oss för att finna grundorsaken till dessa symtom. OBS: Vid diarré, drick mycket vatten, så du inte torkar ut. Svensk råa eller frysta blåbär hjälper till att minska problemet liksom kryddnejlika som du maler och dricker som te.',
          }, {
            name: 'Jag får ofta diarré',
            input: '',
            inputRequired: false,
            scoring: 5,
            response: '',
          }]
        },
        {
          name: 'Jag har allergi?',
          multiple: false,
          input: '',
          inputRequired: false,
          scaleGroup: 'Allergier',
          choices: [{
            name: 'Nej',
            input: '',
            inputRequired: false,
            scoring: 0,
            response: 'Vi finner inga negativa avvikelser i detta avseende'
          }, {
            name: 'Ja',
            input: 'Komplettera med vilka du vet att du har?',
            inputRequired: true,
            scoring: 1,
            response: 'Allergier kan bero på olika slags faktorer, arv eller miljö eller en kombination av de båda. Miljömässiga faktorer kan vara föroreningar, epidemier och kost. Människor som har stor benägenhet att utveckla en allergi har en så kallad atopi. Mastceller - bekämpar parasiter, speciellt maskar. Mastceller binder till sin yta antikroppar som i sin tur aktiverar utsöndring av histamin. Mastceller är även den huvudsakliga orsaken till allergier. Histamin är ett hormon som är verksamt vid allergier. Den sätter igång den allergiska reaktionen om man är överkänslig mot något. Allergi är ett sätt för kroppen att säga att nu är det något som är för mycket och att immunförsvaret är för överbelastat. Vid autonoma sjukdomar så som reumatism angriper vårt immunförsvar sig själv, i tron som att de vore fiender. Även fast det är vanligt att ärva atopiska egenskaper, utvecklar inte alla allergi. Ibland kan en allergi utvecklas på grund av att systemet är fullt av gifter och inte orkar mer. Den stöter då ifrån sig ämnet. Matallergier och intoleranser är vanligt här. Dock är inte celiaki (glutenintolerans) en grundorsak utan det kan bero på att kroppen har blivit utsatt för tungmetaller. Som i sin tur skapat inflammationer så kroppens immunförsvar inte orkar ta hand om allt, det är ständigt triggat. Då kan det vara bra att ta tillskott av något som kallad L-glutatin, som dels är ett mycket effektivt antioxidant vilket motverkar de fria radikalerna som bl.a. tungmetaller utgör. Samt hjälper kroppen att transportera ut tungmetaller som koppar, kvicksilver etc. ut ur kroppen. ',
          }]
        },
      ]
    }
  ]
};
