import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { ADMIN_ENDPOINTS } from 'utils/api';

const FETCHING = 'ADMIN/PATIENTS/FETCHING';
const FETCHED_ALL = 'ADMIN/PATIENTS/FETCHED_ALL';
const FETCHED_SINGLE = 'ADMIN/PATIENTS/FETCHED_SINGLE';
const CREATE_PATIENT = 'ADMIN/PATIENTS/CREATE_PATIENT';
const UPDATE_PATIENT = 'ADMIN/PATIENTS/UPDATE_PATIENT';
const CREATE_COMMENT = 'ADMIN/PATIENTS/CREATE_COMMENT';
const UPDATE_COMMENT = 'ADMIN/PATIENTS/UPDATE_COMMENT';
const DELETE_COMMENT = 'ADMIN/PATIENTS/DELETE_COMMENT';
const CREATE_STATEMENT = 'ADMIN/PATIENTS/CREATE_STATEMENT';
const CREATE_MESSAGE = 'ADMIN/PATIENTS/CREATE_MESSAGE';
const CREATE_ATTACHMENT = 'ADMIN/PATIENTS/CREATE_ATTACHMENT';
const DELETE_TEST_RESULT = 'ADMIN/PATIENTS/DELETE_TEST_RESULT';
const CLEAR_CURRENT = 'ADMIN/PATIENTS/CLEAR_CURRENT';

const initialState = {
  fetching: false,
  fetched: false,
  data: [],
  current: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCHING:
      return {
        ...state,
        fetching: true
      };
    case FETCHED_ALL:
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: action.payload
      };
    case FETCHED_SINGLE:
      return {
        ...state,
        fetching: false,
        current: action.payload,
      };
    case CLEAR_CURRENT:
      return {
        ...state,
        current: {}
      };
    case CREATE_PATIENT:
    case UPDATE_PATIENT:
    case CREATE_STATEMENT:
    case CREATE_COMMENT:
    case CREATE_MESSAGE:
    case CREATE_ATTACHMENT:
    case DELETE_TEST_RESULT:
    case UPDATE_COMMENT:
    case DELETE_COMMENT:
    default:
      return state;
  }
};

export const fetchAll = (showLoader = true) => (dispatch) => {
  if (showLoader) dispatch({ type: FETCHING });

  return axios.get(`${ADMIN_ENDPOINTS.PATIENTS}`)
    .then((res) => dispatch({ type: FETCHED_ALL, payload: res.data }));
};

export const fetchSingle = (id, showLoader = true) => (dispatch) => {
  if (showLoader) dispatch({ type: FETCHING });

  return axios.get(`${ADMIN_ENDPOINTS.PATIENTS}/${id}`)
    .then((res) => dispatch({ type: FETCHED_SINGLE, payload: res.data }));
};

export const createPatient = (newPatient) => (dispatch) =>
  axios.post(`${ADMIN_ENDPOINTS.PATIENTS}`, newPatient)
    .then(() => {
      dispatch({ type: CREATE_PATIENT });
      dispatch(fetchAll(false));
    });

export const updatePatient = (patientId, updatedPatient) => (dispatch) =>
  axios.put(`${ADMIN_ENDPOINTS.PATIENTS}/${patientId}`, updatedPatient)
    .then(() => {
      dispatch({ type: UPDATE_PATIENT });
      dispatch(fetchSingle(patientId));
    });

export const clearCurrent = () => (dispatch) => dispatch({ type: CLEAR_CURRENT });

export const createComment = (patientId, newComment) => (dispatch) =>
  axios.post(`${ADMIN_ENDPOINTS.PATIENTS}/${patientId}/comments`, newComment).then(() => {
    dispatch({ type: CREATE_COMMENT });
    dispatch(fetchSingle(patientId, false));
  });

export const updateComment = (patientId, commentId, updatedComment) => (dispatch) =>
  axios.put(`${ADMIN_ENDPOINTS.PATIENTS}/${patientId}/comments/${commentId}`, updatedComment).then(() => {
    dispatch({ type: UPDATE_COMMENT });
    dispatch(fetchSingle(patientId, false));
  });

export const deleteComment = (patientId, commentId) => (dispatch) =>
  axios.delete(`${ADMIN_ENDPOINTS.PATIENTS}/${patientId}/comments/${commentId}`).then(() => {
    dispatch({ type: DELETE_COMMENT });
    dispatch(fetchSingle(patientId, false));
  });

export const createStatement = (patientId, newStatement) => (dispatch) =>
  axios.post(`${ADMIN_ENDPOINTS.PATIENTS}/${patientId}/statements`, newStatement).then(() => {
    dispatch({ type: CREATE_STATEMENT });
    dispatch(fetchSingle(patientId, false));
  });

export const createMessage = (patientId, newMessage) => (dispatch) =>
  axios.post(`${ADMIN_ENDPOINTS.PATIENTS}/${patientId}/messages`, newMessage).then(() => {
    dispatch({ type: CREATE_MESSAGE });
    dispatch(fetchSingle(patientId, false));
  });

export const createAttachment = (id, newAttachment) => (dispatch) =>
  axios.post(`${ADMIN_ENDPOINTS.PATIENTS}/${id}/attachment`, newAttachment,{
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
    .then(() => {
      dispatch({ type: CREATE_ATTACHMENT });
      dispatch(fetchSingle(id, false));
    });

export const deleteTestResult = (id, patientId) => (dispatch) =>
  axios.delete(`${ADMIN_ENDPOINTS.TESTS}/${id}`).then(() => {
    dispatch({ type: DELETE_TEST_RESULT });
    dispatch(fetchSingle(patientId, false));
  });

export const usePatientState = () => ({
  fetching: useSelector(({ admin: { patients: { fetching } } }) => fetching),
  fetched: useSelector(({ admin: { patients: { fetched } } }) => fetched),
  patients: useSelector(({ admin: { patients: { data } } }) => data),
  current: useSelector(({ admin: { patients: { current } } }) => current),
});

export const usePatientDispatch = () => {
  const dispatch = useDispatch();
  return {
    fetchSingle: (id) => dispatch(fetchSingle(id)),
    fetchAll: () => dispatch(fetchAll()),
    createPatient: (newPatient) => dispatch(createPatient(newPatient)),
    updatePatient: (patientId, updatedPatient) => dispatch(updatePatient(patientId, updatedPatient)),
    clearCurrent: () => dispatch(clearCurrent()),
    createComment: (patientId, newComment) => dispatch(createComment(patientId, newComment)),
    updateComment: (patientId, commentId, updatedComment) => dispatch(updateComment(patientId, commentId, updatedComment)),
    deleteComment: (patientId, statementId) => dispatch(deleteComment(patientId, statementId)),
    createStatement: (patientId, newStatement) => dispatch(createStatement(patientId, newStatement)),
    createMessage: (patientId, newMessage) => dispatch(createMessage(patientId, newMessage)),
    deleteTestResult: (id, patientId) => dispatch(deleteTestResult(id, patientId)),
    createAttachment: (id, newAttachment) => dispatch(createAttachment(id, newAttachment))
  };
};
