import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, makeStyles
} from '@material-ui/core';
import {
  ArrowForward as ArrowForwardIcon,
  Clear as CrossIcon,
  Done as DoneIcon,
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  crossIcon: {
    fill: theme.palette.error.main,
  },
  doneIcon: {
    fill: theme.palette.success.main,
  }
}));

const HealthSurveys = ({ surveys, role }) => {
  const classes = useStyles();
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Hälsoenkät-ID</TableCell>
          <TableCell>Besvarad</TableCell>
          <TableCell/>
        </TableRow>
      </TableHead>
      <TableBody>
        {surveys.map((survey, i) => (
          <TableRow key={survey.id}>
            <TableCell component="th" scope="row">
              Hälsoenkät #{i + 1}
            </TableCell>
            <TableCell component="th" scope="row">
              {survey.answered
                ? <DoneIcon className={classes.doneIcon}/>
                : <CrossIcon className={classes.crossIcon}/>
              }
            </TableCell>
            <TableCell>
              {survey.answered &&
                <Tooltip title="Öppna hälsoenkät">
                  <IconButton
                    component={Link}
                    to={`/${role}/surveys/${survey.id}`}
                  >
                    <ArrowForwardIcon color="primary"/>
                  </IconButton>
                </Tooltip>
              }
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

HealthSurveys.propTypes = {
  surveys: PropTypes.array.isRequired,
  role: PropTypes.string.isRequired,
};

export default HealthSurveys;
