import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  LinearProgress, makeStyles, Typography, IconButton, Tooltip
} from '@material-ui/core';

import { AssignmentTurnedIn as SignIcon } from '@material-ui/icons';

import { useTestDispatch as useTestDispatchAdmin, useTestState as useTestStateAdmin } from 'modules/admin/tests';
import { useTestDispatch as useTestDispatchDoctor, useTestState as useTestStateDoctor } from 'modules/doctor/tests';

const useStyles = makeStyles(() => ({
  title: {
    marginBottom: 20,
    marginLeft: 10
  },
  signContainer: {
    marginBottom: 20,
    marginLeft: 10,
    marginRight: 10,
    borderBottom: '1px solid black'
  }
}));
const Show = () => {
  const { role, id } = useParams();
  const classes = useStyles();

  const useTestDispatch = role === 'admin' ? useTestDispatchAdmin : useTestDispatchDoctor;
  const useTestState = role === 'admin' ? useTestStateAdmin : useTestStateDoctor;

  const { fetchSingle, signTestResult, clearCurrent } = useTestDispatch();
  const { fetching, current } = useTestState();

  useEffect(() => {
    fetchSingle(parseInt(id, 10));
    return () => clearCurrent();
  }, []);

  return (
    <div className="elevated-container">
      {fetching
        ? <LinearProgress/>
        : Object.entries(current).length === 0
          ? 'Hittade inget test.'
          :
          <React.Fragment>
            <Typography variant="h2" className={classes.title}>
              Provresultat för <Link to={`/${role}/patients/${current.patient.id}/tests`}>{current.patient.name}</Link> ({current.patient.personalNumber}) {current.case !== null && <Link to={`/${role}/cases/${current.case.id}/tests`}>ärende (#{current.case.id})</Link>}
            </Typography>
            {!current.signed &&
              <div className={classes.signContainer}>
                <Typography variant="body1">
                  Efter du har signerat resultatet nedan, klicka knappen nedan för att göra provresultatet synligt för klienten. Det är viktigt att du försäkrar dig om att resultatet nedan är signerat innan du klickar här eftersom klienten annars kan ändra i provresultatet.
                </Typography>
                <Tooltip title="Signera resultat">
                  <IconButton color="primary" onClick={() => signTestResult(current.id)}>
                    <SignIcon/>
                  </IconButton>
                </Tooltip>
              </div>
            }
            {current.attachmentFile !== null ?
              <object
                data={current.attachmentFile.url}
                width="100%"
                style={{ height: '85vh' }}
              />
              : current.labPortalenUrl !== '' &&
                <iframe
                  src={current.labPortalenUrl}
                  width="100%"
                  frameBorder={0}
                  style={{ minHeight: '85vh' }}
                  onLoad={() => {
                    /* document.getElementById('btnSign').addEventListener('click',function(){
                      console.log('editingClick')
                    }); */
                  }}
                />
            }
          </React.Fragment>
      }
    </div>
  );
};

export default Show;
