import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles, Button
} from '@material-ui/core';
import useToggle from 'hooks/useToggle';

import HealthSurveyAnswers from 'components/HealthSurveyAnswers';
import CreateHealthSurveyDialog from './CreateHealthSurveyDialog';

const useStyles = makeStyles(() => ({
  button: {
    marginTop: 10,
  },
}));

const HealthSurvey = ({ survey, role, currentCase }) => {
  const classes = useStyles();
  const [showCreateHealthSurvey, toggleCreateHealthSurvey] = useToggle();
  return (
    <React.Fragment>
      {!survey.answered ?
        <React.Fragment>
          <div>Hälsoenkäten har inte blivit besvarad ännu.</div>
          { role === 'admin' &&
            <React.Fragment>
              <Button
                className={classes.button}
                onClick={toggleCreateHealthSurvey}
                variant="contained"
              >
                Skapa hälsoenkätsvar
              </Button>
              <CreateHealthSurveyDialog
                currentCase={currentCase}
                healthSurveyId={survey.id}
                toggleOpen={toggleCreateHealthSurvey}
                open={showCreateHealthSurvey}
              />
            </React.Fragment>
          }
        </React.Fragment>
        :
        <HealthSurveyAnswers gender={currentCase.patient.gender} dbSurvey={survey} role={role} />
      }
    </React.Fragment>

  );
};

HealthSurvey.propTypes = {
  survey: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  currentCase: PropTypes.object.isRequired,
};

export default HealthSurvey;
