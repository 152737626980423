import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Table, TableBody, TableCell, TableHead, TableRow
} from '@material-ui/core';

const TestResults = ({ testResults }) => (testResults.length === 0
  ? 'Hittade inga provresultat'
  :
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Provtyp</TableCell>
          <TableCell>Titel</TableCell>
          <TableCell>Datum</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {testResults.map((result) => (
          <TableRow key={result.id} component={Link} to={`/patient/tests/${result.id}`}>
            <TableCell component="th" scope="row">
              {result.type}
            </TableCell>
            <TableCell component="th" scope="row">
              {result.title ? result.title : ''}
            </TableCell>
            <TableCell component="th" scope="row">
              {result.createdAt.split('T')[0]}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
);

TestResults.propTypes = {
  testResults: PropTypes.array.isRequired,
};

export default TestResults;
