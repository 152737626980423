import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AuthRoute from 'components/AuthRoute';

import Layout from 'layout';

import Home from 'views/Home';
import PatientLogin from 'views/PatientLogin';
import DevLogin from 'views/DevLogin';
import DoctorLogin from 'views/DoctorLogin';
import AdminLogin from 'views/AdminLogin';
import Notifications from 'views/Notifications';

import Laboratories from 'views/Laboratories';
import Referrals from 'views/Referrals';
import HealthPackages from 'views/HealthPackages';
import HealthSurvey from 'views/HealthSurvey';

import PatientsShow from 'views/patients/Show';
import PatientsIndex from 'views/patients/Index';

import AdminsShow from 'views/admins/Show';
import AdminsIndex from 'views/admins/Index';

import DoctorsShow from 'views/doctors/Show';
import DoctorsIndex from 'views/doctors/Index';

import CasesShow from 'views/cases/Show';

import TestsShow from 'views/tests/Show';

// Patient views
import Messages from 'views/patient/Messages';
import Statements from 'views/patient/Statements';
import Settings from 'views/patient/Settings';
import PatientSurveysIndex from 'views/patient/surveys/Index';
import PatientSurveysShow from 'views/patient/surveys/Show';
import PatientCasesIndex from 'views/patient/cases/Index';
import PatientCasesShow from 'views/patient/cases/Show';
import PatientTestsIndex from 'views/patient/tests/Index';
import PatientTestsShow from 'views/patient/tests/Show';
import Attachments from 'views/patient/Attachments';


const Routes = () => (
  <Switch>
    <Layout>
      <Route exact path="/login" component={PatientLogin} />
      <Route exact path="/dev/login" component={DevLogin} />
      <Route exact path="/doctor/login" component={DoctorLogin} />
      <Route exact path="/admin/login" component={AdminLogin} />
      <Route exact path="/" component={Home} />

      <AuthRoute exact path="/:role(patient)/statements" component={Statements} />
      <AuthRoute exact path="/:role(patient)/messages" component={Messages} />
      <AuthRoute exact path="/:role(patient)/settings" component={Settings} />
      <AuthRoute exact path="/:role(patient)/cases" component={PatientCasesIndex} />
      <AuthRoute exact path="/:role(patient)/cases/:id/:view?" component={PatientCasesShow} />
      <AuthRoute exact path="/:role(patient)/tests" component={PatientTestsIndex} />
      <AuthRoute exact path="/:role(patient)/tests/:id" component={PatientTestsShow} />
      <AuthRoute exact path="/:role(patient)/surveys" component={PatientSurveysIndex} />
      <AuthRoute exact path="/:role(patient)/surveys/:id" component={PatientSurveysShow} />
      <AuthRoute exact path="/:role(patient)/attachments" component={Attachments} />

      <AuthRoute exact path="/:role(admin)/admins" component={AdminsIndex} />
      <AuthRoute exact path="/:role(admin)/admins/:id/:view?" component={AdminsShow} />

      <AuthRoute exact path="/:role(admin)/doctors" component={DoctorsIndex} />
      <AuthRoute exact path="/:role(admin)/doctors/:id/:view?" component={DoctorsShow} />

      <AuthRoute exact path="/:role(admin|doctor)/patients" component={PatientsIndex} />
      <AuthRoute exact path="/:role(admin|doctor)/patients/:id/:view?" component={PatientsShow} />

      <AuthRoute exact path="/:role(admin|doctor)/cases/:id/:view?" component={CasesShow} />

      <AuthRoute exact path="/:role(admin|doctor)/tests/:id/:view?" component={TestsShow} />

      <AuthRoute exact path="/:role(admin|doctor|patient)/notifications" component={Notifications} />
      <AuthRoute exact path="/:role(admin)/laboratories" component={Laboratories} />
      <AuthRoute exact path="/:role(admin)/referrals" component={Referrals} />
      <AuthRoute exact path="/:role(admin)/health-packages" component={HealthPackages} />
      <AuthRoute exact path="/:role(admin|doctor)/surveys/:id" component={HealthSurvey} />
    </Layout>
  </Switch>
);

export default Routes;
