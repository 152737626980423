import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { TextField, makeStyles } from '@material-ui/core';
import { UserCreationSchema } from 'utils/validation';
import { useAdminDispatch } from 'modules/admin/admins';
import FormDialog from 'components/FormDialog';

const useStyles = makeStyles(() => ({
  form: {
    '& > div': {
      marginBottom: 10
    },
    '& > button': {
      textAlign: 'right'
    }
  },
  formActions: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

const CreateAdminForm = ({ open, toggleOpen }) => {
  const classes = useStyles();
  const { createAdmin } = useAdminDispatch();
  return (
    <Formik
      initialValues={{
        personalNumber: '',
        email: '',
        firstName: '',
        lastName: '',
        password: 'Password123!',
      }}
      validationSchema={UserCreationSchema}
      onSubmit={(values, actions) => {
        createAdmin(values).then(() => {
          toggleOpen();
          actions.resetForm();
        }).catch(() => actions.setSubmitting(false));
      }}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
      }) => (
        <FormDialog
          title="Lägg till admin"
          description="Fyll i informationen nedan för att skapa en ny admin."
          open={open}
          toggle={toggleOpen}
          onSubmitDisabled={isSubmitting}
          onSubmit={handleSubmit}
        >
          <form onSubmit={handleSubmit} className={classes.form} autoComplete="off">
            <TextField
              variant="outlined"
              fullWidth
              required
              name="personalNumber"
              label="Personnummer (ÅÅÅÅMMDDXXXX)"
              type="number"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.personalNumber}
              error={touched.personalNumber && errors.personalNumber}
            />
            <TextField
              variant="outlined"
              fullWidth
              required
              name="firstName"
              label="Förnamn"
              type="text"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.firstName}
              error={touched.firstName && errors.firstName}
            />
            <TextField
              variant="outlined"
              fullWidth
              required
              name="lastName"
              label="Efternamn"
              type="text"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.lastName}
              error={touched.lastName && errors.lastName}
            />
            <TextField
              variant="outlined"
              fullWidth
              required
              name="email"
              label="Email"
              type="text"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.email}
              error={touched.email && errors.email}
            />
          </form>
        </FormDialog>
      )}
    />
  );
};

CreateAdminForm.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
};

export default CreateAdminForm;
