import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, makeStyles } from '@material-ui/core';

import useToggle from 'hooks/useToggle';
import EditPatientData from './EditPatientData';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 500,
    marginTop: 20,
    borderRadius: 5,
    boxShadow: theme.shadows[2],
    '& > div': {
      padding: 10,
    },
    '& > div:nth-child(even)': {
      backgroundColor: theme.palette.action.selected,
    }
  }
}));

const Overview = ({ patient, role }) => {
  const classes = useStyles();
  const [showUpdateInfo, toggleShowUpdateInfo] = useToggle();

  return (
    <React.Fragment>
      {role === 'admin' &&
        <React.Fragment>
          <EditPatientData
            open={showUpdateInfo}
            toggleOpen={toggleShowUpdateInfo}
            patient={patient}
          />
          <Button variant="contained" onClick={toggleShowUpdateInfo}>
            Uppdatera information
          </Button>
        </React.Fragment>
      }
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Grid container justify="space-between">
          <Grid item>Förnamn</Grid>
          <Grid item>{patient.firstName}</Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justify="space-between">
          <Grid item>Efternamn</Grid>
          <Grid item>{patient.lastName}</Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justify="space-between">
          <Grid item>Personnummer</Grid>
          <Grid item>{patient.personalNumber}</Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justify="space-between">
          <Grid item>Email</Grid>
          <Grid item>{patient.email}</Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justify="space-between">
          <Grid item>Adress</Grid>
          <Grid item>{patient.address}</Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justify="space-between">
          <Grid item>Postnummer</Grid>
          <Grid item>{patient.postCode}</Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justify="space-between">
          <Grid item>Ort</Grid>
          <Grid item>{patient.city}</Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justify="space-between">
          <Grid item>Land</Grid>
          <Grid item>{patient.country}</Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justify="space-between">
          <Grid item>Telefonnummer</Grid>
          <Grid item>{patient.phoneNumber}</Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justify="space-between">
          <Grid item>Telefonnummer (arbete)</Grid>
          <Grid item>{patient.phoneWork}</Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justify="space-between">
          <Grid item>Telefonnummer (hemma)</Grid>
          <Grid item>{patient.phoneHome}</Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justify="space-between">
          <Grid item>Längd (cm)</Grid>
          <Grid item>{patient.length}</Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justify="space-between">
          <Grid item>Vikt (kg)</Grid>
          <Grid item>{patient.weight}</Grid>
        </Grid>
      </Grid>
    </Grid>
    </React.Fragment>
  );
};

Overview.propTypes = {
  patient: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
};

export default Overview;
