import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  LinearProgress, makeStyles, Tab, Tabs, Typography, Button
} from '@material-ui/core';

import { useCaseDispatch as useCaseDispatchAdmin, useCaseState as useCaseStateAdmin } from 'modules/admin/cases';
import { useCaseDispatch as useCaseDispatchDoctor, useCaseState as useCaseStateDoctor } from 'modules/doctor/cases';
import useToggle from 'hooks/useToggle';

import Comments from 'components/Comments';
import Statements from 'components/Statements';
import Attachments from 'components/Attachments';
import TestResults from 'components/TestResults';

import UploadReferralForm from './show/UploadReferralForm';
import HealthSurvey from './show/HealthSurvey';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2)
  },
  title: {
    marginBottom: 20,
    marginLeft: 10
  }
}));

const Show = () => {
  const { role, id, view } = useParams();
  const classes = useStyles();
  const [showUploadReferral, toggleShowUploadReferral] = useToggle();

  const useCaseDispatch = role === 'admin' ? useCaseDispatchAdmin : useCaseDispatchDoctor;
  const useCaseState = role === 'admin' ? useCaseStateAdmin : useCaseStateDoctor;

  const { fetching, current } = useCaseState();
  const {
    fetchSingle, clearCurrent, createAttachment, deleteTestResult
  } = useCaseDispatch();

  useEffect(() => {
    fetchSingle(parseInt(id, 10));
    return () => clearCurrent();
  }, []);

  const handleDeleteTestResult = (testId) => {
    if (role === 'admin' && deleteTestResult) {
      deleteTestResult(testId, parseInt(id, 10));
    }
  };

  return (
    <div className="elevated-container">
    {fetching
      ? <LinearProgress />
      : Object.entries(current).length === 0 ?
        <React.Fragment>Kunde inte hitta ärende med angivet id.</React.Fragment>
        :
        <React.Fragment>
          <Typography variant="h2" className={classes.title}>
            Ärende för <Link to={`/${role}/patients/${current.patient.id}/cases`}>{current.patient.name} ({current.patient.personalNumber})</Link>
          </Typography>
          {current.healthPackageName &&
            <Typography variant="h3" className={classes.title}>
              Hälsopaket: {current.healthPackageName}
            </Typography>
          }
          {current.referralIds && current.referralIds.length > 0 &&
            <Typography variant="h3" className={classes.title}>
              Vi söker fortfarande svar från följande remisser (rid): {current.referralIds.join(', ')}
            </Typography>
          }
          {role === 'admin' &&
            <React.Fragment>
              <Button
                className={classes.title}
                variant="contained"
                onClick={toggleShowUploadReferral}
              >
                Lägg till remisser
              </Button>
              <UploadReferralForm
                caseId={parseInt(id, 10)}
                open={showUploadReferral}
                toggleOpen={toggleShowUploadReferral}
              />
            </React.Fragment>
          }
          <Tabs
            variant="scrollable"
            value={view}
            indicatorColor="primary"
            textColor="primary"
            aria-label="disabled tabs example"
          >
            <Tab label="Provresultat" value="tests" component={Link} to={`/${role}/cases/${id}/tests`} />
            <Tab label="Hälsoenkät" value="health-survey" component={Link} to={`/${role}/cases/${id}/health-survey`} />
            <Tab label="Bilagor" value="attachments" component={Link} to={`/${role}/cases/${id}/attachments`} />
            <Tab label="Utlåtanden" value="statements" component={Link} to={`/${role}/cases/${id}/statements`} />
            <Tab label="Kommentarer" value="comments" component={Link} to={`/${role}/cases/${id}/comments`} />
          </Tabs>
          <div className={classes.root}>
            {{
              tests:
                <TestResults
                  tests={current.testResults}
                  role={role}
                  id={id}
                  fetch={() => fetchSingle(parseInt(id, 10), false)}
                  deleteTest={handleDeleteTestResult}
                  type="case"
                />,
              'health-survey':
                <HealthSurvey
                  survey={current.healthSurvey}
                  role={role}
                  currentCase={current}
                />,
              attachments:
                <Attachments
                  attachments={current.patientAttachments}
                  create={createAttachment}
                  modelId={id}
                />,
              statements:
                <Statements
                  healthStatements={current.healthStatements}
                  doctorStatements={current.doctorStatements}
                  role={role}
                  page="case"
                  id={parseInt(id, 10)}
                />,
              comments:
                <Comments
                  comments={current.comments}
                  id={parseInt(id, 10)}
                  role={role}
                  page="case"
                />,
            }[view]}
          </div>
        </React.Fragment>
    }
    </div>
  );
};

export default Show;
