import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  LinearProgress, Tab, Tabs, makeStyles, Typography
} from '@material-ui/core';

import { usePatientDispatch as usePatientDispatchAdmin, usePatientState as usePatientStateAdmin } from 'modules/admin/patients';
import { usePatientDispatch as usePatientDispatchDoctor, usePatientState as usePatientStateDoctor } from 'modules/doctor/patients';

import Attachments from 'components/Attachments';
import Comments from 'components/Comments';
import Statements from 'components/Statements';
import TestResults from 'components/TestResults';
import Overview from './show/Overview';
import Cases from './show/Cases';
import HealthSurveys from './show/HealthSurveys';
import Messages from './show/Messages';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2)
  },
  title: {
    marginBottom: 20,
    marginLeft: 10
  }
}));

const Show = () => {
  const classes = useStyles();
  const { role, id, view } = useParams();

  const usePatientDispatch = role === 'admin' ? usePatientDispatchAdmin : usePatientDispatchDoctor;
  const usePatientState = role === 'admin' ? usePatientStateAdmin : usePatientStateDoctor;

  const {
    fetchSingle, clearCurrent, createAttachment, deleteTestResult
  } = usePatientDispatch();
  const { fetching, current } = usePatientState();

  useEffect(() => {
    fetchSingle(parseInt(id, 10));
    return () => clearCurrent();
  }, []);

  const handleDeleteTestResult = (testId) => {
    if (role === 'admin' && deleteTestResult) {
      deleteTestResult(testId, parseInt(id, 10));
    }
  };

  return (
    <div className="elevated-container">
    {fetching
      ? <LinearProgress />
      : Object.entries(current).length === 0 ?
        <React.Fragment>Kunde inte hitta klient</React.Fragment>
        :
        <React.Fragment>
          <Typography variant="h2" className={classes.title}>
            Journal för {current.name} ({current.personalNumber})
          </Typography>
          <Tabs
            variant="scrollable"
            value={view}
            indicatorColor="primary"
            textColor="primary"
            aria-label="disabled tabs example"
          >
            <Tab label="Översikt" value="overview" component={Link} to={`/${role}/patients/${id}/overview`} />
            <Tab label="Ärenden" value="cases" component={Link} to={`/${role}/patients/${id}/cases`} />
            <Tab label="Provresultat" value="tests" component={Link} to={`/${role}/patients/${id}/tests`} />
            <Tab label="Utlåtanden" value="statements" component={Link} to={`/${role}/patients/${id}/statements`} />
            <Tab label="Hälsoenkäter" value="health-surveys" component={Link} to={`/${role}/patients/${id}/health-surveys`} />
            <Tab label="Bilagor" value="attachments" component={Link} to={`/${role}/patients/${id}/attachments`} />
            <Tab label="Kommentarer" value="comments" component={Link} to={`/${role}/patients/${id}/comments`} />
            { role === 'admin' &&
              <Tab label="Meddelanden" value="messages" component={Link} to={`/${role}/patients/${id}/messages`} />
            }
          </Tabs>
          <div className={classes.root}>
            {{
              overview: <Overview
                patient={current}
                role={role}
              />,
              cases:
                <Cases
                  cases={current.cases}
                  role={role}
                  patientId={current.patientId}
                  fetchPatient={() => fetchSingle(parseInt(id, 10), false)}
                />,
              tests:
                <TestResults
                  tests={current.testResults}
                  role={role}
                  id={id}
                  type="patient"
                  fetch={() => fetchSingle(parseInt(id, 10), false)}
                  deleteTest={handleDeleteTestResult}
                />,
              statements:
                <Statements
                  healthStatements={current.generalHealthStatements}
                  doctorStatements={current.doctorStatements}
                  role={role}
                  page="patient"
                  id={parseInt(id, 10)}
                />,
              'health-surveys': <HealthSurveys surveys={current.healthSurveys} role={role} />,
              attachments:
                <Attachments
                  attachments={current.patientAttachments}
                  create={createAttachment}
                  modelId={id}
                />,
              comments:
                <Comments
                  comments={current.comments}
                  id={parseInt(id, 10)}
                  role={role}
                  page="patient"
                />,
              messages: role === 'admin' && <Messages messages={current.messages} />,
            }[view]}
          </div>
        </React.Fragment>
    }
    </div>
  );
};

export default Show;
