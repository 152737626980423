import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { COMMON_ENDPOINTS } from 'utils/api';

const FETCHING = 'NOTIFICATIONS/FETCHING';
const FETCHED_ALL = 'NOTIFICATIONS/FETCHED_ALL';
const SET_READ = 'NOTIFICATIONS/SET_READ';
const SET_DONE = 'NOTIFICATIONS/SET_DONE';

const initialState = {
  fetching: false,
  data: [],
  unreadNotifications: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCHING:
      return { ...state, fetching: true };
    case FETCHED_ALL:
      return {
        ...state,
        data: action.payload.notifications,
        unreadNotifications: action.payload.unreadNotifications,
        fetching: false
      };
    case SET_READ:
    case SET_DONE:
    default:
      return state;
  }
};

export const fetchAll = () => (dispatch) => {
  dispatch({ type: FETCHING });
  return axios.get(`${COMMON_ENDPOINTS.NOTIFICATIONS}`)
    .then((res) => dispatch({ type: FETCHED_ALL, payload: res.data }));
};

export const setRead = (notificationId) => (dispatch) =>
  axios.post(`${COMMON_ENDPOINTS.NOTIFICATIONS}/read/${notificationId}`)
    .then(() => {
      dispatch({
        type: SET_READ,
      });
    });

export const setDone = (notificationId) => (dispatch) =>
  axios.post(`${COMMON_ENDPOINTS.NOTIFICATIONS}/done/${notificationId}`)
    .then(() => {
      dispatch({
        type: SET_DONE,
      });
    });

export const useNotificationState = () => ({
  notifications: useSelector(({ notifications: { data } }) => data),
  unreadNotifications: useSelector(({ notifications: { unreadNotifications } }) => unreadNotifications),
  fetching: useSelector(({ notifications: { fetching } }) => fetching),
});

export const useNotificationDispatch = () => {
  const dispatch = useDispatch();
  return {
    fetchAll: () => dispatch(fetchAll()),
    setRead: (notificationId) => dispatch(setRead(notificationId)),
    setDone: (notificationId) => dispatch(setDone(notificationId))
  };
};
