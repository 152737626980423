import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

import FormDialog from 'components/FormDialog';
import { makeStyles, TextField } from '@material-ui/core';
import { PatientCreationSchema } from 'utils/validation';
import { usePatientDispatch } from 'modules/admin/patients';

const useStyles = makeStyles(() => ({
  form: {
    '& > div': {
      marginBottom: 10
    }
  },
}));
const EditPatientData = ({ open, toggleOpen, patient }) => {
  const classes = useStyles();
  const { updatePatient } = usePatientDispatch();

  return (
    <Formik
      initialValues={{
        firstName: patient.firstName ? patient.firstName : '',
        lastName: patient.lastName ? patient.lastName : '',
        email: patient.email ? patient.email : '',
        personalNumber: patient.personalNumber ? patient.personalNumber : '',
        address: patient.address ? patient.address : '',
        postCode: patient.postCode ? patient.postCode : '',
        city: patient.city ? patient.city : '',
        country: patient.country ? patient.country : '',
        phoneNumber: patient.phoneNumber ? patient.phoneNumber : '',
        phoneWork: patient.phoneWork ? patient.phoneWork : '',
        phoneHome: patient.phoneHome ? patient.phoneHome : '',
        length: patient.length ? patient.length : '',
        weight: patient.weight ? patient.weight : ''
      }}
      validationSchema={PatientCreationSchema}
      onSubmit={(values, actions) => {
        updatePatient(patient.patientId, {
          ...values,
          length: values.length === '' ? 0 : parseInt(values.length, 10),
          weight: values.weight === '' ? 0 : parseInt(values.weight, 10)
        }).then(() => {
          actions.resetForm();
          toggleOpen();
        })
          .catch(() => actions.setSubmitting(false));
      }}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        resetForm
      }) => (
        <FormDialog
          title="Uppdatera klient"
          description="Uppdatera klientens information nedan."
          open={open}
          toggle={() => {
            toggleOpen();
            resetForm();
          }}
          onSubmitDisabled={isSubmitting}
          onSubmit={handleSubmit}
        >
          <form onSubmit={handleSubmit} className={classes.form}>
            <TextField
              variant="outlined"
              fullWidth
              name="firstName"
              label="Förnamn"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.firstName}
              error={touched.firstName && errors.firstName}
            />
            <TextField
              variant="outlined"
              fullWidth
              name="lastName"
              label="Efternamn"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.lastName}
              error={touched.lastName && errors.lastName}
            />
            <TextField
              variant="outlined"
              fullWidth
              name="email"
              label="Email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              error={touched.email && errors.email}
            />
            <TextField
              variant="outlined"
              fullWidth
              name="personalNumber"
              label="Personnummer (ÅÅÅÅMMDDXXXX)"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.personalNumber}
              error={touched.personalNumber && errors.personalNumber}
              helperText={errors.personalNumber}
            />
            <TextField
              variant="outlined"
              fullWidth
              name="address"
              label="Adress"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.address}
              error={touched.address && errors.address}
            />
            <TextField
              variant="outlined"
              fullWidth
              name="postCode"
              label="Postnummer"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.postCode}
              error={touched.postCode && errors.postCode}
            />
            <TextField
              variant="outlined"
              fullWidth
              name="city"
              label="Ort"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.city}
              error={touched.city && errors.city}
            />
            <TextField
              variant="outlined"
              fullWidth
              name="country"
              label="Land"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.country}
              error={touched.country && errors.country}
            />
            <TextField
              variant="outlined"
              fullWidth
              name="phoneNumber"
              label="Telefonnummer"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.phoneNumber}
              error={touched.phoneNumber && errors.phoneNumber}
            />
            <TextField
              variant="outlined"
              fullWidth
              name="phoneWork"
              label="Telefonnummer (arbete)"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.phoneWork}
              error={touched.phoneWork && errors.phoneWork}
            />
            <TextField
              variant="outlined"
              fullWidth
              name="phoneHome"
              label="Telefonnummer (hemma)"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.phoneHome}
              error={touched.phoneHome && errors.phoneHome}
            />
            <TextField
              variant="outlined"
              fullWidth
              type="number"
              name="length"
              label="Längd (cm)"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.length}
              error={touched.length && errors.length}
            />
            <TextField
              variant="outlined"
              fullWidth
              type="number"
              name="weight"
              label="Vikt (kg)"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.weight}
              error={touched.weight && errors.weight}
            />
          </form>
        </FormDialog>
      )}
    />
  );
};

EditPatientData.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  patient: PropTypes.object.isRequired,
};

export default EditPatientData;
