import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { ADMIN_ENDPOINTS } from 'utils/api';

const FETCHING = 'ADMIN/DOCTORS/FETCHING';
const FETCHED_ALL = 'ADMIN/DOCTORS/FETCHED_ALL';
const FETCHED_SINGLE = 'ADMIN/DOCTORS/FETCHED_SINGLE';
const CREATE = 'ADMIN/DOCTORS/CREATE';
const CLEAR_CURRENT = 'ADMIN/DOCTORS/CLEAR_CURRENT';

const initialState = {
  fetching: false,
  fetched: false,
  data: [],
  current: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCHED_ALL:
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: action.payload
      };
    case FETCHED_SINGLE:
      return {
        ...state,
        fetching: false,
        current: action.payload
      };
    case FETCHING:
      return {
        ...state,
        fetching: true
      };
    case CLEAR_CURRENT:
      return {
        ...state,
        current: {}
      };
    case CREATE:
    default:
      return state;
  }
};

export const fetchAll = (showLoader = true) => (dispatch) => {
  if (showLoader) dispatch({ type: FETCHING });

  return axios.get(`${ADMIN_ENDPOINTS.DOCTORS}`)
    .then((res) => dispatch({ type: FETCHED_ALL, payload: res.data }));
};

export const fetchSingle = (id) => (dispatch) => {
  dispatch({ type: FETCHING });

  return axios.get(`${ADMIN_ENDPOINTS.DOCTORS}/${id}`)
    .then((res) => dispatch({ type: FETCHED_SINGLE, payload: res.data }));
};

export const clearCurrent = () => (dispatch) => dispatch({ type: CLEAR_CURRENT });

export const createDoctor = (newDoctor) => (dispatch) =>
  axios.post(`${ADMIN_ENDPOINTS.DOCTORS}`, newDoctor)
    .then(() => {
      dispatch({ type: CREATE });
      dispatch(fetchAll(false));
    });

export const useDoctorState = () => ({
  fetching: useSelector(({ admin: { doctors: { fetching } } }) => fetching),
  fetched: useSelector(({ admin: { doctors: { fetched } } }) => fetched),
  doctors: useSelector(({ admin: { doctors: { data } } }) => data),
  current: useSelector(({ admin: { doctors: { current } } }) => current),
});

export const useDoctorDispatch = () => {
  const dispatch = useDispatch();
  return {
    fetchSingle: (id) => dispatch(fetchSingle(id)),
    fetchAll: () => dispatch(fetchAll()),
    clearCurrent: () => dispatch(clearCurrent()),
    createDoctor: (newDoctor) => dispatch(createDoctor(newDoctor))
  };
};
