import React, { useEffect, useState } from 'react';

import { useReferralDispatch, useReferralState } from 'modules/admin/referrals';
import {
  IconButton, LinearProgress, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Box, makeStyles
} from '@material-ui/core';
import {
  Clear as CrossIcon,
  Done as DoneIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@material-ui/icons';

import ButtonBar from 'components/ButtonBar';

import useToggle from 'hooks/useToggle';
import ReferralModal from './referrals/ReferralModal';

const useStyles = makeStyles((theme) => ({
  crossIcon: {
    fill: theme.palette.error.main,
  },
  doneIcon: {
    fill: theme.palette.success.main,
  }
}));

const Referrals = () => {
  const classes = useStyles();
  const [newReferralOpen, toggleReferralOpen] = useToggle();
  const [editReferral, setEditReferral] = useState(false);
  const { fetching, referrals, fetched } = useReferralState();
  const { fetchAll } = useReferralDispatch();

  useEffect(() => {
    if (!fetched) fetchAll();
  }, []);

  const handleReferralEdit = (hp) => {
    setEditReferral(hp);
    toggleReferralOpen();
  };

  const clearEditPackage = () => setEditReferral(false);

  const buttons = [{
    name: 'Lägg till remiss',
    icon: 'add',
    onClickEvent: 'func',
    onClick: toggleReferralOpen
  }, {
    icon: 'refresh',
    onClickEvent: 'func',
    onClick: fetchAll
  }];

  return (
    <div className="elevated-container">
    {fetching
      ? <LinearProgress/>
      :
      <React.Fragment>
        <Box ml="10px">
          <ButtonBar buttons={buttons}/>
        </Box>
        <ReferralModal
          toggleOpen={toggleReferralOpen}
          open={newReferralOpen}
          edit={editReferral !== false}
          editReferral={editReferral}
          clearEditReferral={clearEditPackage}
        />
        {referrals.length === 0 ?
          'Hittade inga remisser'
          :
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Namn</TableCell>
                <TableCell>Laboratorie</TableCell>
                <TableCell>Verifierad</TableCell>
                <TableCell/>
              </TableRow>
            </TableHead>
            <TableBody>
              {referrals.map((r) => (
                <TableRow key={r.id}>
                  <TableCell component="th" scope="row">
                    {r.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {r.laboratory.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {r.needsVerification
                      ? <CrossIcon className={classes.crossIcon}/>
                      : <DoneIcon className={classes.doneIcon}/>
                    }
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Redigera remiss">
                      <IconButton onClick={() => handleReferralEdit(r)}>
                        <EditIcon color="primary"/>
                      </IconButton>
                    </Tooltip>
                    {/*<Tooltip title="Ta bort remiss (TBI)">
                      <IconButton>
                        <DeleteIcon color="error"/>
                      </IconButton>
                    </Tooltip>*/}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        }
      </React.Fragment>
    }
    </div>
  );
};

export default Referrals;
