import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Formik } from 'formik';
import {
  Grid, Avatar, makeStyles, TextField, Button
} from '@material-ui/core';

import { MessageCreationSchema } from 'utils/validation';
import { usePatientDispatch } from 'modules/admin/patients';

const useStyles = makeStyles((theme) => ({
  message: {
    margin: theme.spacing(3, 0)
  },
  contentWrapper: {
    borderBottom: '1px solid #C0C0C0',
    paddingBottom: 20,
  },
  messageHeader: {
    fontSize: 20,
    marginBottom: 10,
    fontWeight: '#C0C0C0'
  },
  content: {
    whiteSpace: 'pre-line',
    wordWrap: 'break-word'
  },
  date: {
    color: 'grey'
  },
  addNew: {
    marginTop: 30,
    '& > .header': {
      fontSize: 20
    },
    '& > div': {
      marginBottom: 10
    },
    '& .bottom': {
      display: 'flex',
      justifyContent: 'space-between'
    }
  }
}));

const Messages = ({ messages }) => {
  const classes = useStyles();
  const { role, id } = useParams();
  const { createMessage } = usePatientDispatch();

  const MessageForm = () =>
    <Formik
      initialValues={{ content: '' }}
      validationSchema={MessageCreationSchema}
      onSubmit={(values, action) => {
        createMessage(id, values).then(() => action.resetForm()).catch(() => action.setSubmitting(false));
      }}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
      }) => (
        <form onSubmit={handleSubmit} className={classes.addNew}>
          <div className="header">Lägg till ett nytt meddelande</div>
          <TextField
            variant="outlined"
            fullWidth
            multiline={true}
            rows={8}
            name="content"
            label="Utlåtande"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.content}
            error={touched.content && errors.content}
          />
          <div className="bottom">
            <Button
              variant="outlined"
              type="submit"
              disabled={isSubmitting}
            >
              Lägg till
            </Button>
          </div>
        </form>
      )}
    />;

  return (
    <React.Fragment>
      { messages.map((message) =>
        <Grid container key={message.id} justify="space-around" alignItems="center" className={classes.message}>
          <Grid item>
            <Avatar>{message.creatorName.charAt(0)}</Avatar>
          </Grid>
          <Grid item xs={10}>
            <Grid container justify="space-between" className={classes.contentWrapper}>
              <Grid item xs={10}>
                <div className={classes.messageHeader}>
                  {message.creatorName}
                </div>
                <div className={classes.content}>{message.content}</div>
              </Grid>
              <Grid item className={classes.date}>
                {message.createdAt.split('T')[0]}
              </Grid>
            </Grid>
          </Grid>
        </Grid>)
      }
      {role === 'admin' && MessageForm()}
      </React.Fragment>
  );
};

Messages.propTypes = {
  messages: PropTypes.array.isRequired,
};

export default Messages;
