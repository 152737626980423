import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { PATIENT_ENDPOINTS } from 'utils/api';

const FETCHING = 'PATIENT/SURVEYS/FETCHING';
const FETCHED_ALL = 'PATIENT/SURVEYS/FETCHED_ALL';
const FETCHED_SINGLE = 'PATIENT/SURVEYS/FETCHED_SINGLE'
const UPDATE = 'PATIENT/SURVEYS/UPDATE';
const CLEAR_CURRENT = 'PATIENT/SURVEYS/CLEAR_CURRENT';

const initialState = {
  fetching: true,
  data: [],
  current: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCHED_ALL:
      return {
        ...state,
        fetching: false,
        data: action.payload
      };
    case FETCHED_SINGLE:
      return {
        ...state,
        fetching: false,
        current: action.payload
      };
    case FETCHING:
      return {
        ...state,
        fetching: true,
      };
    case CLEAR_CURRENT:
      return {
        ...state,
        current: {}
      };
    case UPDATE:
    default:
      return state;
  }
};

const fetchAll = (showLoader = true) => (dispatch) => {
  if (showLoader) dispatch({ type: FETCHING });

  return axios.get(`${PATIENT_ENDPOINTS.SURVEYS}`)
    .then((res) => dispatch({ type: FETCHED_ALL, payload: res.data }));
};

const fetchSingle = (id, showLoader = true) => (dispatch) => {
  if (showLoader) dispatch({ type: FETCHING });

  return axios.get(`${PATIENT_ENDPOINTS.SURVEYS}/${id}`)
    .then((res) => dispatch({ type: FETCHED_SINGLE, payload: res.data }));
};

const clearCurrent = () => (dispatch) => dispatch({ type: CLEAR_CURRENT });

const update = (id, versionId, updatedSurvey) => (dispatch) =>
  axios.put(`${PATIENT_ENDPOINTS.SURVEYS}/${id}`, {
    answers: { ...updatedSurvey },
    versionId,
  }).then(() => {
    dispatch({ type: UPDATE });
    dispatch(fetchSingle(id, false));
  });

export const useSurveyState = () => ({
  fetching: useSelector(({ patient: { surveys: { fetching } } }) => fetching),
  surveys: useSelector(({ patient: { surveys: { data } } }) => data),
  current: useSelector(({ patient: { surveys: { current } } }) => current),
});

export const useSurveyDispatch = () => {
  const dispatch = useDispatch();
  return {
    fetchSingle: (id) => dispatch(fetchSingle(id)),
    fetchAll: () => dispatch(fetchAll()),
    update: (id, versionId, updatedSurvey) => dispatch(update(id, versionId, updatedSurvey)),
    clearCurrent: () => dispatch(clearCurrent()),
  };
};
