import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import { useCaseDispatch } from 'modules/admin/cases';
import FormDialog from 'components/FormDialog';
import HealthSurveyForm from 'components/HealthSurveyForm';
import { getLatestVersionId } from 'utils/surveys';

const CreateHealthSurveyDialog = ({
  toggleOpen, open, currentCase, healthSurveyId,
}) => {
  const { updateSurveyAnswers } = useCaseDispatch();

  // Gets the latest survey
  const versionId = getLatestVersionId();

  const [answers, setAnswers] = useState([]);
  const [extraData, setExtraData] = useState({});
  const [touched, setTouched] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setTouched(true);
    setIsSubmitting(true);
    if (!answers.every((answer) => answer.answer.length > 0 && answer.answer[0] !== '')) {
      setIsSubmitting(false);
      return;
    }
    await updateSurveyAnswers(currentCase.id, healthSurveyId, versionId, {
      survey: answers,
      extra: extraData,
    });
    setIsSubmitting(false);
  };

  return (
    <FormDialog
      title="Skapa hälsoenkätsvar"
      description="Besvara frågorna nedan för att skapa ett hälsoenkätsvar och livshjul för detta ärende."
      open={open}
      toggle={() => {
        toggleOpen();
      }}
      onSubmitDisabled={isSubmitting}
      onSubmit={handleSubmit}
    >
      {versionId ?
        <HealthSurveyForm
          surveyVersionId={versionId}
          id={healthSurveyId}
          updateAnswers={setAnswers}
          updateExtraData={setExtraData}
          gender={currentCase.patient.gender}
        />
        :
        'Kunde inte generera enkäten. Kontakta utvecklarna om problemet består.'
      }
      {touched && (answers.length === 0 || !answers.every((answer) => answer.answer.length > 0 && answer.answer[0] !== ''))
        ?
        <Typography color="error">Vänligen besvara samtliga frågor.</Typography>
        : null
      }
    </FormDialog>
  );
};

CreateHealthSurveyDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  currentCase: PropTypes.object.isRequired,
  healthSurveyId: PropTypes.number.isRequired,
};

export default CreateHealthSurveyDialog;
