import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { DOCTOR_ENDPOINTS } from 'utils/api';

const FETCHING = 'DOCTOR/CASES/FETCHING';
const FETCHED_ALL = 'DOCTOR/CASES/FETCHED_ALL';
const FETCHED_SINGLE = 'DOCTOR/CASES/FETCHED_SINGLE';
const CREATE_STATEMENT = 'DOCTOR/CASES/CREATE_STATEMENT';
const CREATE_ATTACHMENT = 'DOCTOR/CASES/CREATE_ATTACHMENT';
const CLEAR_CURRENT = 'DOCTOR/CASES/CLEAR_CURRENT';
const CREATE_COMMENT = 'DOCTOR/CASES/CREATE_COMMENT';
const UPDATE_COMMENT = 'DOCTOR/CASES/UPDATE_COMMENT';
const DELETE_COMMENT = 'DOCTOR/CASES/DELETE_COMMENT';

const initialState = {
  fetching: false,
  data: [],
  current: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCHED_ALL:
      return {
        ...state,
        fetching: false,
        data: action.payload
      };
    case FETCHED_SINGLE:
      return {
        ...state,
        fetching: false,
        current: action.payload
      };
    case FETCHING:
      return {
        ...state,
        fetching: true,
      };
    case CLEAR_CURRENT:
      return {
        ...state,
        current: {}
      };
    case CREATE_STATEMENT:
    case CREATE_ATTACHMENT:
    case CREATE_COMMENT:
    case UPDATE_COMMENT:
    case DELETE_COMMENT:
    default:
      return state;
  }
};

export const fetchAll = (showLoader = true) => (dispatch) => {
  if (showLoader) dispatch({ type: FETCHING });

  return axios.get(`${DOCTOR_ENDPOINTS.CASES}`)
    .then((res) => dispatch({ type: FETCHED_ALL, payload: res.data }));
};

export const fetchSingle = (id, showLoader = true) => (dispatch) => {
  if (showLoader) dispatch({ type: FETCHING });

  return axios.get(`${DOCTOR_ENDPOINTS.CASES}/${id}`)
    .then((res) => dispatch({ type: FETCHED_SINGLE, payload: res.data }));
};

export const clearCurrent = () => (dispatch) => dispatch({ type: CLEAR_CURRENT });

export const createStatement = (caseId, newStatement) => (dispatch) =>
  axios.post(`${DOCTOR_ENDPOINTS.CASES}/${caseId}/statements`, newStatement).then(() => {
    dispatch({ type: CREATE_STATEMENT });
    dispatch(fetchSingle(caseId, false));
  });

export const createAttachment = (id, newAttachment) => (dispatch) =>
  axios.post(`${DOCTOR_ENDPOINTS.CASES}/${id}/attachment`, newAttachment,{
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
    .then(() => {
      dispatch({ type: CREATE_ATTACHMENT });
      dispatch(fetchSingle(id, false));
    });

export const createComment = (caseId, newComment) => (dispatch) =>
  axios.post(`${DOCTOR_ENDPOINTS.CASES}/${caseId}/comments`, newComment).then(() => {
    dispatch({ type: CREATE_COMMENT });
    dispatch(fetchSingle(caseId, false));
  });

export const updateComment = (caseId, commentId, updatedComment) => (dispatch) =>
  axios.put(`${DOCTOR_ENDPOINTS.CASES}/${caseId}/comments/${commentId}`, updatedComment).then(() => {
    dispatch({ type: UPDATE_COMMENT });
    dispatch(fetchSingle(caseId, false));
  });

export const deleteComment = (caseId, statementId) => (dispatch) =>
  axios.delete(`${DOCTOR_ENDPOINTS.CASES}/${caseId}/comments/${statementId}`).then(() => {
    dispatch({ type: DELETE_COMMENT });
    dispatch(fetchSingle(caseId, false));
  });

export const useCaseState = () => ({
  fetching: useSelector(({ doctor: { cases: { fetching } } }) => fetching),
  cases: useSelector(({ doctor: { cases: { data } } }) => data),
  current: useSelector(({ doctor: { cases: { current } } }) => current),
});

export const useCaseDispatch = () => {
  const dispatch = useDispatch();
  return {
    fetchSingle: (id) => dispatch(fetchSingle(id)),
    fetchAll: () => dispatch(fetchAll()),
    clearCurrent: () => dispatch(clearCurrent()),
    createStatement: (caseId, newStatement) => dispatch(createStatement(caseId, newStatement)),
    createAttachment: (id, newAttachment) => dispatch(createAttachment(id, newAttachment)),
    createComment: (caseId, newComment) => dispatch(createComment(caseId, newComment)),
    updateComment: (caseId, commentId, updatedComment) => dispatch(updateComment(caseId, commentId, updatedComment)),
    deleteComment: (caseId, statementId) => dispatch(deleteComment(caseId, statementId)),
  };
};
