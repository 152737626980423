import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  makeStyles, FormControl, Select, MenuItem, LinearProgress
} from '@material-ui/core';
import { CaseCreationSchema } from 'utils/validation';
import FormDialog from 'components/FormDialog';
import { useCaseDispatch } from 'modules/admin/cases';
import { useHealthPackageDispatch, useHealthPackageState } from 'modules/admin/healthPackages';

const useStyles = makeStyles(() => ({
  form: {
    '& > div': {
      marginBottom: 10
    },
    '& > button': {
      textAlign: 'right'
    }
  },
  formActions: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

const CreateCaseForm = ({
  toggleOpen, open, patientId, fetchPatient
}) => {
  const classes = useStyles();
  const { fetching: fetchingHealthPackages, healthPackages } = useHealthPackageState();
  const { fetchAll: fetchAllHealthPackages } = useHealthPackageDispatch();

  const { createCase } = useCaseDispatch();

  useEffect(() => {
    fetchAllHealthPackages();
  }, []);

  return (
    fetchingHealthPackages ?
      <LinearProgress/>
      :
      <Formik
        initialValues={{
          patientId,
          healthPackageId: healthPackages.length > 0 ? healthPackages[0].id : '',
        }}
        validationSchema={CaseCreationSchema}
        onSubmit={(values, actions) => {
          createCase(values).then(() => {
            toggleOpen();
            fetchPatient();
            actions.resetForm();
          }).catch(() => actions.setSubmitting(false));
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => (
          <FormDialog
            title="Lägg till ett ärende"
            description={`
            ${healthPackages.length === 0 ? 'Kunde inte hitta några hälsopaket. ' : ''}
            ${healthPackages.length > 0 && 'Fyll i informationen nedan för att skapa ett nytt ärende. '}
            `}
            open={open}
            toggle={toggleOpen}
            onSubmitDisabled={isSubmitting}
            onSubmit={handleSubmit}
          >
            <form onSubmit={handleSubmit} className={classes.form}>
              <FormControl fullWidth>
                <Select
                  variant="outlined"
                  value={values.healthPackageId}
                  onChange={handleChange}
                  inputProps={{
                    name: 'healthPackageId',
                    id: 'healthPackageId-select',
                  }}
                >
                  {healthPackages.map((hp) =>
                    <MenuItem key={`healthpackage-select-${hp.id}`} value={hp.id}>
                      {`${hp.name} (Produkt-ID ${hp.woocommerceProductId})`}
                    </MenuItem>)
                  }
                </Select>
              </FormControl>
            </form>
          </FormDialog>
        )}
      />
  );
};

CreateCaseForm.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  patientId: PropTypes.number.isRequired,
  fetchPatient: PropTypes.func.isRequired,
};

export default CreateCaseForm;
