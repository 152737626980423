import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { ADMIN_ENDPOINTS } from 'utils/api';

const FETCHING = 'ADMIN/REFERRALS/FETCHING';
const FETCHED_ALL = 'ADMIN/REFERRALS/FETCHED_ALL';
const CREATE = 'ADMIN/REFERRALS/CREATE';
const UPDATE = 'ADMIN/REFERRALS/UPDATE';

const initialState = {
  fetching: false,
  fetched: false,
  data: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCHED_ALL:
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: action.payload
      };
    case FETCHING:
      return {
        ...state,
        fetching: true
      };
    case CREATE:
    case UPDATE:
    default:
      return state;
  }
};

export const fetchAll = (showLoader = true) => (dispatch) => {
  if (showLoader) dispatch({ type: FETCHING });

  return axios.get(`${ADMIN_ENDPOINTS.REFERRALS}`)
    .then((res) => dispatch({ type: FETCHED_ALL, payload: res.data }));
};

export const createReferral = (newReferral) => (dispatch) =>
  axios.post(`${ADMIN_ENDPOINTS.REFERRALS}`, newReferral)
    .then(() => {
      dispatch({ type: CREATE });
      dispatch(fetchAll(false));
    });

export const updateReferral = (referralId, updatedReferral) => (dispatch) =>
  axios.put(`${ADMIN_ENDPOINTS.REFERRALS}/${referralId}`, updatedReferral)
    .then(() => {
      dispatch({ type: UPDATE });
      dispatch(fetchAll());
    });

export const useReferralState = () => ({
  fetching: useSelector(({ admin: { referrals: { fetching } } }) => fetching),
  fetched: useSelector(({ admin: { referrals: { fetched } } }) => fetched),
  referrals: useSelector(({ admin: { referrals: { data } } }) => data),
});

export const useReferralDispatch = () => {
  const dispatch = useDispatch();
  return {
    fetchAll: () => dispatch(fetchAll()),
    createReferral: (newReferral) => dispatch(createReferral(newReferral)),
    updateReferral: (referralId, updatedReferral) =>
      dispatch(updateReferral(referralId, updatedReferral)),
  };
};
