import React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import CssBaseline from '@material-ui/core/CssBaseline';

import { useAccountState, useAccountDispatch } from 'modules/account';
import useToggle from 'hooks/useToggle';
import SideDrawer from './components/SideDrawer';
import Header from './components/Header';
import theme from './theme';

import useStyles from './styles';

const Layout = ({ children }) => {
  const [drawerOpen, toggleDrawerOpen] = useToggle();
  const classes = useStyles();

  const { authenticated, role } = useAccountState();
  const { logout } = useAccountDispatch();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className={classes.root}>
        <Header
          toggleDrawer={toggleDrawerOpen}
        />
        <SideDrawer
          isLoggedIn={authenticated}
          toggle={toggleDrawerOpen}
          role={role}
          open={drawerOpen}
          logout={logout}
        />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <div className={classes.container}>
            <Paper className={classes.paper}>
              { children }
            </Paper>
          </div>
        </main>
      </div>
    </ThemeProvider>
  );
};

export default Layout;
