import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Badge from '@material-ui/core/Badge';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PeopleIcon from '@material-ui/icons/People';
import DoctorsIcon from '@material-ui/icons/LocalHospital';
import NotificationIcon from '@material-ui/icons/Notifications';
import AdminIcon from '@material-ui/icons/SupervisorAccount';
import LaboratoryIcon from '@material-ui/icons/MeetingRoom';
import ReferralIcon from '@material-ui/icons/Class';
import HealthPackageIcon from '@material-ui/icons/CollectionsBookmark';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import { useAccountState } from 'modules/account';
import { useNotificationState } from 'modules/notifications';

const AdminMenu = ({ toggle, isMobile, pathname }) => {
  const { user: { unreadNotifications: userUnreadNotifications } } = useAccountState();
  const { unreadNotifications } = useNotificationState();

  return (
    <React.Fragment>
      <Divider />
      <List>
        <ListItem
          button component={Link}
          to="/admin/patients"
          selected={pathname.includes('/admin/patients')}
          onClick={() => isMobile && toggle()}
        >
          <ListItemIcon><PeopleIcon /></ListItemIcon>
          <ListItemText primary="Klienter" />
        </ListItem>
        <ListItem
          button component={Link}
          to="/admin/doctors"
          selected={pathname.includes('/admin/doctors')}
          onClick={() => isMobile && toggle()}
        >
          <ListItemIcon><DoctorsIcon /></ListItemIcon>
          <ListItemText primary="Läkare" />
        </ListItem>
        <ListItem
          button component={Link}
          to="/admin/admins"
          selected={pathname.includes('/admin/admins')}
          onClick={() => isMobile && toggle()}
        >
          <ListItemIcon><AdminIcon /></ListItemIcon>
          <ListItemText primary="Admins" />
        </ListItem>
        <ListItem
          button component={Link}
          to="/admin/laboratories"
          selected={pathname.includes('/admin/laboratories')}
          onClick={() => isMobile && toggle()}
        >
          <ListItemIcon><LaboratoryIcon /></ListItemIcon>
          <ListItemText primary="Laboratorier" />
        </ListItem>
        <ListItem
          button component={Link}
          to="/admin/referrals"
          selected={pathname.includes('/admin/referrals')}
          onClick={() => isMobile && toggle()}
        >
          <ListItemIcon><ReferralIcon /></ListItemIcon>
          <ListItemText primary="Remisser" />
        </ListItem>
        <ListItem
          button component={Link}
          to="/admin/health-packages"
          selected={pathname.includes('/admin/health-packages')}
          onClick={() => isMobile && toggle()}
        >
          <ListItemIcon><HealthPackageIcon /></ListItemIcon>
          <ListItemText primary="Hälsopaket" />
        </ListItem>
        <ListItem
          button component={Link}
          to="/admin/notifications"
          selected={pathname.includes('/admin/notifications')}
          onClick={() => isMobile && toggle()}
        >
          <ListItemIcon>
            <Badge badgeContent={unreadNotifications !== null ? unreadNotifications : userUnreadNotifications} color="error">
              <NotificationIcon />
            </Badge>
          </ListItemIcon>
          <ListItemText primary="Notifikationer" />
        </ListItem>
      </List>
    </React.Fragment>
  );
};

AdminMenu.propTypes = {
  toggle: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  pathname: PropTypes.string.isRequired,
};

export default AdminMenu;
