import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { ADMIN_ENDPOINTS } from 'utils/api';

const FETCHING = 'ADMIN/CASES/FETCHING';
const FETCHED_ALL = 'ADMIN/CASES/FETCHED_ALL';
const FETCHED_SINGLE = 'ADMIN/CASES/FETCHED_SINGLE';
const CREATE_CASE = 'ADMIN/CASES/CREATE_CASE';
const CREATE_COMMENT = 'ADMIN/CASES/CREATE_COMMENT';
const UPDATE_COMMENT = 'ADMIN/CASES/UPDATE_COMMENT';
const DELETE_COMMENT = 'ADMIN/CASES/DELETE_COMMENT';
const CREATE_STATEMENT = 'ADMIN/CASES/CREATE_STATEMENT';
const CREATE_ATTACHMENT = 'ADMIN/CASES/CREATE_ATTACHMENT';
const UPDATE_SURVEY_ANSWERS = 'ADMIN/CASES/UPDATE_SURVEY_ANSWERS';
const ADD_REFERRALS = 'ADMIN/CASES/ADD_REFERRALS';
const DELETE_TEST_RESULT = 'ADMIN/PATIENTS/DELETE_TEST_RESULT';
const CLEAR_CURRENT = 'ADMIN/CASES/CLEAR_CURRENT';

const initialState = {
  fetching: false,
  data: [],
  current: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCHED_ALL:
      return {
        ...state,
        fetching: false,
        data: action.payload
      };
    case FETCHED_SINGLE:
      return {
        ...state,
        fetching: false,
        current: action.payload
      };
    case FETCHING:
      return {
        ...state,
        fetching: true,
      };
    case CLEAR_CURRENT:
      return {
        ...state,
        current: {}
      };
    case CREATE_CASE:
    case CREATE_STATEMENT:
    case CREATE_COMMENT:
    case UPDATE_COMMENT:
    case DELETE_COMMENT:
    case CREATE_ATTACHMENT:
    case ADD_REFERRALS:
    case UPDATE_SURVEY_ANSWERS:
    case DELETE_TEST_RESULT:
    default:
      return state;
  }
};

export const fetchAll = (showLoader = true) => (dispatch) => {
  if (showLoader) dispatch({ type: FETCHING });

  return axios.get(`${ADMIN_ENDPOINTS.CASES}`)
    .then((res) => dispatch({ type: FETCHED_ALL, payload: res.data }));
};

export const fetchSingle = (id, showLoader = true) => (dispatch) => {
  if (showLoader) dispatch({ type: FETCHING });

  return axios.get(`${ADMIN_ENDPOINTS.CASES}/${id}`)
    .then((res) => dispatch({ type: FETCHED_SINGLE, payload: res.data }));
};

export const createCase = (newCase) => (dispatch) =>
  axios.post(`${ADMIN_ENDPOINTS.CASES}`, newCase)
    .then(() => {
      dispatch({ type: CREATE_CASE });
      dispatch(fetchAll(false));
    });


const updateSurveyAnswers = (caseId, surveyId, surveyVersionId, updatedSurvey) => (dispatch) =>
  axios.put(`${ADMIN_ENDPOINTS.SURVEYS}/${surveyId}`, {
    answers: { ...updatedSurvey },
    versionId: surveyVersionId
  }).then(() => {
    dispatch({ type: UPDATE_SURVEY_ANSWERS });
    dispatch(fetchSingle(caseId, false));
  });
export const clearCurrent = () => (dispatch) => dispatch({ type: CLEAR_CURRENT });

export const createComment = (caseId, newComment) => (dispatch) =>
  axios.post(`${ADMIN_ENDPOINTS.CASES}/${caseId}/comments`, newComment).then(() => {
    dispatch({ type: CREATE_COMMENT });
    dispatch(fetchSingle(caseId, false));
  });

export const updateComment = (caseId, commentId, updatedComment) => (dispatch) =>
  axios.put(`${ADMIN_ENDPOINTS.CASES}/${caseId}/comments/${commentId}`, updatedComment).then(() => {
    dispatch({ type: UPDATE_COMMENT });
    dispatch(fetchSingle(caseId, false));
  });

export const deleteComment = (caseId, statementId) => (dispatch) =>
  axios.delete(`${ADMIN_ENDPOINTS.CASES}/${caseId}/comments/${statementId}`).then(() => {
    dispatch({ type: DELETE_COMMENT });
    dispatch(fetchSingle(caseId, false));
  });

export const createStatement = (caseId, newStatement) => (dispatch) =>
  axios.post(`${ADMIN_ENDPOINTS.CASES}/${caseId}/statements`, newStatement).then(() => {
    dispatch({ type: CREATE_STATEMENT });
    dispatch(fetchSingle(caseId, false));
  });

export const addReferrals = (caseId, newReferralIds) => (dispatch) =>
  axios.post(`${ADMIN_ENDPOINTS.CASES}/${caseId}/referrals`, newReferralIds).then(() => {
    dispatch({ type: ADD_REFERRALS });
    dispatch(fetchSingle(caseId, false));
  });

export const createAttachment = (id, newAttachment) => (dispatch) =>
  axios.post(`${ADMIN_ENDPOINTS.CASES}/${id}/attachment`, newAttachment,{
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
    .then(() => {
      dispatch({ type: CREATE_ATTACHMENT });
      dispatch(fetchSingle(id, false));
    });

export const deleteTestResult = (id, caseId) => (dispatch) =>
  axios.delete(`${ADMIN_ENDPOINTS.TESTS}/${id}`).then(() => {
    dispatch({ type: DELETE_TEST_RESULT });
    dispatch(fetchSingle(caseId, false));
  });

export const useCaseState = () => ({
  fetching: useSelector(({ admin: { cases: { fetching } } }) => fetching),
  cases: useSelector(({ admin: { cases: { data } } }) => data),
  current: useSelector(({ admin: { cases: { current } } }) => current),
});

export const useCaseDispatch = () => {
  const dispatch = useDispatch();
  return {
    fetchSingle: (id) => dispatch(fetchSingle(id)),
    fetchAll: () => dispatch(fetchAll()),
    clearCurrent: () => dispatch(clearCurrent()),
    createCase: (newCase) => dispatch(createCase(newCase)),
    updateSurveyAnswers: (caseId, surveyId, versionId, updatedSurvey) =>
      dispatch(updateSurveyAnswers(caseId, surveyId, versionId, updatedSurvey)),
    createComment: (caseId, newComment) => dispatch(createComment(caseId, newComment)),
    updateComment: (caseId, commentId, updatedComment) => dispatch(updateComment(caseId, commentId, updatedComment)),
    deleteComment: (caseId, statementId) => dispatch(deleteComment(caseId, statementId)),
    createStatement: (caseId, newStatement) => dispatch(createStatement(caseId, newStatement)),
    createAttachment: (id, newAttachment) => dispatch(createAttachment(id, newAttachment)),
    deleteTestResult: (id, caseId) => dispatch(deleteTestResult(id, caseId)),
    addReferrals: (id, newReferralIds) => dispatch(addReferrals(id, newReferralIds))
  };
};
