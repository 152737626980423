import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { ADMIN_ENDPOINTS } from 'utils/api';

const FETCHING = 'ADMIN/HEALTH-PACKAGES/FETCHING';
const FETCHED_ALL = 'ADMIN/HEALTH-PACKAGES/FETCHED_ALL';
const CREATE = 'ADMIN/HEALTH-PACKAGES/CREATE';
const UPDATE = 'ADMIN/HEALTH-PACKAGES/UPDATE';

const initialState = {
  fetching: false,
  fetched: false,
  data: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCHED_ALL:
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: action.payload
      };
    case FETCHING:
      return {
        ...state,
        fetching: true
      };
    case CREATE:
    case UPDATE:
    default:
      return state;
  }
};

export const fetchAll = (showLoader = true) => (dispatch) => {
  if (showLoader) dispatch({ type: FETCHING });

  return axios.get(`${ADMIN_ENDPOINTS.HEALTHPACKAGES}`)
    .then((res) => dispatch({ type: FETCHED_ALL, payload: res.data }));
};

export const createHealthPackage = (newHealthPackage) => (dispatch) =>
  axios.post(`${ADMIN_ENDPOINTS.HEALTHPACKAGES}`, newHealthPackage)
    .then(() => {
      dispatch({ type: CREATE });
      dispatch(fetchAll(false));
    });

export const updateHealthPackage = (healthPackageId, updatedHealthPackage) => (dispatch) =>
  axios.put(`${ADMIN_ENDPOINTS.HEALTHPACKAGES}/${healthPackageId}`, updatedHealthPackage)
    .then(() => {
      dispatch({ type: UPDATE });
      dispatch(fetchAll(false));
    });

export const useHealthPackageState = () => ({
  fetching: useSelector(({ admin: { healthPackages: { fetching } } }) => fetching),
  fetched: useSelector(({ admin: { healthPackages: { fetched } } }) => fetched),
  healthPackages: useSelector(({ admin: { healthPackages: { data } } }) => data),
});

export const useHealthPackageDispatch = () => {
  const dispatch = useDispatch();
  return {
    fetchAll: () => dispatch(fetchAll()),
    createHealthPackage: (newHealthPackage) => dispatch(createHealthPackage(newHealthPackage)),
    updateHealthPackage: (healthPackageId, updatedHealthPackage) =>
      dispatch(updateHealthPackage(healthPackageId, updatedHealthPackage)),
  };
};
