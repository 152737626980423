import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { TextField, makeStyles } from '@material-ui/core';
import { LaboratoryCreationSchema } from 'utils/validation';
import FormDialog from 'components/FormDialog';
import { useLaboratoryDispatch } from 'modules/admin/laboratories';

const useStyles = makeStyles(() => ({
  form: {
    '& > div': {
      marginBottom: 10
    },
    '& > button': {
      textAlign: 'right'
    }
  },
  formActions: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

const CreateLaboratory = ({ toggleOpen, open }) => {
  const classes = useStyles();
  const { createLaboratory } = useLaboratoryDispatch();
  return (
    <Formik
      initialValues={{
        name: '',
        code: '',
        orderCode: '',
      }}
      validationSchema={LaboratoryCreationSchema}
      onSubmit={(values, actions) => {
        createLaboratory(values).then(toggleOpen).catch(() => actions.setSubmitting(false));
      }}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
      }) => (
        <FormDialog
          title="Lägg till laboratorie"
          description="Fyll i informationen nedan för att skapa ett nytt laboratorie."
          open={open}
          toggle={toggleOpen}
          onSubmitDisabled={isSubmitting}
          onSubmit={handleSubmit}
        >
          <form onSubmit={handleSubmit} className={classes.form} autoComplete="off">
            <TextField
              variant="outlined"
              fullWidth
              name="name"
              label="Laboratoriets namn"
              type="text"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.name}
              error={touched.name && errors.name}
            />
            <TextField
              variant="outlined"
              fullWidth
              name="code"
              label="Laboratoriets kod (EDI)"
              type="text"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.code}
              error={touched.code && errors.code}
            />
            <TextField
              variant="outlined"
              fullWidth
              name="orderCode"
              label="Laboratoriets beställarkod"
              type="text"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.orderCode}
              error={touched.orderCode && errors.orderCode}
            />
          </form>
        </FormDialog>
      )}
    />
  );
};

CreateLaboratory.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired
};

export default CreateLaboratory;
