import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import account from './account';
import notifications from './notifications';

// Admin modules
import admins from './admin/admins';
import adminPatients from './admin/patients';
import doctors from './admin/doctors';
import adminCases from './admin/cases';
import adminTests from './admin/tests';
import laboratories from './admin/laboratories';
import healthPackages from './admin/healthPackages';
import referrals from './admin/referrals';
import adminSurveys from './admin/surveys';


// Doctor modules
import doctorPatients from './doctor/patients';
import doctorCases from './doctor/cases';
import doctorTests from './doctor/tests';
import doctorSurveys from './doctor/surveys';

// Patient modules
import patientCases from './patient/cases';
import messages from './patient/messages';
import statements from './patient/statements';
import patientSurveys from './patient/surveys';
import patientTests from './patient/tests';

export default (history) => combineReducers({
  admin: combineReducers({
    admins,
    patients: adminPatients,
    doctors,
    cases: adminCases,
    tests: adminTests,
    laboratories,
    healthPackages,
    referrals,
    surveys: adminSurveys,
  }),
  doctor: combineReducers({
    patients: doctorPatients,
    cases: doctorCases,
    tests: doctorTests,
    surveys: doctorSurveys,
  }),
  patient: combineReducers({
    cases: patientCases,
    messages,
    statements,
    surveys: patientSurveys,
    tests: patientTests,
  }),
  account,
  notifications,
  router: connectRouter(history)
});
