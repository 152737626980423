export const API_ROOT_ENDPOINT = '/api';

export const COMMON_ENDPOINTS = {
  ACCOUNT: `${API_ROOT_ENDPOINT}/account`,
  FILES: `${API_ROOT_ENDPOINT}/files`,
  NOTIFICATIONS: `${API_ROOT_ENDPOINT}/notifications`,
  BANKID: `${API_ROOT_ENDPOINT}/authenticate/bankid`
};

const ADMIN_ENDPOINT = `${API_ROOT_ENDPOINT}/admin`;
export const ADMIN_ENDPOINTS = {
  ADMINS: `${ADMIN_ENDPOINT}/admins`,
  CASES: `${ADMIN_ENDPOINT}/cases`,
  DOCTORS: `${ADMIN_ENDPOINT}/doctors`,
  PATIENTS: `${ADMIN_ENDPOINT}/patients`,
  TESTS: `${ADMIN_ENDPOINT}/tests`,
  LABORATORIES: `${ADMIN_ENDPOINT}/laboratories`,
  HEALTHPACKAGES: `${ADMIN_ENDPOINT}/health-packages`,
  FILEUPLOAD: `${ADMIN_ENDPOINT}/file-uploads`,
  REFERRALS: `${ADMIN_ENDPOINT}/referrals`,
  SURVEYS: `${ADMIN_ENDPOINT}/surveys`,
};

const DOCTOR_ENDPOINT = `${API_ROOT_ENDPOINT}/doctor`;
export const DOCTOR_ENDPOINTS = {
  CASES: `${DOCTOR_ENDPOINT}/cases`,
  PATIENTS: `${DOCTOR_ENDPOINT}/patients`,
  TESTS: `${DOCTOR_ENDPOINT}/tests`,
  SURVEYS: `${DOCTOR_ENDPOINT}/surveys`,
};

const PATIENT_ENDPOINT = `${API_ROOT_ENDPOINT}/patient`;
export const PATIENT_ENDPOINTS = {
  CASES: `${PATIENT_ENDPOINT}/cases`,
  MESSAGES: `${PATIENT_ENDPOINT}/messages`,
  STATEMENTS: `${PATIENT_ENDPOINT}/statements`,
  SURVEYS: `${PATIENT_ENDPOINT}/surveys`,
  TESTS: `${PATIENT_ENDPOINT}/tests`,
};
