import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { DOCTOR_ENDPOINTS } from 'utils/api';

const FETCHING = 'DOCTOR/SURVEYS/FETCHING';
const FETCHED_SINGLE = 'DOCTOR/SURVEYS/FETCHED_SINGLE';
const CLEAR_CURRENT = 'DOCTOR/SURVEYS/CLEAR_CURRENT';

const initialState = {
  fetching: true,
  current: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCHED_SINGLE:
      return {
        ...state,
        fetching: false,
        current: action.payload
      };
    case FETCHING:
      return {
        ...state,
        fetching: true,
      };
    case CLEAR_CURRENT:
      return {
        ...state,
        current: {}
      };
    default:
      return state;
  }
};


const fetchSingle = (id, showLoader = true) => (dispatch) => {
  if (showLoader) dispatch({ type: FETCHING });

  return axios.get(`${DOCTOR_ENDPOINTS.SURVEYS}/${id}`)
    .then((res) => dispatch({ type: FETCHED_SINGLE, payload: res.data }));
};

const clearCurrent = () => (dispatch) => dispatch({ type: CLEAR_CURRENT });

export const useSurveyState = () => ({
  fetching: useSelector(({ doctor: { surveys: { fetching } } }) => fetching),
  current: useSelector(({ doctor: { surveys: { current } } }) => current),
});

export const useSurveyDispatch = () => {
  const dispatch = useDispatch();
  return {
    fetchSingle: (id) => dispatch(fetchSingle(id)),
    clearCurrent: () => dispatch(clearCurrent()),
  };
};
