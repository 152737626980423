import * as Yup from 'yup';

export const LoginSchema = Yup.object().shape({
  personalNumber: Yup
    .string()
    .matches(/^[0-9]{12}$/, 'Must be exactly 12 digits')
});
export const DevLoginSchema = Yup.object().shape({
  personalNumber: Yup
    .string()
    .matches(/^[0-9]{12}$/, 'Must be exactly 12 digits'),
  password: Yup
    .string()
    .required('required')
});

export const UserCreationSchema = Yup.object().shape({
  personalNumber: Yup
    .string()
    .matches(/^[0-9]{12}$/, 'Must be exactly 12 digits')
    .required('Required'),
  firstName: Yup
    .string()
    .required('Required.'),
  lastName: Yup
    .string()
    .required('Required.'),
  email: Yup
    .string()
    .email()
    .required('Required.'),
  password: Yup
    .string()
    .required('Required.')
});

export const PatientCreationSchema = Yup.object().shape({
  personalNumber: Yup.string().matches(/^[0-9]{12}$/, 'Det måste vara exakt 12 siffror enligt (ÅÅÅÅMMDDXXXX)'),
  firstName: Yup.string().required('Required.'),
  lastName: Yup.string().required('Required.'),
  address: Yup.string(),
  postCode: Yup.string(),
  city: Yup.string(),
  country: Yup.string(),
  phoneNumber: Yup.string(),
  gender: Yup.string(),
  email: Yup.string().email().required('Required.'),
  password: Yup.string(),
  phoneWork: Yup.string(),
  phoneHome: Yup.string(),
  length: Yup.string(),
  weight: Yup.string()
});

export const CaseCreationSchema = Yup.object().shape({
  healthPackageId: Yup
    .number()
    .required('Required.'),
  patientId: Yup
    .number()
    .required('Required.')
});

export const TestResultCreationSchema = Yup.object().shape({
  title: Yup
    .string(),
  requisitionId: Yup
    .string()
});

export const CommentCreationSchema = Yup.object().shape({
  content: Yup
    .string()
    .required('Required'),
  doctorHasPermission: Yup
    .bool()
});

export const StatementCreationSchema = Yup.object().shape({
  content: Yup
    .string()
    .required('Required'),
});

export const MessageCreationSchema = Yup.object().shape({
  content: Yup
    .string()
    .required('Required'),
});

export const LaboratoryCreationSchema = Yup.object().shape({
  name: Yup
    .string()
    .required('Required'),
  code: Yup
    .string()
    .required('Required'),
  orderCode: Yup
    .string()
    .required('Required'),
});

export const HealthPackageCreationSchema = Yup.object().shape({
  name: Yup
    .string()
    .required('Required'),
  woocommerceProductId: Yup
    .number()
    .required('Required'),
});

export const ReferralCreationSchema = Yup.object().shape({
  name: Yup
    .string()
    .required('Required'),
  laboratoryId: Yup
    .number()
    .required('Required'),
});

export const AttachmentCreationSchema = Yup.object().shape({
  description: Yup
    .string(),
  title: Yup
    .string()
    .required('Required')
});

export const ReferralsAddedToCaseSchema = Yup.object().shape({
  referralIds: Yup
    .string()
    .required('Required.'),
});

export const PatientUpdateSchema = Yup.object().shape({
  firstName: Yup.string(),
  lastName: Yup.string(),
  personalNumber: Yup.string().matches(/^[0-9]{12}$/, 'Must be exactly 12 digits'),
  address: Yup.string(),
  postCode: Yup.string(),
  city: Yup.string(),
  country: Yup.string(),
  phoneNumber: Yup.string(),
  phoneWork: Yup.string(),
  phoneHome: Yup.string(),
  length: Yup.string(),
  weight: Yup.string()
});
