import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    minHeight: '100vh',
    backgroundColor: 'rgba(248,248,248,1)'
  },
  paper: {
    padding: 0,
    maxWidth: 'calc(100vw - 272px)',
    boxShadow: 'none',
    backgroundColor: 'unset',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'calc(100vw - 16px)',
    },
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 1),
    },
    padding: theme.spacing(5, 2),
  },

  // Global styles below
  '@global': {
    body: {
      backgroundColor: '#FFFFFF',
    },
    a: {
      color: '#0480C3',
      textDecoration: 'none',
      textDecorationColor: 'none',
      '&: -webkit-any-link': {
        color: 'red'
      }
    },
    '.elevated-container': {
      boxShadow: theme.shadows[2],
      backgroundColor: 'white',
      borderRadius: 3,
      padding: '10px 0',
      marginBottom: 20
    }
  }
}));
