import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { COMMON_ENDPOINTS } from 'utils/api';

const LOGIN = 'ACCOUNT/LOGIN';
const LOGOUT = 'ACCOUNT/LOGOUT';
const AUTH = 'ACCOUNT/AUTH';
const REMOVE_AUTH = 'ACCOUNT/REMOVE_AUTH';
const CREATE_LABPORTALEN_ACCOUNT = 'ACCOUNT/CREATE_LABPORTALEN_ACCOUNT';
const UPDATE_PATIENT = 'ACCOUNT/UPDATE_PATIENT';

const initialState = {
  data: {},
  authenticated: null,
  role: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        authenticated: true,
        role: action.payload.role,
        data: action.payload
      };
    case UPDATE_PATIENT:
    case AUTH:
      return {
        ...state,
        authenticated: true,
        role: action.payload.role,
        data: action.payload
      };
    case LOGOUT:
    case REMOVE_AUTH:
      return {
        ...state,
        authenticated: false,
        role: '',
        data: {}
      };
    default:
      return state;
  }
};

export const devLogin = (userName, password) => (dispatch) =>
  axios.post(`${COMMON_ENDPOINTS.ACCOUNT}/login`, {
    userName,
    password
  }).then((res) => dispatch({ type: LOGIN, payload: res.data }));

export const authenticate = () => (dispatch) =>
  axios.get(`${COMMON_ENDPOINTS.ACCOUNT}/authenticate`).then((res) => {
    dispatch({
      type: AUTH,
      payload: res.data
    });
  });

export const removeAuth = () => (dispatch) => {
  dispatch({ type: REMOVE_AUTH });
};

export const logout = () => (dispatch) =>
  axios.post(`${COMMON_ENDPOINTS.ACCOUNT}/logout`).then(() => dispatch({ type: LOGOUT }));

export const createLabportalenAccount = (id) => (dispatch) =>
  axios.post(`${COMMON_ENDPOINTS.ACCOUNT}/create-labportalen-account/${id}`).then(() => dispatch({ type: CREATE_LABPORTALEN_ACCOUNT }));

export const updateAccountAsPatient = (patientId, updatedPatient) => (dispatch) =>
  axios.put(`${COMMON_ENDPOINTS.ACCOUNT}/patient/${patientId}`, updatedPatient)
    .then((res) => {
      dispatch({ type: UPDATE_PATIENT, payload: res.data });
    });

export const useAccountState = () => ({
  user: useSelector(({ account: { data } }) => data),
  authenticated: useSelector(({ account: { authenticated } }) => authenticated),
  role: useSelector(({ account: { role } }) => role),
});

export const useAccountDispatch = () => {
  const dispatch = useDispatch();
  return {
    devLogin: (userName, password) => dispatch(devLogin(userName, password)),
    authenticate: () => dispatch(authenticate()),
    removeAuth: () => dispatch(removeAuth()),
    logout: () => dispatch(logout()),
    createLabportalenAccount: (id) => dispatch(createLabportalenAccount(id)),
    updateAccountAsPatient: (patientId, updatedPatient) => dispatch(updateAccountAsPatient(patientId, updatedPatient))
  };
};
