import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { TextField, makeStyles } from '@material-ui/core';

import FormDialog from 'components/FormDialog';

import { useTestDispatch } from 'modules/admin/tests';

import { TestResultCreationSchema } from 'utils/validation';

const useStyles = makeStyles(() => ({
  form: {
    '& > div': {
      marginBottom: 10
    }
  },
}));

const CreateLabportalenTestResult = ({
  toggleOpen, open, type, modelId, updateView
}) => {
  const classes = useStyles();
  const { createPatientLabportalenTestResult, createCaseLabportalenTestResult } = useTestDispatch();

  return (
    <Formik
      initialValues={{
        title: '',
        requisitionId: ''
      }}
      validationSchema={TestResultCreationSchema}
      onSubmit={(values, actions) => {
        console.log('test', modelId);
        if (type === 'patient') {
          console.log('patient')
          createPatientLabportalenTestResult({
            ...values,
            modelId
          }).then(() => {
            toggleOpen();
            updateView();
            actions.resetForm();
          }).catch(() => actions.setSubmitting(false));
        } else if (type === 'case') {
          console.log('case')
          createCaseLabportalenTestResult({
            ...values,
            modelId
          }).then(() => {
            toggleOpen();
            updateView();
            actions.resetForm();
          }).catch(() => actions.setSubmitting(false));
        }
      }}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        resetForm
      }) => (
        <FormDialog
          title="Länka provresultat från labportalen"
          description="Fyll i remiss id (rid) från ett svar i labportalen. Dubbelkolla att provresultatet tillhör klienten du är inne och länkar det till. Denna funktionalitet används endast om ni vill länka ett redan befintligt svar från labportalen"
          open={open}
          toggle={() => {
            toggleOpen();
            resetForm();
          }}
          onSubmitDisabled={isSubmitting}
          onSubmit={handleSubmit}
        >
          <form onSubmit={handleSubmit} className={classes.form}>
            <TextField
              variant="outlined"
              fullWidth
              name="title"
              label="Titel"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.title}
              error={touched.title && errors.title}
            />
            <TextField
              variant="outlined"
              fullWidth
              required
              name="requisitionId"
              label="Remiss id (rid)"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.requisitionId}
              error={touched.requisitionId && errors.requisitionId}
            />
          </form>
        </FormDialog>
      )}
    />
  );
};

CreateLabportalenTestResult.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['patient', 'case']),
  modelId: PropTypes.string.isRequired,
  updateView: PropTypes.func.isRequired,
};

export default CreateLabportalenTestResult;
