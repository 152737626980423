import React from 'react';
import PropTypes from 'prop-types';
import { Polar } from 'react-chartjs-2';

const options = {
  legend: {
    display: false
  },
  scale: {
    display: false
  },
  scales: {
    xAxes: [
      {
        ticks: {
          display: false
        },
        gridLines: {
          lineWidth: 0,
          circular: false,
          display: false
        }
      }
    ],
    yAxes: [
      {
        ticks: {
          display: false
        },
        gridLines: {
          lineWidth: 0,
          circular: false,
          display: false
        }
      }
    ]
  },
  tooltips: {
    callbacks: {
      label(tooltipItem, data) {
        return data.labels[tooltipItem.index];
      }
    }
  }
};

const PolarChart = ({ data, labels, colors }) => (
    <Polar
      data={{
        datasets: [{
          data,
          backgroundColor: colors,
        }],
        labels
      }}
      options={options}
    />
);

PolarChart.propTypes = {
  data: PropTypes.array.isRequired,
  labels: PropTypes.array.isRequired,
  colors: PropTypes.array,
};

PolarChart.defaultProps = {
  colors: [
    '#FF6384',
    '#4BC0C0',
    '#FFCE56',
    '#E7E9ED',
    '#36A2EB'
  ]
};

export default PolarChart;
