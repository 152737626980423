import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  LinearProgress, Table, TableBody, TableCell, TableHead, TableRow
} from '@material-ui/core';

import { useTestState, useTestDispatch } from 'modules/patient/tests';

const Index = () => {
  const { fetchAll } = useTestDispatch();
  const { tests, fetching } = useTestState();

  useEffect(() => {
    fetchAll();
  }, []);
  return (
    <div className="elevated-container">
    {fetching
      ? <LinearProgress />
      : tests.length === 0
        ? 'Hittade inte provresultat'
        :
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Provtyp</TableCell>
                <TableCell>Titel</TableCell>
                <TableCell>Datum</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tests.map((test) => (
                <TableRow key={test.id} component={Link} to={`/patient/tests/${test.id}`}>
                  <TableCell component="th" scope="row">
                    {test.type === 'ManualUpload' ? 'Manuellt uppladdat test' : test.type === 'LabportalenTest' ? 'Prov från labportalen' : ''}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {test.title !== '' ? test.title : ''}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {test.createdAt.split('T')[0]}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
    }
    </div>
  );
};

export default Index;
