import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Button, TextField, Menu, MenuItem, makeStyles, LinearProgress, Grid, List, ListItem, ListItemSecondaryAction, IconButton, ListItemText
} from '@material-ui/core';
import {Add as AddIcon, Clear as CrossIcon, Done as DoneIcon} from '@material-ui/icons';

import { HealthPackageCreationSchema } from 'utils/validation';
import { useHealthPackageDispatch } from 'modules/admin/healthPackages';
import { useReferralDispatch, useReferralState } from 'modules/admin/referrals';
import FormDialog from 'components/FormDialog';

const useStyles = makeStyles((theme) => ({
  form: {
    '& > div': {
      marginBottom: 10
    },
    '& > button': {
      textAlign: 'right'
    }
  },
  crossIcon: {
    fill: theme.palette.error.main,
  },
  doneIcon: {
    fill: theme.palette.success.main,
  },
  verificationIcon: {
    backgroundColor: 'transparent !important',
    cursor: 'default'
  }
}));

const CreateHealthPackage = ({
  open, toggleOpen, edit, editPackage, clearEditPackage
}) => {
  const classes = useStyles();
  const [packageReferrals, setPackageReferrals] = useState([]);
  const { fetchAll } = useReferralDispatch();
  const { fetching, referrals } = useReferralState();
  const { createHealthPackage, updateHealthPackage } = useHealthPackageDispatch();

  useEffect(() => {
    fetchAll();
  }, []);

  useEffect(() => {
    if (referrals.length > 0 && edit) {
      setPackageReferrals(editPackage.referrals);
    }
  }, [referrals, editPackage, edit]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClickLabSelect = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseLabSelect = () => {
    setAnchorEl(null);
  };

  const handleAddReferral = (referral) => {
    setPackageReferrals([...packageReferrals, referral]);
    handleCloseLabSelect();
  };

  const handleRemoveReferral = (referralArrayIndex) => {
    let newPackageReferrals = [...packageReferrals];
    newPackageReferrals.splice(referralArrayIndex, 1);
    setPackageReferrals(newPackageReferrals);
  };

  return (
    <Formik
      key={edit ? `healthpackagemodal-${editPackage.id}` : 'healthpackagemodal'}
      initialValues={{
        name: edit ? editPackage.name : '',
        woocommerceProductId: edit ? editPackage.woocommerceProductId : '',
      }}
      validationSchema={HealthPackageCreationSchema}
      onSubmit={(values, actions) => {
        if (edit) {
          const updatedHealthPackage = {
            ...values,
            referralIds: packageReferrals.map((referral) => referral.id)
          };
          updateHealthPackage(editPackage.id, updatedHealthPackage).then(() => {
            toggleOpen();
            packageReferrals([]);
            clearEditPackage();
            actions.resetForm();
          }).catch(() => actions.setSubmitting(false));
        } else {
          const newHealthPackage = {
            ...values,
            referralIds: packageReferrals.map((referral) => referral.id)
          };
          createHealthPackage(newHealthPackage).then(() => {
            toggleOpen();
            actions.resetForm();
          }).catch(() => actions.setSubmitting(false));
        }
      }}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        resetForm,
      }) => (
        <FormDialog
          title={edit
            ? 'Uppdatera hälsopaketet'
            : 'Lägg till ett hälsopaket'
          }
          description={edit
            ? 'Ändra informationen nedan för att uppdatera hälsopaket.'
            : 'Fyll i informationen nedan för att skapa ett nytt hälsopaket.'
          }
          open={open}
          toggle={() => {
            resetForm();
            clearEditPackage();
            setPackageReferrals([]);
            toggleOpen();
          }}
          onSubmitDisabled={isSubmitting}
          onSubmit={handleSubmit}
        >
          <form onSubmit={handleSubmit} className={classes.form} autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  required
                  name="name"
                  label="Hälsopaketets namn"
                  type="text"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  error={touched.name && errors.name}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  name="woocommerceProductId"
                  label="Woocommerce produkt ID"
                  type="number"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.woocommerceProductId}
                  error={touched.woocommerceProductId && errors.woocommerceProductId}
                />
              </Grid>
              <Grid item xs={12}>
                {fetching ?
                  <LinearProgress/>
                  : referrals.length === 0 ?
                    'Hittade inga remisser.'
                    :
                    <React.Fragment>
                      <Button variant="outlined" onClick={handleClickLabSelect}>
                        <AddIcon/>
                        Lägg till en remiss
                      </Button>
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleCloseLabSelect}
                      >
                        {referrals.map((referral) =>
                          !packageReferrals.find((r) => r.id === referral.id) &&
                          <MenuItem
                            key={referral.id}
                            onClick={() => handleAddReferral(referral)}
                          >
                            {`${referral.name} (${referral.laboratory.name})`}
                          </MenuItem>)
                        }
                      </Menu>
                    </React.Fragment>
                }
              </Grid>
              <Grid item xs={12}>
                {packageReferrals.length > 0 &&
                  <List subheader="Remisser">
                    {packageReferrals.map((referral, i) => (
                      <ListItem divider>
                        <ListItemText>
                          {`${referral.name} (${referral.laboratory.name})`}                      
                        </ListItemText>
                        <ListItemSecondaryAction>
                          <IconButton disableRipple className={classes.verificationIcon}>
                          {referral.needsVerification
                            ? <CrossIcon className={classes.crossIcon}/>
                            : <DoneIcon className={classes.doneIcon}/>
                          }
                          </IconButton>
                          <IconButton onClick={() => handleRemoveReferral(i)}>
                            <CrossIcon/>
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}  
                  </List>
                }
              </Grid>
            </Grid>
          </form>
        </FormDialog>
      )}
    />
  );
};

CreateHealthPackage.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  edit: PropTypes.bool,
  editPackage: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  clearEditPackage: PropTypes.func,
};

export default CreateHealthPackage;
