import React from 'react';
import Attachments from 'components/Attachments';
import { useAccountState } from 'modules/account';
import { LinearProgress } from '@material-ui/core';

const PatientAttachments = () => {
  const {
    user: {
      patientAttachments
    }
  } = useAccountState();

  if (!patientAttachments) {
    return <LinearProgress />;
  }

  return (
    <Attachments
      attachments={patientAttachments}
    />
  );
};

export default PatientAttachments;
