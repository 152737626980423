import axios from 'axios';
import { removeAuth } from 'modules/account';

const httpService = {
  setupInterceptors: (store, history) => {
    axios.interceptors.response.use((response) =>
      // Return response if no errors occurred
      response,
    (error) => {
      if (error.response.status === 401) {
        // Remove authentication status in redux
        store.dispatch(removeAuth());

        // Redirect user to login screen
        const { pathname, search } = history.location;

        let queryParam = search;
        if (search.includes('notification')) {
          queryParam = `?return=${pathname}${search}`;
        } else if (search === '' && !pathname.includes('login')) {
          queryParam = `?return=${pathname}`;
        }
        if (pathname.includes('dev')) {
          history.push(`/dev/login${queryParam}`);
        } else if (pathname.includes('admin')) {
          history.push(`/admin/login${queryParam}`);
        } else if (pathname.includes('doctor')) {
          history.push(`/doctor/login${queryParam}`);
        } else {
          history.push(`/login${queryParam}`);
        }
      }
      return Promise.reject(error);
    });
  }
};

export default httpService;
