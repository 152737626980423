import React from 'react';
import {
  ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, makeStyles, Typography
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  expansionDetails: {
    flexDirection: 'column',
    height: '70vh',
  },
  description: {
    whiteSpace: 'pre-line',
    wordWrap: 'break-word'
  }
}));
const AttachmentsList = ({ attachments }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      {attachments.length === 0 && <Typography variant="body1" gutterBottom>Det finns inga uppladdade bilagor.</Typography>}
      {attachments.map((attachment, i) => (
        <ExpansionPanel square defaultExpanded={i === 0} key={attachment.id}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography variant="subtitle1">{`Bilaga #${i + 1}${attachment.title ? ` - ${attachment.title}` : ''}`}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.expansionDetails}>
            {attachment.description !== '' &&
            <React.Fragment>
              {attachment.description !== '' &&
                <React.Fragment>
                  <Typography variant="subtitle2">Beskrivning:</Typography>
                  <Typography
                    variant="body1"
                    gutterBottom
                    className={classes.description}
                  >
                    {attachment.description}
                  </Typography>
                </React.Fragment>
              }
            </React.Fragment>}
            {attachment.file !== null && <object data={attachment.file.url} width="100%" height="100%"/>}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ))}
    </React.Fragment>
  );
};

AttachmentsList.propTypes = {
  attachments: PropTypes.array.isRequired,
};

export default AttachmentsList;
