import { createMuiTheme } from '@material-ui/core/styles';

import palette from './palette';
import typography from './typography';
import overrides from './overrides';

export default createMuiTheme({
  palette,
  typography,
  overrides,
  shape: {
    borderRadius: 3,
  },
});
