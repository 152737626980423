import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DescriptionIcon from '@material-ui/icons/Description';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AttachmentIcon from '@material-ui/icons/Attachment';
import MessageIcon from '@material-ui/icons/Message';
import NotificationIcon from '@material-ui/icons/Notifications';
import TestResultIcon from '@material-ui/icons/AssignmentTurnedIn';
import SettingsIcon from '@material-ui/icons/Settings';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Badge from '@material-ui/core/Badge';

import { useAccountState } from 'modules/account';
import { useNotificationState } from 'modules/notifications';

const PatientMenu = ({ toggle, isMobile, pathname }) => {
  const {
    user:
      {
        unreadNotifications: userUnreadNotifications,
        unansweredSurveys
      }
  } = useAccountState();
  const { unreadNotifications } = useNotificationState();

  return (
    <React.Fragment>
      <Divider/>
      <List>
        <ListItem
          button component={Link}
          to="/patient/cases"
          selected={pathname.includes('/patient/cases')}
          onClick={() => isMobile && toggle()}
        >
          <ListItemIcon><AssignmentIcon/></ListItemIcon>
          <ListItemText primary="Ärenden"/>
        </ListItem>
        <ListItem
          button component={Link}
          to="/patient/statements"
          selected={pathname.includes('/patient/statements')}
          onClick={() => isMobile && toggle()}
        >
          <ListItemIcon><DescriptionIcon/></ListItemIcon>
          <ListItemText primary="Utlåtanden"/>
        </ListItem>
        <ListItem
          button component={Link}
          to="/patient/attachments"
          selected={pathname.includes('/patient/attachments')}
          onClick={() => isMobile && toggle()}
        >
          <ListItemIcon><AttachmentIcon/></ListItemIcon>
          <ListItemText primary="Bilagor"/>
        </ListItem>
        <ListItem
          button component={Link}
          to="/patient/surveys"
          selected={pathname.includes('/patient/surveys')}
          onClick={() => isMobile && toggle()}
        >
          <ListItemIcon>
            <Badge badgeContent={unansweredSurveys} color="error">
              <DescriptionIcon/>
            </Badge>
          </ListItemIcon>
          <ListItemText primary="Hälsoenkäter"/>
        </ListItem>
        <ListItem
          button component={Link}
          to="/patient/tests"
          selected={pathname.includes('/patient/tests')}
          onClick={() => isMobile && toggle()}
        >
          <ListItemIcon><TestResultIcon/></ListItemIcon>
          <ListItemText primary="Provresultat"/>
        </ListItem>
        <ListItem
          button component={Link}
          to="/patient/messages"
          selected={pathname.includes('/patient/messages')}
          onClick={() => isMobile && toggle()}
        >
          <ListItemIcon><MessageIcon/></ListItemIcon>
          <ListItemText primary="Meddelanden"/>
        </ListItem>
        <ListItem
          button component={Link}
          to="/patient/notifications"
          selected={pathname.includes('/patient/notifications')}
          onClick={() => isMobile && toggle()}
        >
          <ListItemIcon>
            <Badge badgeContent={unreadNotifications !== null ? unreadNotifications : userUnreadNotifications} color="error">
              <NotificationIcon />
            </Badge>
          </ListItemIcon>
          <ListItemText primary="Notifikationer"/>
        </ListItem>
        <ListItem
          button component={Link}
          to="/patient/settings"
          selected={pathname.includes('/patient/settings')}
          onClick={() => isMobile && toggle()}
        >
          <ListItemIcon><SettingsIcon/></ListItemIcon>
          <ListItemText primary="Inställningar"/>
        </ListItem>
      </List>
    </React.Fragment>
  );
};

PatientMenu.propTypes = {
  toggle: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  pathname: PropTypes.string.isRequired,
};

export default PatientMenu;
