import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Button, TextField, makeStyles
} from '@material-ui/core';

import FormDialog from 'components/FormDialog';
import { AttachmentCreationSchema } from 'utils/validation';
import AttachmentsList from 'components/AttachmentsList';
import useFileUpload from 'hooks/useFileUpload';
import useToggle from 'hooks/useToggle';

const useStyles = makeStyles(() => ({
  root: {
    padding: 10
  },
  form: {
    '& > div': {
      marginBottom: 10
    },
    '& > button': {
      textAlign: 'right'
    }
  },
  input: {
    display: 'none',
  },
  expansionDetails: {
    flexDirection: 'column'
  },
  uploadButton: {
    marginBottom: 10
  },
}));

const Attachments = ({ attachments, create, modelId }) => {
  const classes = useStyles();
  const [showModal, toggleShowModal] = useToggle();
  const { file, clearFile, renderFileUploader } = useFileUpload(['pdf', 'image']);

  return (
    <div className={classes.root}>
      <Formik
          initialValues={{
            title: '',
            description: ''
          }}
          onSubmit={(values, actions) => {
            if (!modelId || !create) return;
            const formData = new FormData();
            formData.append('file', file);
            formData.append('title', values.title);
            formData.append('description', values.description);
            create(modelId, formData).then(() => {
              actions.resetForm();
              clearFile();
              toggleShowModal();
            }).catch(() => actions.setSubmitting(false));
          }}
          validationSchema={AttachmentCreationSchema}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            resetForm
          }) => (
            <FormDialog
              title="Ladda upp bilaga"
              description="Fyll i informationen nedan och tryck sedan på spara för att ladda upp en ny bilaga."
              open={showModal}
              toggle={() => {
                resetForm();
                clearFile();
                toggleShowModal();
              }}
              onSubmitDisabled={isSubmitting}
              onSubmit={handleSubmit}
            >
              <form onSubmit={handleSubmit} className={classes.form}>
                <TextField
                  variant="outlined"
                  style={{ width: '100%' }}
                  name="title"
                  label="Titel"
                  required
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.title}
                  error={touched.title && errors.title}
                />
                <TextField
                  variant="outlined"
                  style={{ width: '100%' }}
                  multiline={true}
                  rows={6}
                  rowsMax={10}
                  name="description"
                  label="Beskrivning"
                  value={values.description}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={errors.description && touched.description}
                />
                { renderFileUploader() }
              </form>
            </FormDialog>
          )}
        />
      {modelId && create && (
        <Button className={classes.uploadButton} variant="contained" color="primary" onClick={() => toggleShowModal()}>
          Ladda upp bilaga
        </Button>
      )}
      <AttachmentsList attachments={attachments} />
    </div>
  );
};

Attachments.propTypes = {
  attachments: PropTypes.array.isRequired,
};

export default Attachments;
