import React, { useEffect, useState } from 'react';

import { useHealthPackageDispatch, useHealthPackageState } from 'modules/admin/healthPackages';
import {
  IconButton, LinearProgress, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Box, makeStyles
} from '@material-ui/core';
import {
  Clear as CrossIcon,
  Done as DoneIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@material-ui/icons';

import ButtonBar from 'components/ButtonBar';

import useToggle from 'hooks/useToggle';
import HealthPackageModal from './healthPackages/HealthPackageModal';

const useStyles = makeStyles((theme) => ({
  crossIcon: {
    fill: theme.palette.error.main,
  },
  doneIcon: {
    fill: theme.palette.success.main,
  }
}));

const HealthPackages = () => {
  const classes = useStyles();
  const [newHealthPackageOpen, toggleNewHealthPackageOpen] = useToggle(false);
  const [editPackage, setEditPackage] = useState(false);
  const { fetching, fetched, healthPackages } = useHealthPackageState();
  const { fetchAll } = useHealthPackageDispatch();

  useEffect(() => {
    if (!fetched) fetchAll();
  }, []);

  const handlePackageEdit = (hp) => {
    setEditPackage(hp);
    toggleNewHealthPackageOpen();
  };

  const clearEditPackage = () => setEditPackage(false);

  const buttons = [{
    name: 'Lägg till hälsopaket',
    icon: 'add',
    onClickEvent: 'func',
    onClick: toggleNewHealthPackageOpen
  }, {
    icon: 'refresh',
    onClickEvent: 'func',
    onClick: fetchAll
  }];

  return (
    <div className="elevated-container">
    {fetching
      ? <LinearProgress/>
      :
      <React.Fragment>
        <Box ml="10px">
          <ButtonBar buttons={buttons}/>
        </Box>
        <HealthPackageModal
          toggleOpen={toggleNewHealthPackageOpen}
          open={newHealthPackageOpen}
          edit={editPackage !== false}
          editPackage={editPackage}
          clearEditPackage={clearEditPackage}
        />
        {healthPackages.length === 0 ?
          'Hittade inga hälsopaket'
          :
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Namn</TableCell>
                <TableCell>Product ID</TableCell>
                <TableCell>Verifierad</TableCell>
                <TableCell/>
              </TableRow>
            </TableHead>
            <TableBody>
              {healthPackages.map((hp) => (
                <TableRow key={hp.id}>
                  <TableCell component="th" scope="row">
                    {hp.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {hp.woocommerceProductId}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {hp.referralNeedsVerification
                      ? <CrossIcon className={classes.crossIcon}/>
                      : <DoneIcon className={classes.doneIcon}/>
                    }
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Redigera hälsopaket">
                      <IconButton onClick={() => handlePackageEdit(hp)}>
                        <EditIcon color="primary"/>
                      </IconButton>
                    </Tooltip>
                    {/*<Tooltip title="Ta bort hälsopaket (TBI)">
                      <IconButton>
                        <DeleteIcon color="error"/>
                      </IconButton>
                    </Tooltip>*/}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        }
      </React.Fragment>
    }
    </div>
  );
};

export default HealthPackages;
