import React from 'react';
import PropTypes from 'prop-types';
import {
  IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ArrowForward as ArrowForwardIcon } from '@material-ui/icons';

import useToggle from 'hooks/useToggle';
import ButtonBar from 'components/ButtonBar';
import CreateCaseForm from './CreateCaseForm';

const Cases = ({
  cases, role, patientId, fetchPatient
}) => {
  const [open, toggleOpen] = useToggle();

  const buttons = [{
    name: 'Lägg till ärende',
    icon: 'add',
    onClickEvent: 'func',
    onClick: toggleOpen
  }];

  return (
    <React.Fragment>
      {role === 'admin' &&
      <React.Fragment>
        <ButtonBar buttons={buttons}/>
        <CreateCaseForm
          toggleOpen={toggleOpen}
          open={open}
          patientId={patientId}
          fetchPatient={fetchPatient}
        />
      </React.Fragment>
      }
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Ärende-ID</TableCell>
            <TableCell>Hälsopaket</TableCell>
            <TableCell/>
          </TableRow>
        </TableHead>
        <TableBody>
          {cases.map((c) => (
            <TableRow key={c.id}>
              <TableCell component="th" scope="row">
                Ärende #{c.id}
              </TableCell>
              <TableCell component="th" scope="row">
                {c.healthPackageName}
              </TableCell>
              <TableCell>
                <Tooltip title="Öppna ärende">
                  <IconButton
                    component={Link}
                    to={`/${role}/cases/${c.id}/tests`}
                  >
                    <ArrowForwardIcon color="primary"/>
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
};

Cases.propTypes = {
  cases: PropTypes.array.isRequired,
  role: PropTypes.string.isRequired,
  patientId: PropTypes.number.isRequired,
  fetchPatient: PropTypes.func.isRequired,
};

export default Cases;
