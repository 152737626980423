export default {
  fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
  h1: {
    fontSize: 48,
    marginBottom: 20,
    fontWeight: 'bold',
  },
  h2: {
    fontSize: 24,
    marginBottom: 20,
    lineHeight: '38px',
    fontWeight: 'bold',
  },
  h3: {
    fontSize: 19,
    fontWeight: 'bold',
    lineHeight: '23px'
  },
  body1: {
    fontSize: 12
  }
};
