import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  LinearProgress, Table, TableBody, TableCell, TableHead, TableRow
} from '@material-ui/core';

import { useCaseState, useCaseDispatch } from 'modules/patient/cases';

const Index = () => {
  const { fetchAll } = useCaseDispatch();
  const { cases, fetching } = useCaseState();

  useEffect(() => {
    fetchAll();
  }, []);
  return (
    <div className="elevated-container">
    {fetching
      ? <LinearProgress />
      : cases.length === 0
        ? 'Hittade inte ärenden'
        :
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Hälsopaket</TableCell>
              <TableCell>Datum</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cases.map((c) => (
              <TableRow key={c.id} component={Link} to={`/patient/cases/${c.id}/tests`}>
                <TableCell component="th" scope="row">
                  {c.healthPackageName ? c.healthPackageName : ''}
                </TableCell>
                <TableCell component="th" scope="row">
                  {c.createdAt.split('T')[0]}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
    }
    </div>
  );
};

export default Index;
