import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { TextField, makeStyles } from '@material-ui/core';

import { TestResultCreationSchema } from 'utils/validation';
import FormDialog from 'components/FormDialog';
import useFileUpload from 'hooks/useFileUpload';

import { useTestDispatch } from 'modules/admin/tests';

const useStyles = makeStyles(() => ({
  form: {
    '& > div': {
      marginBottom: 10
    }
  },
}));

const UploadTestResultForm = ({
  toggleOpen, open, type, modelId, updateView
}) => {
  const classes = useStyles();
  const { file, clearFile, renderFileUploader } = useFileUpload(['pdf', 'image']);
  const { createCaseTestResult, createPatientTestResult } = useTestDispatch();

  return (
      <Formik
        initialValues={{
          title: ''
        }}
        validationSchema={TestResultCreationSchema}
        onSubmit={(values, actions) => {
          if (file !== null) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('modelId', modelId);
            formData.append('title', values.title);
            if (type === 'patient') {
              createPatientTestResult(formData).then(() => {
                toggleOpen();
                updateView();
                actions.resetForm();
              }).catch(() => actions.setSubmitting(false));
            } else if (type === 'case') {
              createCaseTestResult(formData).then(() => {
                toggleOpen();
                updateView();
                actions.resetForm();
              }).catch(() => actions.setSubmitting(false));
            }
          } else {
            actions.setSubmitting(false);
          }
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          resetForm
        }) => (
          <FormDialog
            title="Skapa nytt provresultat"
            description="Ladda upp en fil för att skapa ett nytt provresultat."
            open={open}
            toggle={() => {
              toggleOpen();
              clearFile();
              resetForm();
            }}
            onSubmitDisabled={isSubmitting}
            onSubmit={handleSubmit}
          >
            <form onSubmit={handleSubmit} className={classes.form}>
              <TextField
                variant="outlined"
                fullWidth
                name="title"
                label="Titel"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.title}
                error={touched.title && errors.title}
              />
              {renderFileUploader()}
            </form>
          </FormDialog>
        )}
      />
  );
};

UploadTestResultForm.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['patient', 'case']),
  modelId: PropTypes.string.isRequired,
  updateView: PropTypes.func.isRequired,
};

export default UploadTestResultForm;
