import React from 'react';
import {
  Avatar, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, makeStyles, Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {DoctorType} from "../../../../utils/enums";
import {ExpandMore as ExpandMoreIcon} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0 10px',
  },
  message: {
    padding: theme.spacing(3, 0),
    width: '100%',
  },
  content: {
    whiteSpace: 'pre-line',
    wordWrap: 'break-word'
  },
  contentWrapper: {
    borderBottom: '1px solid #C0C0C0',
    paddingBottom: 20,
  },
  messageHeader: {
    fontSize: 20,
    marginBottom: 10,
    fontWeight: '#C0C0C0'
  },
  date: {
    color: 'grey'
  },
  expansionDetails: {
    flexDirection: 'column'
  },
}));
const Statements = ({ healthStatements, doctorStatements }) => {
  const classes = useStyles();

  const doctorRoleStatements = doctorStatements
    .filter((statement) => statement.doctor.type === Object.keys(DoctorType)[DoctorType.Doctor]);
  const therapistStatements = doctorStatements
    .filter((statement) => statement.doctor.type === Object.keys(DoctorType)[DoctorType.Therapist]);

  return (
    <div className={classes.root}>
      <ExpansionPanel square disabled={healthStatements.length === 0}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography variant="subtitle1">
            {healthStatements.length > 0
              ? 'Hälsoutlåtanden'
              : 'Hittade inga hälsoutlåtanden'
            }
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.expansionDetails}>
          {healthStatements.map((statement) =>
            <Grid container key={statement.id} justify="space-around" alignItems="center" className={classes.message} spacing={1}>
              <Grid item>
                <Avatar>{statement.admin.name.charAt(0)}</Avatar>
              </Grid>
              <Grid item xs={10}>
                <Grid container justify="space-between" className={classes.contentWrapper}>
                  <Grid item xs={12}>
                    <div className={classes.messageHeader}>
                      {statement.admin.name}
                    </div>
                    <div className={classes.content}>{statement.content}</div>
                  </Grid>
                  <Grid item className={classes.date}>
                    {statement.createdAt.split('T')[0]}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>)
          }
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel square disabled={doctorRoleStatements.length === 0}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography variant="subtitle1">
            {doctorRoleStatements.length > 0
              ? 'Läkarutlåtanden'
              : 'Hittade inga läkarutlåtanden'
            }
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.expansionDetails}>
          {doctorRoleStatements.map((statement) =>
            <Grid container key={statement.id} justify="space-around" alignItems="center" className={classes.message} spacing={1}>
              <Grid item>
                <Avatar>{statement.doctor.name.charAt(0)}</Avatar>
              </Grid>
              <Grid item xs={10}>
                <Grid container justify="space-between" className={classes.contentWrapper}>
                  <Grid item xs={12}>
                    <div className={classes.messageHeader}>
                      {statement.doctor.name}
                    </div>
                    <div className={classes.content}>{statement.content}</div>
                  </Grid>
                  <Grid item className={classes.date}>
                    {statement.createdAt.split('T')[0]}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>)
          }
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel square disabled={therapistStatements.length === 0}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography variant="subtitle1">
            {therapistStatements.length > 0
              ? 'Terapeututlåtanden'
              : 'Hittade inga terapeututlåtanden'
            }
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.expansionDetails}>
          {therapistStatements.map((statement) =>
            <Grid container key={statement.id} justify="space-around" alignItems="center" className={classes.message} spacing={1}>
              <Grid item>
                <Avatar>{statement.doctor.name.charAt(0)}</Avatar>
              </Grid>
              <Grid item xs={10}>
                <Grid container justify="space-between" className={classes.contentWrapper}>
                  <Grid item xs={12}>
                    <div className={classes.messageHeader}>
                      {statement.doctor.name}
                    </div>
                    <div className={classes.content}>{statement.content}</div>
                  </Grid>
                  <Grid item className={classes.date}>
                    {statement.createdAt.split('T')[0]}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>)
          }
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};

Statements.propTypes = {
  healthStatements: PropTypes.array.isRequired,
  doctorStatements: PropTypes.array.isRequired,
};

export default Statements;
