import React from 'react';
import PropTypes from 'prop-types';
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import PolarChart from 'components/PolarChart';
import { surveys } from 'utils/surveys';

const useStyles = makeStyles(() => ({
  item: {
    marginBottom: 20,
  },
  userInput: {
    whiteSpace: 'pre'
  }
}));

const HealthSurveyAnswers = ({ gender, dbSurvey, role }) => {
  const classes = useStyles();
  const survey = surveys.find((s) => s.version === dbSurvey.versionId);
  if (!survey) {
    return role === 'patient'
      ? 'Kunde inte generera något svar för denna enkäten. Kontakta oss via meddelande om problemet består.'
      : 'Kunde inte generera något svar för denna enkäten. Kontakta utvecklarna om problemet består.';
  }

  const calcScorePerSection = (answers) => {
    const answersScore = answers.filter((a) => a.question !== null && a.section !== null).map((a) => {
      const question = survey.sections[a.section].questions[a.question];
      if (question.type === 'input') return 0;
      if (question.gender && gender !== 'Unknown') {
        if (question.gender !== gender) return 0;
      }
      if (a.answer.length > 1) {
        return a.answer.reduce((acc, choice) => acc + question.choices[choice].scoring, 0) / a.answer.length;
      }
      return question.choices[a.answer[0]].scoring;
    });

    const maxPointsPerSection = survey.sections.map((section) =>
      section.questions.map((question) => {
        if (question.type === 'input') return 0;
        if (question.gender && gender !== 'Unknown') {
          if (question.gender !== gender) return 0;
        }
        return question.choices.reduce((prev, current) => ((prev.scoring > current.scoring) ? prev : current)).scoring;
      }).reduce((acc, section) => acc + section));

    // const totalPoints = answersScore.reduce((a, acc) => a + acc);
    const questions = survey.sections.map((section) => section.questions);
    const questionsPerSection = questions.map((question) => question.length);
    const scorePerSection = questionsPerSection.map((question, i) => answersScore.splice(0, questionsPerSection[i]));
    const sumScorePerSection = scorePerSection.map((answers) => answers.reduce((answer, acc) => answer + acc));
    const percentOfScorePerSection = sumScorePerSection.map((points, i) => (maxPointsPerSection[i] - points) / maxPointsPerSection[i]);
    return survey.sections.map((section, i) => ({ section: section.name, score: percentOfScorePerSection[i] }));
  };

  const generateResponse = (choices, answer) => {
    const score = choices[answer].scoring;
    const response = [];
    switch (score) {
      case 0:
        if (choices[0].response !== '') {
          response.push(<React.Fragment>{choices[0].response}</React.Fragment>);
        }
        break;
      case 1:
        if (choices[1].response !== '') {
          response.push(<React.Fragment>{choices[1].response}</React.Fragment>);
        }
        break;
      case 2:
        if (choices[2].response !== '') {
          response.push(<React.Fragment>{choices[2].response}</React.Fragment>);
        }
        break;
      case 3:
        if (choices[2].response !== '' || choices[3].response !== '') {
          response.push(<React.Fragment>{choices[2].response}<br/>{choices[3].response}</React.Fragment>);
        }
        break;
      case 4:
        if (choices[3].response !== '' || choices[4].response !== '') {
          response.push(<React.Fragment>{choices[3].response}<br/>{choices[4].response}</React.Fragment>);
        }
        break;
      default:
        if (choices[answer].response !== '') {
          response.push(<React.Fragment>{choices[answer].response}</React.Fragment>);
        }
        break;
    }
    if (response.length > 0) {
      response.unshift(<React.Fragment><b>Vår respons:</b><br/></React.Fragment>);
    }
    return response;
  };

  let a = 0;
  return (
    <React.Fragment>
      <ExpansionPanel defaultExpanded>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography variant="subtitle1">Livshjulet</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <PolarChart
            data={calcScorePerSection(dbSurvey.answers.survey).map((a) => a.score * 100)}
            labels={calcScorePerSection(dbSurvey.answers.survey).map((a, i) =>
              `${a.section}: ${Math.round(calcScorePerSection(dbSurvey.answers.survey)[i].score * 100)}%`)}
            colors={survey.sections.map((section) => section.color)}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
      {survey.sections.map((section) => (
        <ExpansionPanel key={section.name}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography variant="subtitle1">{section.name}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container>
              {section.questions.map((question, qIndex) => {
                if (question.gender && gender !== 'Unknown') {
                  if (question.gender !== gender) return null;
                }
                a++;
                const b = a - 1;
                const currQuestion = dbSurvey.answers.survey[b];
                if (currQuestion.section === null || currQuestion.question === null) return null;
                if (question.type === 'input') {
                  return (
                    <Grid item xs={12} container key={section.name + qIndex} className={classes.item}>
                      <Grid item xs={12}>
                        <b>Fråga:</b> {question.name}
                      </Grid>
                      <Grid item xs={12}>
                        <b>{role === 'patient' ? 'Ditt svar: ' : 'Klientens svar:'}</b> {currQuestion.answer[0]}
                      </Grid>
                    </Grid>
                  );
                }
                return (
                  <Grid item xs={12} container key={section.name + qIndex} className={classes.item}>
                    <Grid item xs={12}>
                      <b>Fråga:</b> {question.name}
                    </Grid>
                    <Grid item xs={12}>
                      <b>{role === 'patient' ? question.multiple ? 'Dina val: ' : 'Ditt val: ' : 'Klientens val: '}</b>
                      {question.multiple
                        ? currQuestion.answer.map((answer) => question.choices[answer].name).join(', ')
                        : question.choices[currQuestion.answer[0]].name
                    }
                    </Grid>
                    {'input' in currQuestion && currQuestion.input !== '' &&
                    <Grid item xs={12}>
                      <b>Ytterligare information som {role !== 'patient' ? 'klienten' : 'du'} har angivit:</b><br/>
                      <div className={classes.userInput}>{currQuestion.input}</div>
                    </Grid>
                    }
                    {role === 'admin' &&
                    <Grid item xs={12}>
                      {question.multiple
                        ? <React.Fragment>
                          <b>Vår response</b><br/>
                          {currQuestion.answer.map((answer) => <div>{question.choices[answer].response}</div>)}
                        </React.Fragment>
                        : generateResponse(question.choices, currQuestion.answer[0])
                      }
                    </Grid>
                    }
                  </Grid>);
              })}
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>))}
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography variant="subtitle1">Övrigt</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{ flexDirection: 'column' }}>
          <div><b>Övriga synpunkter</b></div>
          <div className={classes.userInput}>{dbSurvey.answers.extra.comment}</div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </React.Fragment>
  );
};
HealthSurveyAnswers.propTypes = {
  gender: PropTypes.string.isRequired,
  dbSurvey: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
};

export default HealthSurveyAnswers;
