import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  LinearProgress, Table, TableBody, TableCell, TableHead, TableRow, makeStyles
} from '@material-ui/core';
import {
  Clear as CrossIcon,
  Done as DoneIcon,
} from '@material-ui/icons';

import { useSurveyState, useSurveyDispatch } from 'modules/patient/surveys';

const useStyles = makeStyles((theme) => ({
  crossIcon: {
    marginLeft: 5,
    fill: theme.palette.error.main,
  },
  doneIcon: {
    marginLeft: 5,
    fill: theme.palette.success.main,
  },
  tableCell: {
    display: 'flex',
    alignItems: 'center'
  }
}));

const Index = () => {
  const classes = useStyles();
  const { fetchAll } = useSurveyDispatch();
  const { surveys, fetching } = useSurveyState();

  useEffect(() => {
    fetchAll();
  }, []);

  return (
    <div className="elevated-container">
    {fetching
      ? <LinearProgress />
      : surveys.length === 0
        ? 'Hittade inga hälsoenkäter'
        :
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Status</TableCell>
                <TableCell>Datum</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {surveys.map((survey) => (
                <TableRow key={survey.id} component={Link} to={`/patient/surveys/${survey.id}`}>
                  <TableCell component="th" scope="row" className={classes.tableCell}>
                    {Object.keys(survey.answers).length === 0
                      ? <React.Fragment>Inväntar svar<CrossIcon className={classes.crossIcon}/></React.Fragment>
                      : <React.Fragment>Besvarad<DoneIcon className={classes.doneIcon}/></React.Fragment>
                    }
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {survey.createdAt.split('T')[0]}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
    }
    </div>
  );
};

export default Index;
