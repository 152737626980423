import React, { useEffect } from 'react';
import {
  List, LinearProgress
} from '@material-ui/core';

import { useAccountState } from 'modules/account';
import { useNotificationDispatch, useNotificationState } from 'modules/notifications';
import NotificationItem from './notifications/NotificationItem';

const Notifications = () => {
  const [checked, setChecked] = React.useState([]);
  const { role } = useAccountState();
  const { notifications, fetching } = useNotificationState();
  const { fetchAll, setRead, setDone } = useNotificationDispatch();

  useEffect(() => {
    fetchAll();
  }, []);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setDone(value);

    setChecked(newChecked);
  };


  useEffect(() => {
    setChecked(notifications.filter((n) => n.done).map((n) => n.id));
  }, [notifications.length]);

  return (
    <div className="elevated-container">
      {fetching ?
        <LinearProgress/>
        :
        notifications.length > 0 ?
          <List>
            {notifications.map((notification) =>
              <NotificationItem
                key={notification.id}
                setRead={setRead}
                handleToggle={handleToggle}
                checked={checked.indexOf(notification.id) !== -1}
                role={role}
                notification={notification}
              />)}
            </List>
          :
          'Hittade inga notifikationer.'
      }
    </div>
  );
};

export default Notifications;
