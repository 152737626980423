import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-hook-inview';
import { Link } from 'react-router-dom';
import {
  Checkbox, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText
} from '@material-ui/core';
import {
  NotificationsActive as NotificationsActiveIcon,
  NotificationsNone as NotificationsNoneIcon
} from '@material-ui/icons';

const NotificationItem = ({
  notification, setRead, role, checked, handleToggle
}) => {
  const [ref, isVisible] = useInView();

  useEffect(() => {
    if (!notification.read && isVisible) setRead(notification.id);
  }, [isVisible]);

  return (
    <ListItem
      ref={ref}
      button={notification.url !== ''}
      key={notification.id}
      component={notification.url !== '' ? Link : 'div'}
      to={notification.url}
    >
      <ListItemIcon>
        {notification.read
          ? <NotificationsNoneIcon/>
          : <NotificationsActiveIcon/>
        }
      </ListItemIcon>
      <ListItemText
        primary={`${notification.title} (${notification.createdAt.split('T')[0]})`}
        secondary={notification.content}
      />
      {['admin', 'doctor'].includes(role.toLowerCase()) &&
      <ListItemSecondaryAction>
        <Checkbox
          color="primary"
          edge="end"
          onChange={handleToggle(notification.id)}
          checked={checked}
        />
      </ListItemSecondaryAction>
      }
    </ListItem>
  );
};

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    read: PropTypes.bool.isRequired,
    done: PropTypes.bool.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  setRead: PropTypes.func.isRequired,
  handleToggle: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
};

export default NotificationItem;
