import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { PATIENT_ENDPOINTS } from 'utils/api';

const FETCHING = 'PATIENT/MESSAGES/FETCHING';
const FETCHED_ALL = 'PATIENT/MESSAGES/FETCHED_ALL';
const CREATE_MESSAGE = 'PATIENT/MESSAGES/CREATE_MESSAGE';

const initialState = {
  fetching: false,
  data: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCHED_ALL:
      return {
        ...state,
        fetching: false,
        data: action.payload
      };
    case FETCHING:
      return {
        ...state,
        fetching: true,
      };
    case CREATE_MESSAGE:
    default:
      return state;
  }
};

export const fetchAll = (showLoader = true) => (dispatch) => {
  if (showLoader) dispatch({ type: FETCHING });

  return axios.get(`${PATIENT_ENDPOINTS.MESSAGES}`)
    .then((res) => dispatch({ type: FETCHED_ALL, payload: res.data }));
};

export const createMessage = (newMessage) => (dispatch) =>
  axios.post(`${PATIENT_ENDPOINTS.MESSAGES}`, newMessage).then(() => {
    dispatch({ type: CREATE_MESSAGE });
    dispatch(fetchAll(false));
  });

export const useMessageState = () => ({
  fetching: useSelector(({ patient: { messages: { fetching } } }) => fetching),
  messages: useSelector(({ patient: { messages: { data } } }) => data),
});

export const useMessageDispatch = () => {
  const dispatch = useDispatch();
  return {
    fetchAll: () => dispatch(fetchAll()),
    createMessage: (newMessage) => dispatch(createMessage(newMessage)),
  };
};
