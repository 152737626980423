import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { ADMIN_ENDPOINTS } from 'utils/api';

const FETCHING = 'ADMIN/TESTS/FETCHING';
const FETCHED_ALL = 'ADMIN/TESTS/FETCHED_ALL';
const FETCHED_SINGLE = 'ADMIN/TESTS/FETCHED_SINGLE';
const CLEAR_CURRENT = 'ADMIN/TESTS/CLEAR_CURRENT';
const CREATE_CASE_TEST_RESULT = 'ADMIN/TESTS/CREATE_CASE_TEST_RESULT';
const CREATE_PATIENT_TEST_RESULT = 'ADMIN/TESTS/CREATE_PATIENT_TEST_RESULT';
const CREATE_PATIENT_LABPORTALEN_TEST_RESULT = 'ADMIN/TESTS/CREATE_PATIENT_LABPORTALEN_TEST_RESULT';
const CREATE_CASE_LABPORTALEN_TEST_RESULT = 'ADMIN/TESTS/CREATE_CASE_LABPORTALEN_TEST_RESULT';
const SIGNED = 'ADMIN/TESTS/SIGNED';

const initialState = {
  fetching: false,
  data: [],
  current: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCHED_ALL:
      return {
        ...state,
        fetching: false,
        data: action.payload
      };
    case FETCHED_SINGLE:
      return {
        ...state,
        fetching: false,
        current: action.payload
      };
    case FETCHING:
      return {
        ...state,
        fetching: true,
      };
    case CLEAR_CURRENT:
      return {
        ...state,
        current: {}
      };
    case CREATE_CASE_TEST_RESULT:
    case CREATE_PATIENT_TEST_RESULT:
    case CREATE_PATIENT_LABPORTALEN_TEST_RESULT:
    case CREATE_CASE_LABPORTALEN_TEST_RESULT:
    case SIGNED:
    default:
      return state;
  }
};

export const fetchAll = () => (dispatch) => {
  dispatch({ type: FETCHING });

  return axios.get(`${ADMIN_ENDPOINTS.TESTS}`)
    .then((res) => dispatch({ type: FETCHED_ALL, payload: res.data }));
};

export const fetchSingle = (id, showLoader = true) => (dispatch) => {
  if (showLoader) dispatch({ type: FETCHING });

  return axios.get(`${ADMIN_ENDPOINTS.TESTS}/${id}`)
    .then((res) => dispatch({ type: FETCHED_SINGLE, payload: res.data }));
};

export const clearCurrent = () => (dispatch) => dispatch({ type: CLEAR_CURRENT });

export const createCaseTestResult = (newTestResult) => (dispatch) =>
  axios.post(`${ADMIN_ENDPOINTS.TESTS}/case`, newTestResult).then(() => {
    dispatch({ type: CREATE_CASE_TEST_RESULT });
    dispatch(fetchAll());
  });

export const createPatientTestResult = (newTestResult) => (dispatch) =>
  axios.post(`${ADMIN_ENDPOINTS.TESTS}/patient`, newTestResult, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }).then(() => {
    dispatch({ type: CREATE_PATIENT_TEST_RESULT });
    dispatch(fetchAll());
  });

export const createPatientLabportalenTestResult = (newTestResult) => (dispatch) =>
  axios.post(`${ADMIN_ENDPOINTS.TESTS}/patient-labportalen`, newTestResult).then(() => {
    dispatch({ type: CREATE_PATIENT_LABPORTALEN_TEST_RESULT });
  });

export const createCaseLabportalenTestResult = (newTestResult) => (dispatch) =>
  axios.post(`${ADMIN_ENDPOINTS.TESTS}/case-labportalen`, newTestResult).then(() => {
    dispatch({ type: CREATE_CASE_LABPORTALEN_TEST_RESULT });
  });

export const signTestResult = (id) => (dispatch) =>
  axios.post(`${ADMIN_ENDPOINTS.TESTS}/${id}/sign`)
    .then(() => {
      dispatch({ type: SIGNED });
      dispatch(fetchSingle(id, false));
    });

export const useTestState = () => ({
  fetching: useSelector(({ admin: { tests: { fetching } } }) => fetching),
  tests: useSelector(({ admin: { tests: { data } } }) => data),
  current: useSelector(({ admin: { tests: { current } } }) => current),
});

export const useTestDispatch = () => {
  const dispatch = useDispatch();
  return {
    fetchSingle: (id) => dispatch(fetchSingle(id)),
    fetchAll: () => dispatch(fetchAll()),
    clearCurrent: () => dispatch(clearCurrent()),
    createCaseTestResult: (newTestResult) => dispatch(createCaseTestResult(newTestResult)),
    createPatientTestResult: (newTestResult) => dispatch(createPatientTestResult(newTestResult)),
    createPatientLabportalenTestResult: (newTestResult) => dispatch(createPatientLabportalenTestResult(newTestResult)),
    createCaseLabportalenTestResult: (newTestResult) => dispatch(createCaseLabportalenTestResult(newTestResult)),
    signTestResult: (id) => dispatch(signTestResult(id))
  };
};
