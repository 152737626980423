import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import MenuIcon from '@material-ui/icons/Menu';
import GridIcon from '@material-ui/icons/Apps';
import SettingsIcon from '@material-ui/icons/Settings';
import FilesIcon from '@material-ui/icons/SaveAlt';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import RefreshIcon from '@material-ui/icons/Refresh';
import SearchIcon from '@material-ui/icons/YoutubeSearchedFor';

const useStyles = makeStyles((theme) => ({
  root: {
    //margin: theme.spacing(0, 2)
  },
  button: {
    margin: '10px 8px 10px 0',
    height: 45,
    padding: '4px',
    minWidth: 10,
  },
  icon: {
    marginRight: 0,
    fontSize: 25,
  },
  label: {
    marginLeft: theme.spacing(1),
  },
}));

const ButtonBar = ({ buttons }) => {
  const classes = useStyles();
  const history = useHistory();

  const onClickFunc = (event, type, onClick) => {
    switch (type) {
      case 'link':
        history.push(onClick);
        break;
      case 'func':
        onClick(event);
        break;
      default:
        break;
    }
  };

  return (
    <div className={classes.root}>
      { buttons.map((button, i) => (
        <Button
          key={i}
          variant="contained"
          size="small"
          disabled={button.disabled}
          className={classes.button}
          onClick={event => onClickFunc(event, button.onClickEvent ? button.onClickEvent : 'none', button.onClick)}
        >
          {{
            save: <SaveIcon className={classes.icon} />,
            grid: <GridIcon className={classes.icon}/>,
            menu: <MenuIcon className={classes.icon}/>,
            edit: <EditIcon className={classes.icon}/>,
            delete: <DeleteIcon className={classes.icon}/>,
            add: <AddIcon className={classes.icon}/>,
            settings: <SettingsIcon className={classes.icon}/>,
            files: <FilesIcon className={classes.icon}/>,
            refresh: <RefreshIcon className={classes.icon}/>,
            search: <SearchIcon className={classes.icon}/>,
          }[button.icon]}
          { button.name && <div className={button.icon ? classes.label : ''}>{button.name}</div> }
        </Button>
      ))}
    </div>
  );
};

ButtonBar.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    icon: PropTypes.oneOf(['none', 'save', 'add', 'grid', 'menu', 'edit', 'delete', 'settings', 'files', 'refresh', 'search']),
    onClickEvent: PropTypes.oneOf(['none', 'link', 'func']),
    disabled: PropTypes.bool,
    onClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  })).isRequired,
};

export default ButtonBar;
