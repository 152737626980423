import React, { useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import { LinearProgress, Box } from '@material-ui/core';

import { usePatientDispatch as usePatientDispatchAdmin, usePatientState as usePatientStateAdmin } from 'modules/admin/patients';
import { usePatientDispatch as usePatientDispatchDoctor, usePatientState as usePatientStateDoctor } from 'modules/doctor/patients';
import ButtonBar from 'components/ButtonBar';
import Table from 'components/Table';
import useToggle from 'hooks/useToggle';
import { ADMIN_ENDPOINTS } from 'utils/api';

import CreatePatientForm from './index/CreatePatientForm';

const Index = () => {
  const { role } = useParams();
  const history = useHistory();
  const [open, toggleOpen] = useToggle();
  const fileUploadRef = useRef();

  const usePatientDispatch = role === 'admin' ? usePatientDispatchAdmin : usePatientDispatchDoctor;
  const usePatientState = role === 'admin' ? usePatientStateAdmin : usePatientStateDoctor;

  const { fetchAll } = usePatientDispatch();
  const { fetching, fetched, patients } = usePatientState();

  useEffect(() => {
    if (!fetched) fetchAll();
  }, []);

  const buttons = [{
    name: 'Lägg till klient',
    icon: 'add',
    onClickEvent: 'func',
    onClick: toggleOpen
  }, {
    name: 'Ladda upp csv',
    icon: 'add',
    onClickEvent: 'func',
    onClick: () => fileUploadRef.current && fileUploadRef.current.click()
  }, {
    icon: 'refresh',
    onClickEvent: 'func',
    onClick: fetchAll
  }];

  const handleCsvUpload = () => {
    if (fileUploadRef.current && fileUploadRef.current.files.length > 0) {
      const formData = new FormData();
      formData.append('file', fileUploadRef.current.files[0]);
      axios.post(`${ADMIN_ENDPOINTS.FILEUPLOAD}/csv-case`, formData, {
        headers: {
          'Content-Type': 'text/csv'
        }
      }).then(() => {
        fetchAll();
      });
      fileUploadRef.current.value = '';
    }
  };

  return (
    <div className="elevated-container">
    {fetching
      ? <LinearProgress />
      : patients.length === 0
        ? 'Hittade inga patienter'
        :
        <React.Fragment>
          {role === 'admin' &&
            <Box ml="10px">
              <ButtonBar buttons={buttons} />
              <CreatePatientForm
                toggleOpen={toggleOpen}
                open={open}
              />
              <a href="csv-mall.csv">Klicka här för att ladda ner csv-mall</a>
              <input
                type="file"
                id="my_file"
                accept=".csv"
                style={{ display: 'none' }}
                ref={fileUploadRef}
                onChange={handleCsvUpload}
              />
            </Box>
          }
          <Table
            columns={[
              { title: 'Namn (personnummer)', field: 'name' }
            ]}
            data={patients.map((patient) => ({
              name: `${patient.name} (${patient.personalNumber})`,
              id: patient.id
            }))}
            actions={[
              {
                icon: 'assignment_ind',
                tooltip: 'Öppna patientjournal',
                onClick: (event, rowData) => history.push(`/${role}/patients/${rowData.id}/overview`)
              }
            ]}
          />
        </React.Fragment>
    }
    </div>
  );
};

export default Index;
