import React from 'react';
import PropTypes from 'prop-types';
import {
  IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, makeStyles
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import {
  ArrowForward as ArrowForwardIcon,
  Description as DescriptionIcon,
  Clear as CrossIcon,
  Done as DoneIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';

import useToggle from 'hooks/useToggle';
import ButtonBar from 'components/ButtonBar';
import UploadTestResultForm from './testResults/UploadTestResultForm';
import CreateLabportalenTestResult from './testResults/CreateLabportalenTestResult';

const useStyles = makeStyles((theme) => ({
  crossIcon: {
    fill: theme.palette.error.main,
  },
  doneIcon: {
    fill: theme.palette.success.main,
  }
}));

const TestResults = ({
  tests, role, id, type, fetch, deleteTest
}) => {
  const classes = useStyles();
  const [showUploadTest, toggleShowUploadTest] = useToggle();
  const [showLabportalenLinkForm, toggleShowLabportalenLinkForm] = useToggle();

  const buttons = [{
    name: 'Ladda upp provresultat',
    icon: 'add',
    onClickEvent: 'func',
    onClick: toggleShowUploadTest
  }, {
    name: 'Länka labbportalen svar',
    icon: 'add',
    onClickEvent: 'func',
    onClick: toggleShowLabportalenLinkForm
  }];

  return (
    <React.Fragment>
      {role === 'admin' &&
      <React.Fragment>
        <UploadTestResultForm
          toggleOpen={toggleShowUploadTest}
          open={showUploadTest}
          type={type}
          modelId={id}
          updateView={fetch}
        />
        <CreateLabportalenTestResult
          toggleOpen={toggleShowLabportalenLinkForm}
          open={showLabportalenLinkForm}
          type={type}
          modelId={id}
          updateView={fetch}
        />
        <ButtonBar buttons={buttons}/>
      </React.Fragment>
      }
      {tests.length === 0
        ? 'Hittade inga provresultat.'
        :
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Typ</TableCell>
              <TableCell>Titel</TableCell>
              <TableCell>Rid</TableCell>
              <TableCell>Datum</TableCell>
              <TableCell>Signerat</TableCell>
              <TableCell/>
            </TableRow>
          </TableHead>
          <TableBody>
            {tests.map((test) => (
              <TableRow key={test.id}>
                <TableCell component="th" scope="row">
                  {test.type}
                </TableCell>
                <TableCell component="th" scope="row">
                  {test.title ? test.title : ''}
                </TableCell>
                <TableCell component="th" scope="row">
                  {test.referralId ? test.referralId : ''}
                </TableCell>
                <TableCell>
                  {test.createdAt.split('T')[0]}
                </TableCell>
                <TableCell>
                  {test.signed
                    ? <DoneIcon className={classes.doneIcon}/>
                    : <CrossIcon className={classes.crossIcon}/>
                  }
                </TableCell>
                <TableCell>
                  <Tooltip title="Öppna provresultat">
                    <IconButton
                      component={Link}
                      to={`/${role}/tests/${test.id}`}
                    >
                      <ArrowForwardIcon color="primary"/>
                    </IconButton>
                  </Tooltip>
                  {test.case !== null && type === 'patient' &&
                    <Tooltip title="Öppna ärende">
                      <IconButton
                        component={Link}
                        to={`/${role}/cases/${test.case.id}/tests`}
                      >
                        <DescriptionIcon color="primary"/>
                      </IconButton>
                    </Tooltip>
                  }
                  {test.type === 'ManualUpload' && role === 'admin' && !test.signed &&
                  <Tooltip title="Ta bort provresultat">
                    <IconButton
                      onClick={() => deleteTest(test.id)}
                    >
                      <DeleteIcon color="error"/>
                    </IconButton>
                  </Tooltip>
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      }
    </React.Fragment>
  );
};

TestResults.propTypes = {
  fetch: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['patient', 'case']).isRequired,
};

export default TestResults;
