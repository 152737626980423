import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { DOCTOR_ENDPOINTS } from 'utils/api';

const FETCHING = 'DOCTOR/TESTS/FETCHING';
const FETCHED_ALL = 'DOCTOR/TESTS/FETCHED_ALL';
const FETCHED_SINGLE = 'DOCTOR/TESTS/FETCHED_SINGLE';
const SIGNED = 'DOCTOR/TESTS/SIGNED';
const CLEAR_CURRENT = 'DOCTOR/TESTS/CLEAR_CURRENT';

const initialState = {
  fetching: false,
  data: [],
  current: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCHED_ALL:
      return {
        ...state,
        fetching: false,
        data: action.payload
      };
    case FETCHED_SINGLE:
      return {
        ...state,
        fetching: false,
        current: action.payload
      };
    case FETCHING:
      return {
        ...state,
        fetching: true,
      };
    case CLEAR_CURRENT:
      return {
        ...state,
        current: {}
      };
    case SIGNED:
    default:
      return state;
  }
};

export const fetchAll = () => (dispatch) => {
  dispatch({ type: FETCHING });

  return axios.get(`${DOCTOR_ENDPOINTS.TESTS}`)
    .then((res) => dispatch({ type: FETCHED_ALL, payload: res.data }));
};

export const fetchSingle = (id, showLoader = true) => (dispatch) => {
  if (showLoader) dispatch({ type: FETCHING });

  return axios.get(`${DOCTOR_ENDPOINTS.TESTS}/${id}`)
    .then((res) => dispatch({ type: FETCHED_SINGLE, payload: res.data }));
};

export const signTestResult = (id) => (dispatch) =>
  axios.post(`${DOCTOR_ENDPOINTS.TESTS}/${id}/sign`)
    .then(() => {
      dispatch({ type: SIGNED });
      dispatch(fetchSingle(id, false));
    });

export const clearCurrent = () => (dispatch) => dispatch({ type: CLEAR_CURRENT });

export const useTestState = () => ({
  fetching: useSelector(({ doctor: { tests: { fetching } } }) => fetching),
  tests: useSelector(({ doctor: { tests: { data } } }) => data),
  current: useSelector(({ doctor: { tests: { current } } }) => current),
});

export const useTestDispatch = () => {
  const dispatch = useDispatch();
  return {
    fetchSingle: (id) => dispatch(fetchSingle(id)),
    fetchAll: () => dispatch(fetchAll()),
    clearCurrent: () => dispatch(clearCurrent()),
    signTestResult: (id) => dispatch(signTestResult(id))
  };
};
