import React, { useEffect } from 'react';
import {
  LinearProgress
} from '@material-ui/core';

import { useParams } from 'react-router-dom';
import { useSurveyDispatch as useSurveyAdminDispatch, useSurveyState as useSurveyAdminState } from 'modules/admin/surveys';
import { useSurveyDispatch as useSurveyDoctorDispatch, useSurveyState as useSurveyDoctorState } from 'modules/doctor/surveys';

import HealthSurveyAnswers from 'components/HealthSurveyAnswers';

const HealthSurvey = () => {
  const { role, id } = useParams();

  const useSurveyDispatch = role === 'admin' ? useSurveyAdminDispatch : useSurveyDoctorDispatch;
  const useSurveyState = role === 'admin' ? useSurveyAdminState : useSurveyDoctorState;

  const { fetchSingle, clearCurrent } = useSurveyDispatch();
  const { current, fetching } = useSurveyState();

  useEffect(() => {
    fetchSingle(parseInt(id, 10));
    return () => clearCurrent();
  }, []);

  return (fetching ?
    <LinearProgress/>
    : (Object.keys(current).length > 0 && Object.keys(current.answers).length > 0) ?
      <HealthSurveyAnswers gender={current.patient.gender} dbSurvey={current} role={role} />
      :
      <div className="elevated-container">
        Antingen finns det ingen enkät med detta ID eller är denna enkäten inte besvarad ännu.
      </div>
  );
};

export default HealthSurvey;
