import React from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { Paper } from '@material-ui/core';

const Table = ({ columns, data, actions }) => {
  return (
    <MaterialTable
      title=""
      components={{
        Container: (props) => <Paper {...props} elevation={0}/>
      }}
      localization={{
        header: {
          actions: ''
        },
        pagination: {
          labelDisplayedRows: '{from}-{to} av {count}',
          labelRowsSelect: 'rader'
        },
        toolbar: {
          searchPlaceholder: 'Sök',
          searchTooltip: 'Sök',
        }
      }}
      options={{
        pageSize: 10
      }}
      columns={columns}
      data={data}
      actions={actions}
    />
  );
};

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  actions: PropTypes.array,
};

export default Table;
