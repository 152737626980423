import React from 'react';
import { Formik } from 'formik';
import { useAccountState, useAccountDispatch } from 'modules/account';
import {
  Grid, Button, makeStyles, TextField, Typography
} from '@material-ui/core';
import { PatientCreationSchema } from 'utils/validation';

const useStyles = makeStyles(() => ({
  form: {
    margin: '0 10px',
    '& > div': {
      marginBottom: 10
    },
    '& h6': {
      marginBottom: 15
    }
  },
}));

const Settings = () => {
  const classes = useStyles();
  const { user } = useAccountState();
  const { updateAccountAsPatient } = useAccountDispatch();

  return (
    <Grid container justify="center" className="elevated-container">
      <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
      <Formik
        initialValues={{
          firstName: user.firstName ? user.firstName : '',
          lastName: user.lastName ? user.lastName : '',
          email: user.email ? user.email : '',
          address: user.address ? user.address : '',
          postCode: user.postCode ? user.postCode : '',
          city: user.city ? user.city : '',
          country: user.country ? user.country : '',
          phoneNumber: user.phoneNumber ? user.phoneNumber : '',
          phoneWork: user.phoneWork ? user.phoneWork : '',
          phoneHome: user.phoneHome ? user.phoneHome : '',
          length: user.length ? user.length : '',
          weight: user.weight ? user.weight : ''
        }}
        validationSchema={PatientCreationSchema}
        onSubmit={(values, actions) => {
          updateAccountAsPatient(user.patientId, {
            ...values,
            length: values.length === '' ? 0 : parseInt(values.length, 10),
            weight: values.weight === '' ? 0 : parseInt(values.weight, 10)
          }).then(() => {
            actions.resetForm();
          })
            .catch(() => actions.setSubmitting(false));
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) =>
          <form onSubmit={handleSubmit} className={classes.form}>
            <Typography variant="subtitle1">Uppdatera din information nedan.</Typography>
            <TextField
              variant="outlined"
              fullWidth
              name="firstName"
              label="Förnamn"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.firstName}
              error={touched.firstName && errors.firstName}
            />
            <TextField
              variant="outlined"
              fullWidth
              name="lastName"
              label="Efternamn"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.lastName}
              error={touched.lastName && errors.lastName}
            />
            <TextField
              variant="outlined"
              fullWidth
              name="email"
              label="Email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              error={touched.email && errors.email}
            />
            <TextField
              variant="outlined"
              fullWidth
              name="address"
              label="Adress"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.address}
              error={touched.address && errors.address}
            />
            <TextField
              variant="outlined"
              fullWidth
              name="postCode"
              label="Postnummer"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.postCode}
              error={touched.postCode && errors.postCode}
            />
            <TextField
              variant="outlined"
              fullWidth
              name="city"
              label="Ort"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.city}
              error={touched.city && errors.city}
            />
            <TextField
              variant="outlined"
              fullWidth
              name="country"
              label="Land"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.country}
              error={touched.country && errors.country}
            />
            <TextField
              variant="outlined"
              fullWidth
              name="phoneNumber"
              label="Telefonnummer"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.phoneNumber}
              error={touched.phoneNumber && errors.phoneNumber}
            />
            <TextField
              variant="outlined"
              fullWidth
              name="phoneWork"
              label="Telefonnummer (arbete)"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.phoneWork}
              error={touched.phoneWork && errors.phoneWork}
            />
            <TextField
              variant="outlined"
              fullWidth
              name="phoneHome"
              label="Telefonnummer (hemma)"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.phoneHome}
              error={touched.phoneHome && errors.phoneHome}
            />
            <TextField
              variant="outlined"
              fullWidth
              type="number"
              name="length"
              label="Längd (cm)"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.length}
              error={touched.length && errors.length}
            />
            <TextField
              variant="outlined"
              fullWidth
              type="number"
              name="weight"
              label="Vikt (kg)"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.weight}
              error={touched.weight && errors.weight}
            />
            <Button variant="contained" color="primary" fullWidth type="submit" disabled={isSubmitting}>
              Spara
            </Button>
          </form>
        }
      />
      </Grid>
    </Grid>
  );
};

export default Settings;
