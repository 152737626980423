import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const AnalysisList = ({ show, toggle, analysisList }) => (
    <Dialog open={show} onClose={toggle}>
      <DialogTitle>Analyslista</DialogTitle>
      <DialogContent dividers>
        {analysisList === null ?
          'Hittade inga analyser för detta laboratoriet.'
          : analysisList.length === 0 ?
            'Hittade inga analyser för detta laboratoriet.'
            :
            analysisList.map((analysis) =>
              <div key={analysis.AnalysisCode}>
                {analysis.AnalysisName}
              </div>)
        }
      </DialogContent>
    </Dialog>
);

export default AnalysisList;
