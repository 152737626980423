import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import {
  LinearProgress, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Box
} from '@material-ui/core';
import {
  ArrowForward as ArrowForwardIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@material-ui/icons';

import { useAdminDispatch, useAdminState } from 'modules/admin/admins';

import ButtonBar from 'components/ButtonBar';
import useToggle from 'hooks/useToggle';
import CreateAdminForm from './index/CreateAdminForm';

const Index = () => {
  const [open, toggleOpen] = useToggle(false);

  const { fetchAll } = useAdminDispatch();
  const { fetching, fetched, admins } = useAdminState();

  useEffect(() => {
    if (!fetched) fetchAll();
  }, []);

  const buttons = [{
    name: 'Lägg till admin',
    icon: 'add',
    onClickEvent: 'func',
    onClick: toggleOpen
  }, {
    icon: 'refresh',
    onClickEvent: 'func',
    onClick: fetchAll
  }];

  return (
    <div className="elevated-container">
    {fetching
      ? <LinearProgress />
      :
      <React.Fragment>
        <Box ml="10px">
          <ButtonBar buttons={buttons} />
          <CreateAdminForm
            toggleOpen={toggleOpen}
            open={open}
          />
        </Box>
        {admins.length === 0
          ? <React.Fragment>Hittade inga admins.</React.Fragment>
          :
          <React.Fragment>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Namn</TableCell>
                  <TableCell/>
                </TableRow>
              </TableHead>
              <TableBody>
                { admins.map((admin) => (
                  <TableRow key={admin.id}>
                    <TableCell component="th" scope="row">
                      {admin.name}
                    </TableCell>
                    <TableCell>
                      <Tooltip title="Öppna adminprofil">
                        <IconButton
                          component={Link}
                          to={`/admin/admins/${admin.id}/statements`}
                        >
                          <ArrowForwardIcon color="primary"/>
                        </IconButton>
                      </Tooltip>
                      {/*<Tooltip title="Redigera admin (TBI)">
                        <IconButton>
                          <EditIcon color="primary"/>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Ta bort admin (TBI)">
                        <IconButton>
                          <DeleteIcon color="error"/>
                        </IconButton>
                      </Tooltip>*/}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </React.Fragment>
        }
      </React.Fragment>
    }
    </div>
  );
};

export default Index;
