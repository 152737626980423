import React from 'react';
import PropTypes from 'prop-types';
import {
  TextField, FormControl, Select, MenuItem, makeStyles
} from '@material-ui/core';
import { Formik } from 'formik';

import { usePatientDispatch } from 'modules/admin/patients';
import { PatientCreationSchema } from 'utils/validation';
import { Gender } from 'utils/enums';
import FormDialog from 'components/FormDialog';

const useStyles = makeStyles(() => ({
  form: {
    '& > div': {
      marginBottom: 10
    },
    '& > button': {
      textAlign: 'right'
    }
  },
  formActions: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

const CreatePatientForm = ({ toggleOpen, open }) => {
  const classes = useStyles();
  const { createPatient } = usePatientDispatch();
  return (
    <Formik
      initialValues={{
        personalNumber: '',
        firstName: '',
        lastName: '',
        address: '',
        postCode: '',
        city: '',
        country: '',
        gender: Gender.Male,
        phoneNumber: '',
        email: '',
        password: 'Password123!',
      }}
      validationSchema={PatientCreationSchema}
      onSubmit={(values, actions) => {
        createPatient(values).then(() => {
          toggleOpen();
          actions.resetForm();
        }).catch(() => actions.setSubmitting(false));
      }}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
      }) => (
        <FormDialog
          title="Lägg till klient"
          description="Fyll i informationen nedan för att skapa en ny klient."
          open={open}
          toggle={toggleOpen}
          onSubmitDisabled={isSubmitting}
          onSubmit={handleSubmit}
        >
          <form onSubmit={handleSubmit} className={classes.form} autoComplete="off">
            <TextField
              variant="outlined"
              fullWidth
              required
              name="personalNumber"
              label="Personnummer (ÅÅÅÅMMDDXXXX)"
              type="number"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.personalNumber}
              error={touched.personalNumber && errors.personalNumber}
            />
            <TextField
              variant="outlined"
              fullWidth
              required
              name="firstName"
              label="Förnamn"
              type="text"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.firstName}
              error={touched.firstName && errors.firstName}
            />
            <TextField
              variant="outlined"
              fullWidth
              required
              name="lastName"
              label="Efternamn"
              type="text"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.lastName}
              error={touched.lastName && errors.lastName}
            />
            <TextField
              variant="outlined"
              fullWidth
              name="address"
              label="Adress"
              type="text"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.address}
              error={touched.address && errors.address}
            />
            <TextField
              variant="outlined"
              fullWidth
              name="postCode"
              label="Postnummer"
              type="text"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.postCode}
              error={touched.postCode && errors.postCode}
            />
            <TextField
              variant="outlined"
              fullWidth
              name="city"
              label="Ort"
              type="text"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.city}
              error={touched.city && errors.city}
            />
            <TextField
              variant="outlined"
              fullWidth
              name="country"
              label="Land"
              type="text"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.country}
              error={touched.country && errors.country}
            />
            <TextField
              variant="outlined"
              fullWidth
              name="phoneNumber"
              label="Telefonnummer"
              type="text"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.phoneNumber}
              error={touched.phoneNumber && errors.phoneNumber}
            />
            <FormControl fullWidth>
              <Select
                variant="outlined"
                value={values.gender}
                onChange={handleChange}
                inputProps={{
                  name: 'gender',
                  id: 'gender-select',
                }}
              >
                <MenuItem value={Gender.Male}>Male</MenuItem>
                <MenuItem value={Gender.Female}>Female</MenuItem>
              </Select>
            </FormControl>
            <TextField
              variant="outlined"
              fullWidth
              required
              name="email"
              label="Email"
              type="text"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.email}
              error={touched.email && errors.email}
            />
          </form>
        </FormDialog>
      )}
    />
  );
};

CreatePatientForm.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
};

export default CreatePatientForm;
