import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import AccountIcon from '@material-ui/icons/AccountBox';
import HomeIcon from '@material-ui/icons/Home';
import { makeStyles } from '@material-ui/core/styles';

import AdminMenu from './sideDrawer/AdminMenu';
import DoctorMenu from './sideDrawer/DoctorMenu';
import PatientMenu from './sideDrawer/PatientMenu';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
}));

const SideDrawer = ({
  toggle, open, role, logout, isLoggedIn
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { pathname } = useLocation();

  const drawer = (isMobile) => (
    <React.Fragment>
      <div className={classes.toolbar} />
      { role === 'Admin' && <AdminMenu toggle={toggle} isMobile={isMobile} pathname={pathname} /> }
      { role === 'Doctor' && <DoctorMenu toggle={toggle} isMobile={isMobile} pathname={pathname} /> }
      { role === 'Patient' && <PatientMenu toggle={toggle} isMobile={isMobile} pathname={pathname} /> }
      <Divider />
      {/*<List>
        <ListItem button component={Link} to="/" onClick={() => isMobile && toggle()} selected={pathname === '/'}>
          <ListItemIcon><HomeIcon /></ListItemIcon>
          <ListItemText primary="Hem" />
        </ListItem>
      </List>
      <Divider />*/}
      <List>
        { isLoggedIn !== null && isLoggedIn ?
          <ListItem button onClick={() =>
            logout().then(() => {
              if (isMobile) toggle();
              history.push(`${role !== 'Patient' ? `/${role.toLowerCase()}` : ''}/login`);
            })
          }>
            <ListItemIcon><LogoutIcon /></ListItemIcon>
            <ListItemText primary="Logga ut" />
          </ListItem>
          :
          <ListItem button component={Link} to="/login" onClick={() => isMobile && toggle()} selected={pathname === '/login'}>
            <ListItemIcon><AccountIcon /></ListItemIcon>
            <ListItemText primary="Logga in" />
          </ListItem>
        }
      </List>
    </React.Fragment>
  );

  return (
    <nav className={classes.drawer}>
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          open={open}
          onClose={toggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          { drawer(true) }
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          { drawer(false) }
        </Drawer>
      </Hidden>
    </nav>
  );
};

SideDrawer.propTypes = {
  isLoggedIn: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};


export default SideDrawer;
