import React, { useEffect, useState } from 'react';

import {
  LinearProgress, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Box
} from '@material-ui/core';
import {
  List as ListIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Cached as RefreshIcon,
} from '@material-ui/icons';


import { useLaboratoryDispatch, useLaboratoryState } from 'modules/admin/laboratories';
import useToggle from 'hooks/useToggle';

import ButtonBar from 'components/ButtonBar';

import AnalysisList from './laboratories/AnalysisList';
import CreateLaboratory from './laboratories/CreateLaboratory';

const Laboratories = () => {
  const [analysisListOpen, toggleAnalysisListOpen] = useToggle(false);
  const [newLabOpen, toggleNewLabOpen] = useToggle(false);
  const [analysisList, setAnalysisList] = useState([]);
  const { laboratories, fetched, fetching } = useLaboratoryState();
  const { fetchAll, updateAnalysis } = useLaboratoryDispatch();

  useEffect(() => {
    if (!fetched) fetchAll();
  }, []);

  const handleAnalysisListToggle = (index) => {
    let currentLabAnalysis = [];
    if (Object.keys(laboratories[index].analysis).length > 0) {
      currentLabAnalysis = laboratories[index].analysis.Analysislist;
    }
    setAnalysisList(currentLabAnalysis);
    toggleAnalysisListOpen();
  };

  const buttons = [{
    name: 'Lägg till laboratorie',
    icon: 'add',
    onClickEvent: 'func',
    onClick: toggleNewLabOpen
  }, {
    icon: 'refresh',
    onClickEvent: 'func',
    onClick: fetchAll
  }];

  return (
    <div className="elevated-container">
    {fetching
      ? <LinearProgress />
      :
      <React.Fragment>
        <CreateLaboratory toggleOpen={toggleNewLabOpen} open={newLabOpen}/>
        <AnalysisList
          show={analysisListOpen}
          toggle={toggleAnalysisListOpen}
          analysisList={analysisList}
        />
        <Box ml="10px">
          <ButtonBar buttons={buttons} />
        </Box>
        {laboratories.length === 0
          ? 'Hittade inga laboratorier.'
          :
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Namn</TableCell>
                <TableCell>Kod</TableCell>
                <TableCell>Beställarkod</TableCell>
                <TableCell>Senast kontrollerad</TableCell>
                <TableCell>Senast uppdaterad</TableCell>
                <TableCell/>
              </TableRow>
            </TableHead>
            <TableBody>
              {laboratories.map((lab, i) => (
                <TableRow key={lab.id}>
                  <TableCell component="th" scope="row">
                    {lab.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {lab.code}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {lab.orderCode}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {lab.lastCheck.split('T')[0]}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {lab.lastUpdate.split('T')[0]}
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Visa analyser">
                      <IconButton onClick={() => handleAnalysisListToggle(i)}>
                        <ListIcon color="primary"/>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Uppdatera analyser">
                      <IconButton onClick={() => updateAnalysis(lab.id)}>
                        <RefreshIcon color="primary"/>
                      </IconButton>
                    </Tooltip>
                    {/*<Tooltip title="Redigera laboratorie (TBI)">
                      <IconButton>
                        <EditIcon color="primary"/>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Ta bort laboratorie (TBI)">
                      <IconButton>
                        <DeleteIcon color="error"/>
                      </IconButton>
                    </Tooltip>*/}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        }
      </React.Fragment>
    }
    </div>
  );
};

export default Laboratories;
