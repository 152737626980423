import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { makeStyles, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  section: {
    marginBottom: 70,
  },
  item: {
    padding: theme.spacing(4, 2),
    borderBottom: '1px solid black'
  },
  header: {
    fontWeight: 'bold',
    marginBottom: 10,
  },
}));

const Statements = ({ statements, role }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.section}>
        {statements.length === 0
          ? 'Hittade inga utlåtanden.'
          : statements.map((statement) =>
            <Grid key={statement.id} className={classes.item} container justify="space-between" alignItems="center">
              <Grid item xs={10}>
                <div className={classes.header}>
                  <Link to={`/${role}/patients/${statement.patient.id}/overview`}>{statement.patient.name}</Link>
                  {statement.case !== null &&
                    <React.Fragment> - <Link to={`/${role}/cases/${statement.case.id}`}>Tillhör följande ärende</Link></React.Fragment>
                  }
                </div>
                <div>{statement.content}</div>
              </Grid>
              <Grid item>
                {statement.createdAt.split('T')[0]}
              </Grid>
            </Grid>)
        }
      </div>
    </React.Fragment>
  );
};

Statements.propTypes = {
  statements: PropTypes.array.isRequired,
  role: PropTypes.string.isRequired,
};

export default Statements;
