import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

import {
  makeStyles, Grid, TextField, Button, Checkbox, FormControlLabel, IconButton,
} from '@material-ui/core';
import { Edit as EditIcon, Clear as ClearIcon, Delete as DeleteIcon } from '@material-ui/icons';

import { useCaseDispatch as useCaseDispatchAdmin } from 'modules/admin/cases';
import { usePatientDispatch as usePatientDispatchAdmin } from 'modules/admin/patients';
import { useCaseDispatch as useCaseDispatchDoctor } from 'modules/doctor/cases';
import { usePatientDispatch as usePatientDispatchDoctor } from 'modules/doctor/patients';
import { useAccountState } from 'modules/account';

import { CommentCreationSchema } from 'utils/validation';

const useStyles = makeStyles((theme) => ({
  item: {
    padding: theme.spacing(4, 2),
    borderBottom: '2px solid black'
  },
  header: {
    fontWeight: 'bold',
    marginBottom: 10,
  },
  contentDoctorPermission: {
    marginTop: 10,
    fontStyle: 'italic'
  },
  content: {
    whiteSpace: 'pre-line',
    wordWrap: 'break-word'
  },
  addNew: {
    marginTop: 30,
    '& > .header': {
      fontSize: 20
    },
    '& > div': {
      marginBottom: 10
    },
    '& .bottom': {
      display: 'flex',
      justifyContent: 'space-between'
    }
  }
}));
const Comments = ({
  comments, id, role, page
}) => {
  const classes = useStyles();
  const [editingIds, setEditingIds] = useState([]);
  const { user } = useAccountState();

  const toggleEditing = (id) => {
    const editingIndex = editingIds.findIndex((i) => i === id);
    if (editingIndex >= 0) {
      const newEditingId = [...editingIds];
      newEditingId.splice(editingIndex, 1);
      setEditingIds(newEditingId);
    } else {
      setEditingIds([...editingIds, id]);
    }
  };

  let useDispatch;
  if (page === 'patient') {
    useDispatch = role === 'admin' ? usePatientDispatchAdmin : usePatientDispatchDoctor;
  } else if (page === 'case') {
    useDispatch = role === 'admin' ? useCaseDispatchAdmin : useCaseDispatchDoctor;
  }
  const { createComment, updateComment, deleteComment } = useDispatch();

  const CommentForm = (initialValues, editing = false, commentId = null) =>
    <Formik
      initialValues={initialValues}
      validationSchema={CommentCreationSchema}
      onSubmit={(values, action) => {
        if (editing) {
          updateComment(id, commentId, values).then(() => {
            action.resetForm();
            toggleEditing(commentId);
          }).catch(() => action.setSubmitting(false));
        } else {
          createComment(id, values).then(() => action.resetForm()).catch(() => action.setSubmitting(false));
        }
      }}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
      }) => (
        <form onSubmit={handleSubmit} className={classes.addNew}>
          {!editing && <div className="header">Lägg till ny kommentar</div>}
          <TextField
            variant="outlined"
            fullWidth
            multiline={true}
            rows={8}
            name="content"
            label="Kommentar"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.content}
            error={touched.content && errors.content}
          />
          <div className="bottom">
            {role === 'admin' &&
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  name="doctorHasPermission"
                  checked={values.doctorHasPermission}
                  onChange={handleChange}
                />
              }
              label="Läkare kan se kommentaren"
            />
            }
            <Button
              variant="outlined"
              type="submit"
              disabled={isSubmitting}
            >
              {editing ? 'Uppdatera' : 'Lägg till'}
            </Button>
          </div>
        </form>
      )}
    />;

  return (
    <React.Fragment>
      {comments.map((comment) =>
        <Grid key={comment.id} className={classes.item} container justify="space-between" alignItems="center">
          <Grid item xs={10}>
            <div className={classes.header}>
              {comment.admin !== null ? comment.admin.name : comment.doctor !== null ? comment.doctor.name : ''}
              {((role === 'admin' && comment.admin && comment.admin.id === user.adminId) || (role === 'doctor' && comment.doctor && comment.doctor.id === user.doctorId)) &&
              <React.Fragment>
                <IconButton onClick={() => toggleEditing(comment.id)}>
                  {editingIds.find((id) => id === comment.id)
                    ? <ClearIcon color="primary"/>
                    : <EditIcon color="primary"/>}
                </IconButton>
                <IconButton onClick={() => window.confirm('Är du säker på att du vill ta bort denna kommentaren?') && deleteComment(id, comment.id)}>
                  <DeleteIcon color="error"/>
                </IconButton>
              </React.Fragment>
              }
            </div>
            {!editingIds.find((id) => id === comment.id) ?
              <React.Fragment>
                <div className={classes.content}>{comment.content}</div>
                {role === 'admin' &&
                  comment.doctorHasPermission &&
                  comment.admin &&
                <div className={classes.contentDoctorPermission}>Läkare kan se denna kommentaren.</div>
                }
              </React.Fragment>
              :
              CommentForm({ content: comment.content, doctorHasPermission: comment.doctorHasPermission }, true, comment.id)
            }
          </Grid>
          <Grid item>
            {comment.createdAt.split('T')[0]}
          </Grid>
        </Grid>)}
      {CommentForm({ content: '', doctorHasPermission: true })}
    </React.Fragment>
  );
};

Comments.propTypes = {
  comments: PropTypes.array.isRequired,
  id: PropTypes.number.isRequired,
  role: PropTypes.string.isRequired,
  page: PropTypes.oneOf(['patient', 'case']).isRequired,
};

export default Comments;
