import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { LinearProgress } from '@material-ui/core';

import { useTestDispatch, useTestState } from 'modules/patient/tests';

const Show = () => {
  const { id } = useParams();

  const { fetchSingle, clearCurrent } = useTestDispatch();
  const { fetching, current } = useTestState();

  useEffect(() => {
    fetchSingle(parseInt(id, 10));
    return () => clearCurrent();
  }, []);
console.log(current)
  return (
    <div className="elevated-container" style={{ minWidth: '850px', overflowX: 'auto' }}>
    {fetching
      ? <LinearProgress />
      : Object.entries(current).length === 0 ?
        'Test not found.'
        : current.attachmentFile !== null ?
          <object
            data={current.attachmentFile.url}
            width="100%"
            style={{ height: '85vh' }}
          />
          : current.labPortalenUrl !== '' &&
            <iframe
              src={current.labPortalenUrl}
              width="100%"
              frameBorder={0}
              style={{ minHeight: '85vh' }}
            />
    }
    </div>
  );
};

export default Show;
